import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
const AIImage = (props) => {
    return (_jsxs("svg", { width: "40", height: "40", viewBox: "0 0 40 40", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [_jsx("rect", { width: "40", height: "40", rx: "8", fill: "#EBFFF8" }), _jsx("path", { d: "M18.92 11.891C19.2234 10.7967 20.7751 10.7967 21.0785 11.891L22.4349 16.7828C22.54 17.1617 22.836 17.4578 23.2149 17.5629L28.1068 18.9192C29.2011 19.2227 29.2011 20.7744 28.1068 21.0778L23.2149 22.4342C22.836 22.5392 22.54 22.8353 22.4349 23.2142L21.0785 28.106C20.7751 29.2003 19.2234 29.2003 18.92 28.106L17.5636 23.2142C17.4586 22.8353 17.1625 22.5392 16.7836 22.4342L11.8918 21.0778C10.7974 20.7744 10.7974 19.2227 11.8918 18.9192L16.7836 17.5629C17.1625 17.4578 17.4586 17.1617 17.5636 16.7828L18.92 11.891Z", fill: "url(#paint0_linear_1366_25380)" }), _jsx("path", { d: "M26.8775 24.1687C26.9945 23.7464 27.5932 23.7464 27.7103 24.1687L28.2337 26.0561C28.2742 26.2023 28.3884 26.3165 28.5346 26.3571L30.4221 26.8804C30.8443 26.9975 30.8443 27.5962 30.4221 27.7133L28.5346 28.2366C28.3884 28.2772 28.2742 28.3914 28.2337 28.5376L27.7103 30.425C27.5932 30.8473 26.9945 30.8473 26.8775 30.425L26.3541 28.5376C26.3136 28.3914 26.1994 28.2772 26.0532 28.2366L24.1657 27.7133C23.7435 27.5962 23.7435 26.9975 24.1657 26.8804L26.0532 26.3571C26.1994 26.3165 26.3136 26.2023 26.3541 26.0561L26.8775 24.1687Z", fill: "url(#paint1_linear_1366_25380)" }), _jsxs("defs", { children: [_jsxs("linearGradient", { id: "paint0_linear_1366_25380", x1: "19.9993", y1: "7.9985", x2: "19.9993", y2: "31.9985", gradientUnits: "userSpaceOnUse", children: [_jsx("stop", { offset: "0.356082", "stop-color": "#6FB697" }), _jsx("stop", { offset: "1", "stop-color": "#66A3CA" })] }), _jsxs("linearGradient", { id: "paint1_linear_1366_25380", x1: "27.2939", y1: "22.6668", x2: "27.2939", y2: "31.9269", gradientUnits: "userSpaceOnUse", children: [_jsx("stop", { offset: "0.356082", "stop-color": "#61B5C1" }), _jsx("stop", { offset: "1", "stop-color": "#70B798" })] })] })] }));
};
const AIImageIcon = (props) => {
    const {} = props;
    return _jsx(Icon, { component: AIImage, ...props });
};
export default AIImageIcon;
