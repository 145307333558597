import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { get, map } from 'lodash';
import { Component } from 'react';
import { Button, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import CustomizeFieldOptionsListing from '../components/dashboard/form-templates/customize_field_options_listing';
import { tableDataForEditFieldModal } from '../constant';
import { getLabelFromTemplate } from '../utils/form_utils';
import FxSwitch from 'sharedV2/FxSwitch';
class FxFieldLabelWithEditButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isEditSingleFieldModal: false,
            singleFieldEditModalInfo: {
                heading: '',
                body: null,
            },
        };
    }
    toggleEditSingleFieldModal = () => {
        this.setState((state) => {
            return {
                isEditSingleFieldModal: !state.isEditSingleFieldModal,
            };
        });
    };
    updateBoolean = (event, config) => {
        const updatedConfig = JSON.parse(JSON.stringify(this.props.fieldsMetaData));
        if (!updatedConfig?.[config?.['fieldId']]) {
            updatedConfig[config?.['fieldId']] = {};
        }
        updatedConfig[config?.['fieldId']][event.currentTarget.dataset.id] = !updatedConfig[config['fieldId']][event.currentTarget.dataset.id];
        this.props.handleBooleanUpdate(updatedConfig);
    };
    updateTextValue = (event, config) => {
        const updatedConfig = JSON.parse(JSON.stringify(this.props.fieldsMetaData));
        const dataId = event.currentTarget.dataset.id;
        const dataKey = event.currentTarget.dataset.key;
        if (!updatedConfig?.[config?.fieldId]) {
            updatedConfig[config?.fieldId] = {};
        }
        updatedConfig[config?.fieldId][dataId] = get(event, 'currentTarget.value');
        this.props.handleTextUpdate(updatedConfig, dataKey);
    };
    showEnabledCol = (config, extraData) => {
        switch (config.type) {
            case 'boolean':
                return (_jsx("div", { onClick: (e) => {
                        e.stopPropagation();
                    }, style: { display: 'inline-block' }, children: _jsxs(Label, { className: "switch switch-3d switch-primary", children: [_jsx(Input, { type: "checkbox", checked: extraData?.[`${config?.fieldId}`]?.[`${config?.id}`] ?? false, className: "switch-input", "data-id": config.id, onChange: (e) => this.updateBoolean(e, config) }), _jsx("span", { className: "switch-label" }), _jsx("span", { className: "switch-handle" })] }) }));
            case 'text':
                return (_jsx("div", { onClick: (e) => {
                        e.stopPropagation();
                    }, style: { display: 'inline-block' }, children: _jsx("div", { children: _jsx(Input, { type: "text", "data-key": `${config['fieldId']}-${config.id}`, value: extraData?.[`${config?.fieldId}`]?.[`${config?.id}`], "data-id": config.id, onChange: (event) => this.updateTextValue(event, config) }, `${config.id}-input`) }) }));
            default:
                return (_jsx("div", { onClick: (e) => {
                        e.stopPropagation();
                    }, style: { display: 'inline-block' }, children: _jsxs(Label, { className: "switch switch-3d switch-primary", children: [_jsx(Input, { type: "checkbox", checked: extraData[`${config.fieldId}`]?.[`${config.id}`], className: "switch-input", "data-id": config.id, onChange: (e) => this.updateBoolean(e, config) }), _jsx("span", { className: "switch-label" }), _jsx("span", { className: "switch-handle" })] }) }));
        }
    };
    onSingleFieldEdit = (id, heading) => {
        // Adding field Id of the field that is being edited
        const tableDataForEditFieldModalModified = map(tableDataForEditFieldModal, (row) => {
            return {
                ...row,
                fieldId: id,
            };
        });
        if (this.props.showRequiredMandatary) {
            tableDataForEditFieldModalModified.push({
                id: 'required',
                label: 'Required',
                type: 'boolean',
                fieldId: id,
            });
        }
        this.setState({
            isEditSingleFieldModal: true,
            singleFieldEditModalInfo: {
                heading: heading || '',
                data: tableDataForEditFieldModalModified,
            },
        });
    };
    render() {
        const { fieldsMetaData, fieldId, defaultLabel, isRequired, isTemplate, children, searchWith, isUpdateTemplate, onCreateTemplate, onUpdateTemplate, values, } = this.props;
        const columns = [
            {
                'dataField': 'label',
                'text': 'Name',
            },
            {
                'dataField': 'enabled',
                'text': 'Enabled',
                formatter: (cell, config, index, extraData) => {
                    return this.showEnabledCol(config, extraData);
                },
                formatExtraData: fieldsMetaData,
            },
        ];
        return (_jsxs(_Fragment, { children: [_jsxs(Modal, { isOpen: this.state.isEditSingleFieldModal, toggle: this.toggleEditSingleFieldModal, title: this.state.singleFieldEditModalInfo.heading, children: [_jsx(ModalHeader, { toggle: this.toggleEditSingleFieldModal, children: this.state.singleFieldEditModalInfo.heading }), _jsxs(ModalBody, { children: [' ', _jsx(CustomizeFieldOptionsListing, { columns: columns, data: this.state.singleFieldEditModalInfo.data })] }), _jsx(ModalFooter, { children: _jsx(Button, { onClick: this.toggleEditSingleFieldModal, children: "Save" }) })] }), _jsxs("div", { className: "mb-2", children: [_jsxs("div", { className: searchWith ? 'mb-2 d-flex justify-content-between' : 'mb-2', children: [_jsxs("div", { className: "mr-1", children: [getLabelFromTemplate(fieldsMetaData, fieldId) || defaultLabel, isRequired && _jsx("abbr", { className: "ml-2 text-danger", children: "*" })] }), searchWith && (_jsxs("div", { children: ["Search With", ' ', _jsx(FxSwitch, { checkedChildren: "Name", unCheckedChildren: "Code", defaultChecked: true, onChange: this.props.onSearchWith })] })), this.props.isCustomField && (_jsx("sup", { className: "text-info", style: { fontSize: 10 }, children: "Custom" })), ' ', isTemplate && (_jsx("span", { className: "edit_form_field", style: { 'cursor': 'pointer' }, onClick: () => this.onSingleFieldEdit(fieldId, defaultLabel), children: _jsx("i", { className: "fa fa-edit" }) })), ' '] }), children] })] }));
    }
}
export default FxFieldLabelWithEditButton;
