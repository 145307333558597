import { jsx as _jsx } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
const Safety = (props) => {
    const { color } = props;
    return (_jsx("svg", { width: "19", height: "18", viewBox: "0 0 19 18", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { d: "M4.29766 13.8C3.96766 13.8 3.68516 13.6825 3.45016 13.4475C3.21516 13.2125 3.09766 12.93 3.09766 12.6V5.40001C3.09766 5.07001 3.21516 4.78751 3.45016 4.55251C3.68516 4.31751 3.96766 4.20001 4.29766 4.20001H11.4977C11.8277 4.20001 12.1102 4.31751 12.3452 4.55251C12.5802 4.78751 12.6977 5.07001 12.6977 5.40001V8.10001L15.0977 5.70001V12.3L12.6977 9.90001V12.6C12.6977 12.93 12.5802 13.2125 12.3452 13.4475C12.1102 13.6825 11.8277 13.8 11.4977 13.8H4.29766ZM4.29766 12.6H11.4977V5.40001H4.29766V12.6Z", fill: color }) }));
};
const SafetyIcon = (props) => {
    const { color, ...restProps } = props;
    return _jsx(Icon, { component: (iconProps) => _jsx(Safety, { color: color, ...iconProps }) });
};
export default SafetyIcon;
