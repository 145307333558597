import { jsx as _jsx } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
const Fuel = (props) => {
    const { color } = props;
    return (_jsx("svg", { width: "19", height: "18", viewBox: "0 0 19 18", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { d: "M4 14.1055V4.23394C4 3.89461 4.12082 3.60412 4.36247 3.36247C4.60412 3.12082 4.89461 3 5.23394 3H8.93576C9.27509 3 9.56558 3.12082 9.80723 3.36247C10.0489 3.60412 10.1697 3.89461 10.1697 4.23394V8.55273H10.7867C11.126 8.55273 11.4165 8.67355 11.6581 8.9152C11.8998 9.15684 12.0206 9.44733 12.0206 9.78667V12.563C12.0206 12.7378 12.0797 12.8844 12.198 13.0026C12.3162 13.1209 12.4628 13.18 12.6376 13.18C12.8124 13.18 12.9589 13.1209 13.0772 13.0026C13.1954 12.8844 13.2545 12.7378 13.2545 12.563V8.12085C13.162 8.17226 13.0643 8.20568 12.9615 8.22111C12.8587 8.23653 12.7507 8.24424 12.6376 8.24424C12.2057 8.24424 11.8407 8.09514 11.5425 7.79694C11.2443 7.49874 11.0952 7.1337 11.0952 6.70182C11.0952 6.37277 11.1851 6.07714 11.3651 5.81492C11.545 5.55271 11.7841 5.36505 12.0823 5.25194L10.7867 3.9563L11.4345 3.30848L13.7173 5.52958C13.8715 5.68382 13.9872 5.86377 14.0643 6.06942C14.1414 6.27508 14.18 6.48588 14.18 6.70182V12.563C14.18 12.9949 14.0309 13.3599 13.7327 13.6582C13.4345 13.9564 13.0695 14.1055 12.6376 14.1055C12.2057 14.1055 11.8407 13.9564 11.5425 13.6582C11.2443 13.3599 11.0952 12.9949 11.0952 12.563V9.47818H10.1697V14.1055H4ZM5.23394 7.31879H8.93576V4.23394H5.23394V7.31879ZM12.6376 7.31879C12.8124 7.31879 12.9589 7.25966 13.0772 7.14141C13.1954 7.02316 13.2545 6.87663 13.2545 6.70182C13.2545 6.52701 13.1954 6.38048 13.0772 6.26223C12.9589 6.14397 12.8124 6.08485 12.6376 6.08485C12.4628 6.08485 12.3162 6.14397 12.198 6.26223C12.0797 6.38048 12.0206 6.52701 12.0206 6.70182C12.0206 6.87663 12.0797 7.02316 12.198 7.14141C12.3162 7.25966 12.4628 7.31879 12.6376 7.31879ZM5.23394 12.8715H8.93576V8.55273H5.23394V12.8715Z", fill: color }) }));
};
const Fuel2Icon = (props) => {
    const { color, ...restProps } = props;
    return _jsx(Icon, { component: (iconProps) => _jsx(Fuel, { color: color, ...iconProps }), ...restProps });
};
export default Fuel2Icon;
