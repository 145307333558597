import { jsx as _jsx } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
const GPS = (props) => {
    const { color } = props;
    return (_jsx("svg", { width: "19", height: "18", viewBox: "0 0 19 18", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { d: "M8.33516 15.375L10.3539 10.3812L15.3477 8.3625V7.37083L2.59766 2.625L7.34349 15.375H8.33516ZM7.87474 12.7542L5.00599 5.03333L12.7268 7.90208L9.25599 9.28333L7.87474 12.7542Z", fill: color }) }));
};
const GPSIcon = (props) => {
    const { color, ...restProps } = props;
    return _jsx(Icon, { component: (iconProps) => _jsx(GPS, { color: color, ...iconProps }) });
};
export default GPSIcon;
