import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Component } from 'react';
import { bindActionCreators } from 'redux';
import { Button, Collapse } from 'reactstrap';
import { connect } from 'react-redux';
import { map, get, filter, includes, find, set, forEach, keyBy, toLower, unset, } from 'lodash';
import { Field, FieldArray } from 'formik';
import { FxTextFieldStackedLabel, FxSelectField, FxSelectSingleDate } from '../../../../shared';
import { fetchTyreModel, fetchTyreTypes } from '../../../../actions/dashboard/tyres_action';
import { rfTagListing } from '../../../../actions/dashboard/tyres/v2/tyre_actions';
import { fetchVehicleListMini } from '../../../../actions/dashboard/vehicles_action';
import { getMomentTime, DATE_FORMAT_TIMESTAMP_T } from '../../../../utils/date_utils';
import { mappedRfTags, mappedTyreConfigurations } from '../../../utils/mapping_utils';
import { TYRE_TYPE } from '../../../../constant';
import { enableTyreRFIDFlow, isManualTaxCalculationForTyre } from '../../../../utils/account_utils';
import { mappedTags } from '../../../../utils/tag_utils';
import { fetchTags } from '../../../../actions/dashboard/tags_action';
import VehicleTyreConfig from '../vehicle_tyre_config';
import UploadDocumentsComponentFormik from 'shared/UploadDocumentsComponentFormik';
import FxTextToNumericStackedLabel from 'shared/FxTextToNumericStackedLabel';
export function mappedTyreBrands(tyreBrandsList) {
    return map(tyreBrandsList, (o) => {
        return {
            value: o.id,
            label: `${o.name}`,
        };
    });
}
export function mappedTyreModels(tyreModelList) {
    let newTyreModelList = map(tyreModelList, (o) => {
        return {
            value: o.id,
            label: `${o.name}`,
        };
    });
    return newTyreModelList;
}
export function mappedTyreList(tyreList) {
    let newTyreModelList = map(tyreList, (o) => {
        return {
            value: o.id,
            label: `${o.tyreNo}`,
        };
    });
    return newTyreModelList;
}
class TyreFields extends Component {
    state = {
        tyreBrandsList: [],
        tyreModelList: [],
        availableRFTags: [],
        activeConfigurationIndex: 0,
        tyreConfigurationListOptions: [],
        mappedBranches: [],
    };
    componentDidMount = async () => {
        this.fetchAllBrands();
        this.fetchTyreModel();
        this.fetchAvailableRFTags();
        this.props.fetchVehicleListMini(this.props.accesstoken, true);
        this.props.fetchTags(this.props.accesstoken, 'TYRE');
    };
    fetchAllBrands = async () => {
        const { payload: { data: resp }, } = await this.props.fetchTyreTypes(this.props.accesstoken);
        this.setState({
            tyreBrandsList: get(resp, 'tyreBrands', []),
        });
    };
    fetchTyreModel = async () => {
        const { payload: { data: resp }, } = await this.props.fetchTyreModel(this.props.accesstoken);
        this.setState({
            tyreModelList: get(resp, 'models'),
        });
    };
    fetchAvailableRFTags = async () => {
        const resp = await this.props.rfTagListing(this.props.accesstoken);
        this.setState({
            availableRFTags: get(resp, 'payload.data'),
        });
    };
    openConfiguration = (index) => {
        if (index == this.state.activeConfigurationIndex) {
            this.setState({
                activeConfigurationIndex: null,
            });
        }
        else {
            this.setState({
                activeConfigurationIndex: index,
            });
        }
    };
    handleTyreDetailsChange = ({ key, value, vehicleDetails, index, values }) => {
        const vehicleTemp = get(vehicleDetails, get(values, 'chasisNo'));
        if (vehicleTemp) {
            const currentVehicle = JSON.parse(JSON.stringify(vehicleTemp));
            forEach(get(currentVehicle, 'tyreDetails'), (tyre, i) => {
                if (get(tyre, 'addedFromUI') == index) {
                    forEach(key, (k, j) => {
                        set(currentVehicle, `tyreDetails.[${i}].${k}`, value[j]);
                    });
                }
            });
            this.props.setVehicleDetails(get(values, 'chasisNo'), currentVehicle);
        }
    };
    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.tyreConfigurationList != this.props.tyreConfigurationList) {
            this.setState({
                tyreConfigurationListOptions: mappedTyreConfigurations(this.props.tyreConfigurationList),
            });
        }
        if (prevProps.branches != this.props.branches) {
            this.setState({
                mappedBranches: keyBy(this.props.branches, 'id'),
            });
        }
    };
    render() {
        const tyreConfigurationListOptions = this.state.tyreConfigurationListOptions;
        const isManualTaxCalc = isManualTaxCalculationForTyre();
        return (_jsx(FieldArray, { name: "tyreSaveList", children: ({ move, swap, push, insert, remove, unshift, pop, form }) => {
                const fieldMetaData = get(this.props, 'fieldCustomMetaData');
                const { setFieldValue } = form;
                const shouldShowIssuanceFields = get(form.values, 'issueTyres') && get(form.values, 'nature') == 'CHASSIS_MRN';
                const tyreSaveList = get(form.values, 'tyreSaveList');
                const isCreateBillAllowed = includes(['TYRE_MRN', 'REMOULD_TYRE_MRN', 'TYRE_AGAINST_CLAIM_MRN'], get(form.values, 'nature'));
                const createBill = get(form.values, 'createBill', false);
                const branch = get(this.state.mappedBranches, `${get(form.values, 'branch')}`);
                const isIgst = toLower(get(branch, 'address.state')) !== toLower(get(form.values, 'billInfo.stateOfSupply'));
                return (_jsxs(_Fragment, { children: [form.values.tyreSaveList && form.values.tyreSaveList.length > 0
                            ? map(get(form.values, 'tyreSaveList'), (field, index) => {
                                return (_jsxs("div", { className: "card borderImportant", style: { backgroundColor: '#f3f6f7', paddingLeft: '3.5rem' }, children: [_jsxs("div", { className: 'cursor-pointer', onClick: () => {
                                                this.openConfiguration(index);
                                            }, style: { backgroundColor: '#f3f6f7' }, children: [_jsxs("div", { className: "my-4", children: [_jsx("i", { className: `fa mr-2 ${get(field, 'id', index) ==
                                                                this.state.activeConfigurationIndex
                                                                ? 'fa-angle-up'
                                                                : 'fa-angle-right'} `, style: {
                                                                fontSize: '19px',
                                                                fontWeight: 700,
                                                            } }), _jsxs("span", { style: { fontSize: '19px' }, className: "w-100", children: ["Tyre #", index + 1] })] }), _jsx("i", { title: "Remove", className: "fa fa-times pos-abs cursor-pointer", style: { top: 30, right: 30 }, onClick: (e) => {
                                                        e.stopPropagation();
                                                        this.setState({
                                                            activeConfigurationIndex: null,
                                                        });
                                                        remove(index);
                                                    } })] }), _jsxs(Collapse, { isOpen: get(field, 'id', index) == this.state.activeConfigurationIndex, children: [_jsxs("div", { className: "d-flex flex-wrap pb-4", children: [includes(get(this.props.fields, 'tyreFields'), 'NEW_TYRE_NUMBER') && (_jsx("div", { style: { width: '30%' }, className: "mr-4", children: _jsx(Field, { name: `tyreSaveList.[${index}].tyreNo`, type: "text", component: FxTextFieldStackedLabel, label: fieldMetaData
                                                                    ? get(fieldMetaData.NEW_TYRE_NUMBER, 'label', 'NewTyre Number')
                                                                    : `New Tyre Number`, isRequired: true }) })), includes(get(this.props.fields, 'tyreFields'), 'TYRE_NUMBER') && (_jsx("div", { style: { width: '30%' }, className: "mr-4", children: _jsx(Field, { name: `tyreSaveList.[${index}].tyreNo`, type: "text", component: FxTextFieldStackedLabel, label: fieldMetaData
                                                                    ? get(fieldMetaData.TYRE_NUMBER, 'label', 'Tyre Number')
                                                                    : `Tyre Number`, isRequired: true }) })), includes(get(this.props.fields, 'tyreFields'), 'TYRE_NUMBER_SELECT') && (_jsx("div", { style: { width: '30%' }, className: "mr-4", children: _jsx(Field, { name: `tyreSaveList.[${index}].tyre.id`, options: mappedTyreList(get(this.props.filteredTyres, 'data')), component: FxSelectField, label: fieldMetaData
                                                                    ? get(fieldMetaData.TYRE_NUMBER, 'label', 'Tyre Number')
                                                                    : `Tyre Number`, isRequired: true, onChange: (val) => this.props.onTyreSelection(val, index, form) }) })), includes(get(this.props.fields, 'tyreFields'), 'TYPE') && (_jsx("div", { style: { width: '30%' }, className: "mr-4", children: _jsx(Field, { name: `tyreSaveList.[${index}].type`, options: TYRE_TYPE, component: FxSelectField, label: "Type", isRequired: true }) })), includes(get(this.props.fields, 'tyreFields'), 'PRODUCTION_DATE') && (_jsx("div", { style: { width: '30%' }, className: "mr-4", children: _jsx(Field, { name: `tyreSaveList.[${index}].productionDate`, timePicker: true, label: "Manufacturing Date", placeholder: "Manufacturing Date", component: FxSelectSingleDate, 
                                                                //   isRequired={true}
                                                                valueFormatter: (value) => getMomentTime(value).format(DATE_FORMAT_TIMESTAMP_T) }) })), _jsx("div", { style: { width: '30%' }, className: "mr-4", children: _jsx(Field, { name: `tyreSaveList.[${index}].brandId`, options: mappedTyreBrands(this.state.tyreBrandsList), component: FxSelectField, label: "Brand", onChange: (val) => {
                                                                    const selectedTyreBrand = find(this.state.tyreBrandsList, { id: val });
                                                                    setFieldValue(`tyreSaveList.[${index}].model.brand`, selectedTyreBrand);
                                                                    setFieldValue(`tyreSaveList.[${index}].modelId`, '');
                                                                    if (shouldShowIssuanceFields) {
                                                                        this.handleTyreDetailsChange({
                                                                            key: [
                                                                                'model.brand',
                                                                                'model.id',
                                                                                'model.name',
                                                                            ],
                                                                            value: [selectedTyreBrand, '', ''],
                                                                            vehicleDetails: this.props.vehicleDetails,
                                                                            index,
                                                                            values: form.values,
                                                                        });
                                                                    }
                                                                }, isRequired: true }) }), _jsx("div", { style: { width: '30%' }, className: "mr-4", children: _jsx(Field, { name: `tyreSaveList.[${index}].modelId`, options: mappedTyreModels(filter(this.state.tyreModelList, (model) => {
                                                                    return (model.brand.id ===
                                                                        get(form.values, `tyreSaveList.[${index}].brandId`));
                                                                })), onChange: (val) => {
                                                                    const selectedTyreModel = find(this.state.tyreModelList, { id: val });
                                                                    setFieldValue(`tyreSaveList.[${index}].model.id`, selectedTyreModel?.id);
                                                                    setFieldValue(`tyreSaveList.[${index}].model.name`, selectedTyreModel?.name);
                                                                    if (shouldShowIssuanceFields) {
                                                                        this.handleTyreDetailsChange({
                                                                            key: ['model.id', 'model.name'],
                                                                            value: [
                                                                                selectedTyreModel?.id,
                                                                                selectedTyreModel?.name,
                                                                            ],
                                                                            vehicleDetails: this.props.vehicleDetails,
                                                                            index,
                                                                            values: form.values,
                                                                        });
                                                                    }
                                                                }, component: FxSelectField, label: "Model", isRequired: true }) }), includes(get(this.props.fields, 'tyreFields'), 'PURCHASE_AMOUNT') && (_jsx("div", { style: { width: '30%' }, className: "mr-4", children: _jsx(Field, { name: `tyreSaveList.[${index}].amount`, component: FxTextToNumericStackedLabel, label: fieldMetaData
                                                                    ? get(fieldMetaData.PURCHASE_AMOUNT, 'label', 'Price')
                                                                    : `Price`, isRequired: true }) })), includes(get(this.props.fields, 'tyreFields'), 'CURRENT_AMOUNT') && (_jsx("div", { style: { width: '30%' }, className: "mr-4", children: _jsx(Field, { name: `tyreSaveList.[${index}].currentAmount`, component: FxTextToNumericStackedLabel, label: fieldMetaData
                                                                    ? get(fieldMetaData.CURRENT_AMOUNT, 'label', 'Current Price')
                                                                    : 'Current Price', isRequired: true, disabled: includes(get(this.props.fields, 'tyreFields'), 'EXTRA_AMOUNT') }) })), includes(get(this.props.fields, 'tyreFields'), 'EXTRA_AMOUNT') && (_jsx("div", { style: { width: '30%' }, className: "mr-4", children: _jsx(Field, { name: `tyreSaveList.[${index}].extraAmount`, type: "number", component: FxTextFieldStackedLabel, label: fieldMetaData
                                                                    ? get(fieldMetaData.EXTRA_AMOUNT, 'label', 'Extra Price')
                                                                    : `Extra Price`, isRequired: true }) })), _jsx("div", { style: { width: '30%' }, className: "mr-4", children: _jsx(Field, { name: `tyreSaveList.[${index}].discount`, component: FxTextToNumericStackedLabel, label: 'Discount' }) }), createBill && isCreateBillAllowed && isManualTaxCalc && (_jsxs(_Fragment, { children: [isIgst && (_jsx("div", { style: { width: '30%' }, className: "mr-4", children: _jsx(Field, { name: `tyreSaveList.[${index}].igstAmount`, type: "number", component: FxTextFieldStackedLabel, label: 'IGST Amount', min: 0 }) })), !isIgst && (_jsxs(_Fragment, { children: [_jsx("div", { style: { width: '30%' }, className: "mr-4", children: _jsx(Field, { name: `tyreSaveList.[${index}].sgstAmount`, type: "number", component: FxTextFieldStackedLabel, label: 'SGST Amount' }) }), _jsx("div", { style: { width: '30%' }, className: "mr-4", children: _jsx(Field, { name: `tyreSaveList.[${index}].cgstAmount`, type: "number", component: FxTextFieldStackedLabel, label: 'CGST Amount', min: 0 }) })] }))] })), includes(get(this.props.fields, 'tyreFields'), 'KM_ISSUED') && (_jsx("div", { style: { width: '30%' }, className: "mr-4", children: _jsx(Field, { name: `tyreSaveList.[${index}].kmIssued`, component: FxTextToNumericStackedLabel, label: "KM Limit", isRequired: true }) })), includes(get(this.props.fields, 'tyreFields'), 'OPENING_KM') && (_jsx("div", { style: { width: '30%' }, className: "mr-4", children: _jsx(Field, { name: `tyreSaveList.[${index}].openingKm`, component: FxTextToNumericStackedLabel, label: "Opening KM", isRequired: true, onChange: (val) => {
                                                                    if (shouldShowIssuanceFields) {
                                                                        setFieldValue(`tyreSaveList[${index}].tyreDTOIssueAndRemovalRequest.attachOdometerReading`, val);
                                                                    }
                                                                } }) })), includes(get(this.props.fields, 'tyreFields'), 'PLY_RATING') && (_jsx("div", { style: { width: '30%' }, className: "mr-4", children: _jsx(Field, { name: `tyreSaveList.[${index}].plyRating`, component: FxTextToNumericStackedLabel, label: "Ply Rating" }) })), includes(get(this.props.fields, 'tyreFields'), 'TREAD_DEPTH') && (_jsx("div", { style: { width: '30%' }, className: "mr-4", children: _jsx(Field, { name: `tyreSaveList.[${index}].treadDepth`, component: FxTextToNumericStackedLabel, label: "Tread Depth", onChange: (val) => {
                                                                    setFieldValue(`tyreSaveList.[${index}].treadDepth`, val);
                                                                    if (shouldShowIssuanceFields) {
                                                                        this.handleTyreDetailsChange({
                                                                            key: ['treadDepth'],
                                                                            value: [val],
                                                                            vehicleDetails: this.props.vehicleDetails,
                                                                            index,
                                                                            values: form.values,
                                                                        });
                                                                    }
                                                                } }) })), includes(get(this.props.fields, 'tyreFields'), 'TYRE_SIZE') && (_jsx("div", { style: { width: '30%' }, className: "mr-4", children: _jsx(Field, { name: `tyreSaveList.[${index}].size`, type: "text", component: FxTextFieldStackedLabel, label: "Tyre Size", 
                                                                //   isRequired={true}
                                                                placeholder: "215/60R15" }) })), includes(get(this.props.fields, 'tyreFields'), 'RF_TAG') &&
                                                            enableTyreRFIDFlow() && (_jsx("div", { style: { width: '30%' }, className: "mr-4", children: _jsx(Field, { name: `tyreSaveList.[${index}].rfTag`, options: mappedRfTags(this.state.availableRFTags), component: FxSelectField, label: "RF Tag" }) })), includes(get(this.props.fields, 'tyreFields'), 'REMARKS') && (_jsx("div", { style: { width: '30%' }, className: "mr-4", children: _jsx(Field, { name: `tyreSaveList.[${index}].remarks`, type: "text", component: FxTextFieldStackedLabel, label: "Remarks" }) })), _jsx("div", { style: { width: '30%' }, className: "mr-4", children: _jsx(Field, { name: `tyreSaveList.[${index}].psi`, component: FxTextToNumericStackedLabel, label: "PSI" }) }), _jsx("div", { style: { width: '30%' }, className: "mr-4", children: _jsx(Field, { name: `tyreSaveList[${index}].tags`, label: "Tags", options: mappedTags(this.props.tags), component: FxSelectField, multi: true }) }), shouldShowIssuanceFields && (_jsxs(_Fragment, { children: [_jsx("div", { style: { width: '30%' }, className: "mr-4", children: _jsx(Field, { name: `tyreSaveList[${index}].tyreDTOIssueAndRemovalRequest.currentAmount`, label: "Current Amount", component: FxTextFieldStackedLabel, isRequired: true, type: "number" }) }), _jsx("div", { style: { width: '30%' }, className: "mr-4", children: _jsx(Field, { name: `tyreSaveList[${index}].tyreDTOIssueAndRemovalRequest.attachOdometerReading`, label: 'Start Odometer', component: FxTextFieldStackedLabel, isRequired: true, type: "number" }) }), _jsx("div", { style: { width: '30%' }, className: "mr-4", children: _jsx(Field, { name: `tyreSaveList[${index}].tyreDTOIssueAndRemovalRequest.eventTime`, timePicker: true, label: 'Attach Date', component: FxSelectSingleDate, isRequired: true }) }), _jsx("div", { style: { width: '30%' }, className: "mr-4", children: _jsx(Field, { name: `tyreSaveList[${index}].tyreDTOIssueAndRemovalRequest.positionId`, label: "Wheel Position", 
                                                                        // value={wheelPosition}
                                                                        component: FxTextFieldStackedLabel, isRequired: true, type: "number", disabled: true }) })] }))] }), this.props.tyreFlow === 'INWARD' && _jsx("h6", { children: "Upload Documents" }), this.props.tyreFlow === 'INWARD' && _jsx("hr", { className: "mr-5" }), this.props.tyreFlow === 'INWARD' && (_jsx("div", { className: "pr-5 pb-3", children: _jsx(UploadDocumentsComponentFormik, { name: `tyreSaveList.[${index}].tyreImages`, documents: get(form.values, `tyreSaveList.[${index}].tyreImages`, []), isTagTypeRequired: false }) })), shouldShowIssuanceFields && get(form, 'values.chasisNo') && (_jsx("div", { children: _jsx(VehicleTyreConfig, { tyreListIndex: index, vehicleDetails: this.props.vehicleDetails, vehicleId: get(form.values, `chasisNo`), selectedTyreId: get(form.values, `tyreSaveList.[${index}].tyreNo`), tyres: [], setVehicleDetails: (vehicleId, vehicleDetails) => this.props.setVehicleDetails(vehicleId, vehicleDetails), tyreFlow: 'ISSUANCE', setFieldValue: setFieldValue, alreadySelectedTyres: [], onAddTyreCB: (val) => setFieldValue(`tyreSaveList[${index}].tyreDTOIssueAndRemovalRequest.positionId`, val), isTyreInward: true, selectedTyreInward: get(form.values, `tyreSaveList.[${index}]`), tyreConfigurationListOptions: tyreConfigurationListOptions, tyreConfigurationList: this.props.tyreConfigurationList }) }))] })] }, index));
                            })
                            : null, _jsx("div", { className: "d-flex justify-content-center", children: _jsxs(Button, { type: "button", color: "success", onClick: () => {
                                    const tyreSaveListLength = get(form.values, 'tyreSaveList.length');
                                    const previousTyre = Object.assign({}, get(form.values, `tyreSaveList[${tyreSaveListLength - 1}]`));
                                    let newTyre = {
                                        tyreDTOIssueAndRemovalRequest: {
                                            attachOdometerReading: null,
                                            eventTime: getMomentTime(get(form.values, 'mrnDate')).add(30, 'minutes'),
                                        },
                                    };
                                    unset(previousTyre, 'tyreNo');
                                    if (tyreSaveListLength) {
                                        newTyre = {
                                            ...newTyre,
                                            ...previousTyre,
                                        };
                                    }
                                    push(newTyre);
                                    if (tyreSaveListLength) {
                                        this.setState({
                                            activeConfigurationIndex: tyreSaveListLength,
                                        });
                                    }
                                }, children: [_jsx("i", { className: 'fa fa-plus mr-2' }), ' ', get(form.values, 'tyreSaveList') ? ' Add More Tyres' : 'Add Tyre'] }) })] }));
            } }));
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({ fetchTyreTypes, fetchTyreModel, fetchVehicleListMini, rfTagListing, fetchTags }, dispatch);
}
function mapStateToProps(state, ownProps) {
    return {
        accesstoken: get(state, 'login.data.access_token'),
        vehicleListMini: state.vehicles.vehicleListMini,
        tags: state.tags.tags,
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(TyreFields);
