import { Component } from 'react';
import { connect } from 'react-redux';
import { fetchVehicleListMini } from 'actions/dashboard/vehicles_action';
import { filter, get, includes, isEmpty, toLower } from 'lodash';
import { setLocalVehicleId } from 'actions/dashboard/local_action';
import {
    disableVehicleIdSearchInVehicleDropdown,
    isRemoteSelectOpenForAccount,
    showRemoveVehiclesInSearchForAccount,
} from 'utils/account_utils';
import { isSwitchVehicles } from '../roles/permission_utils';
import { withTranslation } from 'react-i18next';
import { mappedVehicleListMiniOptions } from '../../utils/mapping_utils';
import { FxSelect } from '../../../sharedV2/index';
import { isFuelLevelAvailable, isOBD } from 'components/dashboard/report_section/fuel_level_v3/Utils';

class SelectVehicle extends Component {
    constructor(props) {
        super(props);
        this.state = {
            vehicleListMini: [],
        };
    }

    handleSelectChange = (value, triggeredAction) => {
        const option = this.props.vehicleListMiniAsMap[value];
        this.props.setLocalVehicleId(value);
        this.props.onVehicleSelected(value, option ? option.vehicleName : null, option ? option.vehicleNumber : null);
    };

    fetchData = () => {
        this.props.fetchVehicleListMini(
            this.props.accesstoken,
            showRemoveVehiclesInSearchForAccount(this.props.loggedInUser, this.props.isSwitchedUser),
            this.props.groupId,
            false,
            null,
            this.props.allVehicles,
            null,
            this.props.isVideoTalematics,
            this.props.isFuelSensor
        );
    };

    componentDidMount() {
        if (this.props.preventFetch && !isEmpty(this.props.vehicleListMini)) {
            this.updateVehicleListing();
            return;
        }
        if (!isRemoteSelectOpenForAccount()) {
            this.fetchData();
        }
    }

    updateVehicleListing = () => {
        let vehicleListMini = this.props.vehicleListMini;
        if (this.props.onlyFilterFuelLevelSensor || this.props.isOBDOnly) {
            vehicleListMini = filter(vehicleListMini, (v) => {
                let toFilter = true;
                if (this.props.onlyFilterFuelLevelSensor) {
                    toFilter =
                        toFilter &&
                        (get(v, 'fuelSensor', 0) > 0 || isFuelLevelAvailable(v?.id, this.props.vehicleListMiniAsMap));
                }
                if (this.props.isOBDOnly) {
                    toFilter = toFilter && isOBD(v.deviceType);
                }
                return !!toFilter;
            });
            this.setState({
                vehicleListMini,
            });
        } else {
            this.setState({
                vehicleListMini,
            });
        }
    };

    componentDidUpdate(prevProps, prevState) {
        if (this.props.vehicleListMini !== prevProps.vehicleListMini) {
            this.updateVehicleListing();
        }

        if (this.props.preventFetch) return;
        if (prevProps.groupId !== this.props.groupId && !isRemoteSelectOpenForAccount()) {
            this.fetchData();
        }
    }

    render() {
        const {
            isSwitchedUser,
            isSwitchUserAllowed,
            disabled,
            t,
            isVideoTalematics,
            accesstoken,
            hasList,
            loggedInUser,
            vehicleListMiniAsMap,
            loading,
            showDisabledOnListing = false,
            ...rest
        } = this.props;
        const { vehicleListMini } = this.state;
        const mappedVehicleOptions = mappedVehicleListMiniOptions(
            vehicleListMini,
            null,
            false,
            this.props.isVideoTalematics,
            {
                showDisabled: showDisabledOnListing,
            }
        );
        return (
            <FxSelect
                matchProp={isSwitchedUser || isSwitchUserAllowed ? 'any' : 'label'}
                key="select-vehicle-dropdown"
                placeholder={`${t('vehicles.selectVehicle')}`}
                value={this.props.value}
                options={mappedVehicleOptions}
                isLoading={loading}
                allowClear={this.props.clearable}
                onChange={this.handleSelectChange}
                disabled={disabled}
                style={{ width: '100%', maxWidth: '220px' }}
                filterOption={(input, option) => {
                    return (
                        includes(toLower(get(option, 'value', '')), toLower(input)) ||
                        includes(toLower(get(option, 'search', '')), toLower(input))
                    );
                }}
                {...rest}
            />
        );
    }
}

function mapStateToProps(state) {
    return {
        accesstoken: state.login.data.access_token,
        loading: state.vehicles.isVehicleListMiniLoading,
        hasList: !!state.vehicles.vehicleListMini,
        loggedInUser: state.settings.loggedInUser,
        isSwitchUserAllowed: isSwitchVehicles(get(state, 'login.data.scope')),
        isSwitchedUser: get(state, 'login.isSwitchedUser'),
        vehicleListMini: state.vehicles.vehicleListMini,
        vehicleListMiniAsMap: state.vehicles.vehicleListMiniAsMap,
    };
}

export default withTranslation()(connect(mapStateToProps, { fetchVehicleListMini, setLocalVehicleId })(SelectVehicle));
