import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect, useCallback } from 'react';
import { FxCol, FxRow, FxTable } from 'sharedV2';
import CalendarComponent from 'components/utils/calendar_component';
import { connect } from 'react-redux';
import { cloneDeep, get, map, pickBy } from 'lodash';
import { getCommonColumns, getConsignmentColumns } from './utils';
import DeductionSection from './BillSettlementDeductionComponent';
const BillSettlementFreightBillComponent = (props) => {
    const { formikProps, bills, loading, dateRange, onDateRangeChange, ledgers } = props;
    const { values, setFieldValue } = formikProps;
    console.log(bills, 'dsfgfdfsg');
    const [expandedBillRowKeys, setExpandedBillRowKeys] = useState([]);
    const [expandedConsignmentRowKeys, setExpandedConsignmentRowKeys] = useState({});
    // Automatically set expanded rows for preselected rows
    useEffect(() => {
        const consignmentRowKeys = {};
        const preselectedBillIds = map(get(values, 'paymentMappingRequests', []), (r) => r.billId);
        setExpandedBillRowKeys(preselectedBillIds);
        preselectedBillIds.forEach((freightBillId) => {
            const consignmentMapping = get(values, `paymentMappingRequests.${freightBillId}.settlementEntityRequests`, {});
            consignmentRowKeys[freightBillId] = Object.keys(consignmentMapping).map((key) => parseInt(key, 10));
        });
        setExpandedConsignmentRowKeys(consignmentRowKeys);
    }, [values]);
    // Handle bill selection
    // const handleFreightBillSelect = (selectedRowKeys: any[], selectedRows: any[]) => {
    //     console.log(selectedRowKeys, selectedRows, 'sdf');
    //     const currentMappings = cloneDeep(get(values, 'paymentMappingRequests', {}));
    //     console.log(currentMappings, 'currentMappings');
    //     const currentMappingsFiltered = pickBy(currentMappings, (_, key) => includes(selectedRowKeys, key));
    //     const rowsToAdd = filter(selectedRows, (row) => !has(currentMappingsFiltered, row.id));
    //     const newMappings = rowsToAdd.reduce((acc, row) => {
    //         console.log(acc, row, 'Dheeraj1111');
    //         const existingSettlements = get(currentMappings, [row.id, 'settlementEntityRequests'], {});
    //         acc[row.id] = {
    //             billId: row.id,
    //             settlementType: 'FREIGHT_INVOICE',
    //             settlementEntityRequests: existingSettlements,
    //         };
    //         return acc;
    //     }, {});
    //     const updatedMappings = assign({}, currentMappingsFiltered, newMappings);
    //     setFieldValue('paymentMappingRequests', updatedMappings);
    //     // Automatically expand rows for selected bills
    //     setExpandedBillRowKeys(selectedRowKeys);
    // };
    const handleFreightBillSelect = (selectedRowKeys, selectedRows) => {
        console.log(selectedRowKeys, selectedRows, 'sdf');
        const currentMappings = cloneDeep(get(values, 'paymentMappingRequests', {}));
        console.log(currentMappings, 'currentMappings');
        // Create a map of selected bill IDs for quick lookup
        const selectedBillIds = new Set(selectedRowKeys);
        // Preserve existing mappings for other sections
        const otherMappings = pickBy(currentMappings, (mapping) => mapping.settlementType !== 'FREIGHT_INVOICE');
        // 1. Keep existing freight bill mappings that are still selected
        const existingFreightMappings = pickBy(currentMappings, (mapping, key) => mapping.settlementType === 'FREIGHT_INVOICE' && selectedBillIds.has(key));
        // 2. Add new freight bill entries for newly selected rows
        const newMappings = selectedRows
            .filter((row) => !existingFreightMappings[row.id])
            .reduce((acc, row) => {
            acc[row.id] = {
                billId: row.id,
                settlementType: 'FREIGHT_INVOICE',
                settlementEntityRequests: get(currentMappings, [row.id, 'settlementEntityRequests'], {}),
                tdsAmount: get(currentMappings, [row.id, 'tdsAmount'], null),
            };
            return acc;
        }, {});
        // 3. Merge all mappings
        const updatedMappings = {
            ...otherMappings,
            ...existingFreightMappings,
            ...newMappings, // New freight entries
        };
        setFieldValue('paymentMappingRequests', updatedMappings);
        setExpandedBillRowKeys(selectedRowKeys);
    };
    // Handle consignment selection
    const handleConsignmentSelect = (freightBillId, selectedRowKeys, selectedRows) => {
        const currentMapping = get(values, `paymentMappingRequests.${freightBillId}`);
        if (!currentMapping)
            return;
        const currentSettlements = { ...get(currentMapping, 'settlementEntityRequests', {}) };
        Object.keys(currentSettlements).forEach((settlementId) => {
            if (!selectedRows.find((row) => row.id === settlementId)) {
                delete currentSettlements[settlementId];
            }
        });
        selectedRows.forEach((row) => {
            if (!currentSettlements[row.id]) {
                currentSettlements[row.id] = {
                    settlementEntityId: row.id,
                    remarks: '',
                    deductionMappings: [],
                };
            }
        });
        setFieldValue(`paymentMappingRequests.${freightBillId}.settlementEntityRequests`, currentSettlements);
        // Automatically expand consignment rows for this bill
        setExpandedConsignmentRowKeys((prevState) => ({
            ...prevState,
            [freightBillId]: selectedRowKeys,
        }));
    };
    const consignmentColumns = getConsignmentColumns('FREIGHT_BILL', formikProps);
    const columns = getCommonColumns('FREIGHT_BILL', formikProps);
    const expandedConsignmentRowRender = useCallback((record) => {
        const consignmentId = get(record, 'id');
        const freightBillId = get(record, 'freightBillId');
        if (!consignmentId || !freightBillId)
            return null;
        return (_jsx(DeductionSection, { consignmentId: consignmentId, freightBillId: freightBillId, ledgers: ledgers, formikProps: formikProps }));
    }, [ledgers, formikProps]);
    const expandedRowRender = useCallback((record) => {
        const consignmentBillDetailsList = map(get(record, 'consignmentBillDetailsList', []), (item) => ({
            ...item,
            freightBillId: record.id,
        }));
        const freightBillId = get(record, 'id');
        const currentMapping = get(values, `paymentMappingRequests.${freightBillId}`, {});
        const selectedKeys = Object.keys(get(currentMapping, 'settlementEntityRequests', {})).map((key) => parseInt(key, 10));
        return (_jsx(FxTable, { columns: consignmentColumns, dataSource: consignmentBillDetailsList, pagination: false, rowKey: (record) => get(record, 'id'), size: "small", expandable: {
                expandedRowKeys: expandedConsignmentRowKeys[freightBillId] || [],
                onExpand: (expanded, consignmentRecord) => {
                    setExpandedConsignmentRowKeys((prevState) => ({
                        ...prevState,
                        [freightBillId]: expanded
                            ? [...(prevState[freightBillId] || []), consignmentRecord.id]
                            : (prevState[freightBillId] || []).filter((key) => key !== consignmentRecord.id),
                    }));
                },
                expandedRowRender: expandedConsignmentRowRender,
            }, rowSelection: {
                type: 'checkbox',
                selectedRowKeys: selectedKeys,
                onChange: (selectedRowKeys, selectedRows) => handleConsignmentSelect(freightBillId, selectedRowKeys, selectedRows),
            } }));
    }, [consignmentColumns, expandedConsignmentRowRender, expandedConsignmentRowKeys, values]);
    return (_jsxs(_Fragment, { children: [_jsx(FxRow, { className: "mb-4", children: _jsx(FxCol, { span: 24, children: _jsx(CalendarComponent, { startDate: dateRange.startDate, endDate: dateRange.endDate, onDateSelected: onDateRangeChange }) }) }), _jsx(FxTable, { columns: columns, dataSource: bills, loading: loading, rowKey: "id", pagination: false, scroll: { x: true }, size: "small", expandable: {
                    expandedRowKeys: expandedBillRowKeys,
                    onExpand: (expanded, record) => {
                        const keys = expanded
                            ? [...expandedBillRowKeys, record.id]
                            : expandedBillRowKeys.filter((key) => key !== record.id);
                        setExpandedBillRowKeys(keys);
                    },
                    expandedRowRender,
                }, rowSelection: {
                    type: 'checkbox',
                    selectedRowKeys: map(get(values, 'paymentMappingRequests', []), (r) => {
                        console.log(r, 'FDGJHDFG');
                        return r.billId;
                    }),
                    onChange: handleFreightBillSelect,
                } })] }));
};
const mapStateToProps = (state) => ({
    ledgers: get(state, 'consignments.ledgers', []),
});
export default connect(mapStateToProps)(BillSettlementFreightBillComponent);
