import { jsx as _jsx } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
const Device = (props) => {
    const { color } = props;
    return (_jsx("svg", { width: "19", height: "18", viewBox: "0 0 19 18", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { "fill-rule": "evenodd", "clip-rule": "evenodd", d: "M4.37871 4.92837C7.20731 2.09199 11.1595 2.63863 13.4422 4.92209C13.7351 5.21503 14.21 5.21511 14.5029 4.92226C14.7958 4.62942 14.7959 4.15454 14.5031 3.8616C11.7233 1.08087 6.81612 0.360019 3.3166 3.86917C3.02411 4.16246 3.02476 4.63733 3.31805 4.92983C3.61135 5.22232 4.08622 5.22166 4.37871 4.92837ZM11.5673 7.04932C10.3158 5.79754 8.16824 5.5091 6.6287 7.05279C6.3362 7.34607 5.86133 7.34671 5.56804 7.05421C5.27475 6.76171 5.27411 6.28684 5.56661 5.99355C7.77707 3.77715 10.8795 4.23977 12.6281 5.98881C12.9209 6.28174 12.9208 6.75661 12.6279 7.04947C12.335 7.34232 11.8601 7.34226 11.5673 7.04932ZM7.22266 8.99957C6.18712 8.99957 5.34766 9.83903 5.34766 10.8746V14.6246C5.34766 15.6601 6.18712 16.4996 7.22266 16.4996H10.9727C12.0082 16.4996 12.8477 15.6601 12.8477 14.6246V10.8746C12.8477 9.83903 12.0082 8.99957 10.9727 8.99957H7.22266ZM8.04766 10.4996C7.38491 10.4996 6.84766 11.0368 6.84766 11.6996V13.7996C6.84766 14.4623 7.38491 14.9996 8.04766 14.9996H10.1477C10.8104 14.9996 11.3477 14.4623 11.3477 13.7996V11.6996C11.3477 11.0368 10.8104 10.4996 10.1477 10.4996H8.04766Z", fill: color }) }));
};
const DeviceIcon = (props) => {
    const { color, ...restProps } = props;
    return _jsx(Icon, { component: (iconProps) => _jsx(Device, { color: color, ...iconProps }), ...restProps });
};
export default DeviceIcon;
