import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { NO_OF_RETRIES } from 'components/utils/component_loader_utils';
import ErrorBoundary from 'components/utils/error_boundary_component';
import componentLoader from 'components/utils/component_loader_utils';
import AccountComponent from 'components/login/accounts_component';
import ChannelSalesComponent from 'components/dashboard/channel_sales/channel_sales_component';
import RouteErrorBoundary from 'components/utils/RouterErrorBoundary';
import realtimeRoutes from './realtime';
import vehicleRoutes from './vehicles';
import assetsRoutes from './assets';
import typreRoutes from './typres';
import batteryRoutes from './battery';
import tripsRoutes from './trips';
import reportsRoutes from './reports';
import routeRoutes from './routes';
import settingsRoutes from './settings';
import financeRoutes from './finance';
import adminRoutes from './admin';
import vendorsRoutes from './vendors';
import maintenanceRoutes from './maintenance';
import remindersRoutes from './reminders';
import issuesRoutes from './issues';
import internalIssuesRoutes from './internalissues';
import partentriesRoutes from './partentries';
import partstockinventoryRoutes from './partstockinventory';
import workordersRoutes from './workorders';
import epodRoutes from './epod';
import consignmentRoutes from './consignments';
import challansRoutes from './challans';
import orderRoutes from './order';
import hireslipRoutes from './hire-slip';
import vehicleDocumentRoutes from './vehicle-document';
import voucherRoutes from './voucher';
import billsRoutes from './bills';
import tyresAndPartsBillsRoutes from './tyresAndPartsBills';
import billingInfoRoutes from './billing-info';
import gatepassRoutes from './gatepass';
import arrivalRoutes from './arrival';
import transporterAnalyticsRoutes from './transporteranalytics';
import procurementRoutes from './procurement';
import ratechartsRoutes from './ratecharts';
import clientcontractchartsRoutes from './client-contract-charts';
import NotFoundComponent from 'components/utils/NotFoundComponent';
import NotAuthorizedComponent from 'components/utils/NotAuthorizedComponent';
import AccountingStatementRoutes from 'components/dashboard/accounting-statement';
import financingRoutes from './financing';
import paymentsRoutes from './payment';
import advanceRoutes from './advance';
const AlarmsComponent = async () => {
    const Module = await componentLoader(() => import(/* webpackChunkName: "app-dashboard-alerts" */ 'components/dashboard/alerts/alerts_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const AlarmsAnalyticsComponent = async () => {
    const Module = await componentLoader(() => import(/* webpackChunkName: "app-dashboard-alerts-analytics" */ 'components/dashboard/alerts/analytics'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const PayablesCollectionComponent = async () => {
    const Module = await componentLoader(() => import(/* webpackChunkName: "app-dashboard-payable" */ 'components/dashboard/payables/CollectionComponent'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const ReceivablesCollectionComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-receivables" */ 'components/dashboard/receivables/CollectionComponent'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const OverviewComponent = async () => {
    const Module = await componentLoader(() => import(/* webpackChunkName: "app-dashboard-overview" */ 'components/dashboard/overview/header'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const CustomerSwitchComponent = async () => {
    const Module = await componentLoader(() => import(/* webpackChunkName: "app-dashboard-customerswitch" */ 'components/login/CustomerSwitchComponent'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const TimeFenceComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-timefences" */ 'components/dashboard/timefence/time_fences_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const ControlRoomContainerComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-timefences" */ 'components/dashboard/controlroom/control_room_container_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const FuelEntriesComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-fuelEntries" */ 'components/dashboard/vehicles/fuelentries/fuel_entries_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const DtcComponent = async () => {
    const Module = await componentLoader(() => import(/* webpackChunkName: "app-dashboard-dtc" */ 'components/dashboard/dtcV2/DtcComponentV2'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const UpdatePasswordComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-updatePassword" */ 'components/dashboard/users/update_password_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const MaterialStockVisibility = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-material-stock-visibility" */ 'components/dashboard/material_stock/MaterialStockVisibility'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const SafetyComponent = async () => {
    const Module = await componentLoader(() => import(/* webpackChunkName: "app-dashboard-safety" */ 'components/dashboard/safety/Home'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const SettingsRouteWrapper = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-settings" */ 'components/dashboard/settings/settings_route_wrapper'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const FuelStationPriceComponentWrapper = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-fuelstation" */ 'components/dashboard/fuel-station/FuelStationPriceComponent'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const ReceivablesV2Component = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-receivables" */ 'components/dashboard/receivablesv2/ReceivablesV2Component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const MSILDashboardComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-msil-dashboard" */ 'components/dashboard/analytics/msilDashboard'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const dashboardRoutes = [
    {
        path: 'accounts',
        element: (_jsxs(ErrorBoundary, { children: [_jsx(AccountComponent, {}), ","] })),
    },
    {
        path: 'channel-sales',
        element: (_jsx(ErrorBoundary, { children: _jsx(ChannelSalesComponent, {}) })),
    },
    // {
    //     path: 'receivables',
    //     lazy: ReceivablesCollectionComponent,
    // },
    {
        path: 'payables',
        lazy: PayablesCollectionComponent,
    },
    {
        path: 'alarms',
        lazy: AlarmsComponent,
    },
    {
        path: 'alarms/analytics',
        lazy: AlarmsAnalyticsComponent,
    },
    {
        path: 'customerswitch',
        lazy: CustomerSwitchComponent,
    },
    {
        path: 'controlroom',
        lazy: ControlRoomContainerComponent,
    },
    {
        path: 'dtc',
        lazy: DtcComponent,
    },
    {
        path: 'overview',
        lazy: OverviewComponent,
    },
    {
        path: 'timefences',
        lazy: TimeFenceComponent,
    },
    {
        path: 'updatepassword',
        lazy: UpdatePasswordComponent,
    },
    {
        path: 'fuelentries',
        lazy: FuelEntriesComponent,
    },
    {
        path: 'material-stock',
        lazy: MaterialStockVisibility,
    },
    {
        path: 'safety',
        lazy: SafetyComponent,
    },
    {
        path: 'settings',
        lazy: SettingsRouteWrapper,
        children: settingsRoutes,
    },
    {
        path: 'fuelstation-price',
        lazy: FuelStationPriceComponentWrapper,
    },
    {
        path: 'receivables',
        lazy: ReceivablesV2Component,
    },
    {
        path: 'admin',
        children: adminRoutes,
    },
    {
        path: 'assets',
        children: assetsRoutes,
    },
    {
        path: 'battery',
        children: batteryRoutes,
    },
    {
        path: 'issues',
        children: issuesRoutes,
    },
    {
        path: 'internalissues',
        children: internalIssuesRoutes,
    },
    {
        path: 'finance',
        children: financeRoutes,
    },
    {
        path: 'realtime',
        children: realtimeRoutes,
    },
    {
        path: 'reports',
        children: reportsRoutes,
    },
    {
        path: 'routes',
        children: routeRoutes,
    },
    {
        path: 'trips',
        children: tripsRoutes,
    },
    {
        path: 'tyres',
        children: typreRoutes,
    },
    {
        path: 'vehicles',
        children: vehicleRoutes,
    },
    {
        path: 'maintenance',
        children: maintenanceRoutes,
    },
    {
        path: 'vehicle-document',
        children: vehicleDocumentRoutes,
    },
    {
        path: 'vendors',
        children: vendorsRoutes,
    },
    {
        path: 'reminders',
        children: remindersRoutes,
    },
    {
        path: 'partentries',
        children: partentriesRoutes,
    },
    {
        path: 'partstockinventory',
        children: partstockinventoryRoutes,
    },
    {
        path: 'workorders',
        children: workordersRoutes,
    },
    {
        path: 'epod',
        children: epodRoutes,
    },
    {
        path: 'consignments',
        children: consignmentRoutes,
    },
    {
        path: 'challans',
        children: challansRoutes,
    },
    {
        path: 'order-module',
        children: orderRoutes,
    },
    {
        path: 'hire-slip',
        children: hireslipRoutes,
    },
    {
        path: 'voucher',
        children: voucherRoutes,
    },
    {
        path: 'bills',
        children: billsRoutes,
    },
    {
        path: 'tyresAndPartsBills',
        children: tyresAndPartsBillsRoutes,
    },
    {
        path: 'billing-info',
        children: billingInfoRoutes,
    },
    {
        path: 'advance',
        children: advanceRoutes,
    },
    {
        path: 'payments',
        children: paymentsRoutes,
    },
    {
        path: 'gatepass',
        children: gatepassRoutes,
    },
    {
        path: 'arrival',
        children: arrivalRoutes,
    },
    {
        path: 'transporteranalytics',
        children: transporterAnalyticsRoutes,
    },
    {
        path: 'procurement',
        children: procurementRoutes,
    },
    {
        path: 'ratecharts',
        children: ratechartsRoutes,
    },
    {
        path: 'client-contract-charts',
        children: clientcontractchartsRoutes,
    },
    {
        path: 'accounting-statement',
        children: AccountingStatementRoutes,
    },
    {
        path: 'financing',
        children: financingRoutes,
    },
    {
        path: 'msil',
        lazy: MSILDashboardComponent,
    },
    {
        path: '403',
        element: (_jsx(ErrorBoundary, { children: _jsx(NotAuthorizedComponent, {}) })),
    },
    {
        path: '*',
        element: (_jsx(ErrorBoundary, { children: _jsx(NotFoundComponent, {}) })),
    },
];
export default dashboardRoutes;
