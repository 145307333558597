import { jsx as _jsx } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
const Temperature = (props) => {
    const { color } = props;
    return (_jsx("svg", { width: "19", height: "18", viewBox: "0 0 19 18", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { d: "M9.09766 15.75C8.06016 15.75 7.17578 15.3844 6.44453 14.6531C5.71328 13.9219 5.34766 13.0375 5.34766 12C5.34766 11.4 5.47891 10.8406 5.74141 10.3219C6.00391 9.80312 6.37266 9.3625 6.84766 9V4.5C6.84766 3.875 7.06641 3.34375 7.50391 2.90625C7.94141 2.46875 8.47266 2.25 9.09766 2.25C9.72266 2.25 10.2539 2.46875 10.6914 2.90625C11.1289 3.34375 11.3477 3.875 11.3477 4.5V9C11.8227 9.3625 12.1914 9.80312 12.4539 10.3219C12.7164 10.8406 12.8477 11.4 12.8477 12C12.8477 13.0375 12.482 13.9219 11.7508 14.6531C11.0195 15.3844 10.1352 15.75 9.09766 15.75ZM6.84766 12H11.3477C11.3477 11.6375 11.2695 11.3 11.1133 10.9875C10.957 10.675 10.7352 10.4125 10.4477 10.2L9.84766 9.75V4.5C9.84766 4.2875 9.77578 4.10937 9.63203 3.96562C9.48828 3.82187 9.31016 3.75 9.09766 3.75C8.88516 3.75 8.70703 3.82187 8.56328 3.96562C8.41953 4.10937 8.34766 4.2875 8.34766 4.5V9.75L7.74766 10.2C7.46016 10.4125 7.23828 10.675 7.08203 10.9875C6.92578 11.3 6.84766 11.6375 6.84766 12Z", fill: color }) }));
};
const TemperatureIcon = (props) => {
    const { color, ...restProps } = props;
    return _jsx(Icon, { component: (iconProps) => _jsx(Temperature, { color: color, ...iconProps }) });
};
export default TemperatureIcon;
