import { jsx as _jsx } from "react/jsx-runtime";
import RouterErrorBoundary from 'components/utils/RouterErrorBoundary';
import componentLoader, { NO_OF_RETRIES } from 'components/utils/component_loader_utils';
const LiveFuel = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-livefuel" */ 'components/dashboard/report_section/live_fuel/live_fuel'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const VehicleTemperature = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-vehicletemperature" */ 'components/dashboard/vehicles/temperature/vehicle_temperature_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const FuelLevelComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-fuellevel" */ 'components/dashboard/report_section/fuel_level/fuel_level'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const FuelPriceList = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-fuelpricelist" */ 'components/dashboard/report_section/fuel_calculator'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const JobReports = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-jobreports" */ 'components/dashboard/report_section/job_reports/job_reports'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const PlannedVersusActual = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-plannedvsactual" */ 'components/dashboard/report_section/planned_vs_actual/planned_versus_actual'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const CustomReportsComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-customreports" */ 'components/dashboard/report_section/custom_reports/custom_reports'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const AddEditCustomReports = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-addeditcustomreports" */ 'components/dashboard/report_section/CustomReportsV2'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const ScheduleCustomerComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-schedulecustomer" */ 'components/dashboard/report_section/custom_reports/scheduleCustomReport'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const ViewAssetDataInTableFormat = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-viewassetdataintableformat" */ 'components/dashboard/internal_tools/asset_data/view_asset_data_in_table_format'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const DownloadReportSectionComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-downloadreportsection" */ 'components/dashboard/report_section/download_report_section/download_report_section_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const VehicleBatteryPowerComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-vehiclebatterypower" */ 'components/dashboard/vehicles/battery_power/vehicle_battery_power_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const VideoTelematics = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-videotelematics" */ 'components/dashboard/telematics/video_telematics'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const Playback = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-videotelematics" */ 'components/dashboard/telematics/playbackmodule'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const TwoWayCalling = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-twowaycalling" */ 'components/dashboard/telematics/twoWayCalling'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const UploaderSectionComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-uploadersection" */ 'components/dashboard/report_section/uploader_section/uploader_section_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const DocumentsDownloadComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-documentsdownload" */ 'components/dashboard/report_section/documents_download/documents_download_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const UserManagementDashboard = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-usermanagement" */ 'components/dashboard/UserManagement/UserManagementDashboard'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const UserAttendanceDashboard = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-userattendance" */ 'components/dashboard/UserAttendance/UserAttendanceMasterDashboard'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const AdblueLevelComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-adbluelevel" */ 'components/dashboard/report_section/adblue_level/adblue_level'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
// src/components/dashboard/report_section/fuel_level_v2/fuel_level.tsx
const FuelLevelV2 = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-fuellevelv2" */ 'components/dashboard/report_section/fuel_level_v2/fuel_level'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const FuelLevelV3 = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-fuellevelv3vehicle" */ 'components/dashboard/report_section/fuel_level_v3/Home'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const DriverAnalyticsComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-driveranalytics" */ 'components/dashboard/driverAnalytics/driver_analytics_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const ViewDriverAnalyticsComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-viewdriveranalytics" */ 'components/dashboard/driverAnalytics/view_driver_analytics_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const OBDHeath = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-OBDHeath" */ 'components/dashboard/report_section/obd_health/obdHeath'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const reportsRoutes = [
    {
        path: 'documents',
        lazy: DocumentsDownloadComponent,
    },
    {
        path: 'download',
        lazy: DownloadReportSectionComponent,
    },
    {
        path: 'assetdata',
        lazy: ViewAssetDataInTableFormat,
    },
    {
        path: 'livefuel',
        lazy: LiveFuel,
    },
    {
        path: 'vehicletemp',
        lazy: VehicleTemperature,
    },
    {
        path: 'fuellevel',
        lazy: FuelLevelComponent,
    },
    {
        path: 'fuellevelv2',
        lazy: FuelLevelV2,
    },
    {
        path: 'fuellevelv3',
        lazy: FuelLevelV3,
    },
    {
        path: 'fuelpricecalculator',
        lazy: FuelPriceList,
    },
    {
        path: 'driveranalytics',
        lazy: DriverAnalyticsComponent,
    },
    {
        path: 'driveranalytics/view',
        lazy: ViewDriverAnalyticsComponent,
    },
    {
        path: 'jobreports',
        lazy: JobReports,
    },
    {
        path: 'plannedvsactual',
        lazy: PlannedVersusActual,
    },
    {
        path: 'obdhealth',
        lazy: OBDHeath,
    },
    {
        path: 'customreports/add',
        lazy: AddEditCustomReports,
    },
    {
        path: 'customreports/edit/:id',
        lazy: AddEditCustomReports,
    },
    {
        path: 'customreports/:id/schedule',
        lazy: ScheduleCustomerComponent,
    },
    {
        path: 'customreports',
        lazy: CustomReportsComponent,
    },
    {
        path: 'vehiclebatterypower',
        lazy: VehicleBatteryPowerComponent,
    },
    {
        path: 'videos',
        lazy: VideoTelematics,
    },
    {
        path: 'playback',
        lazy: Playback,
    },
    {
        path: 'twowaycalling',
        lazy: TwoWayCalling,
    },
    {
        path: 'uploaders',
        lazy: UploaderSectionComponent,
    },
    {
        path: 'usermanagement',
        lazy: UserManagementDashboard,
    },
    {
        path: 'userattendance',
        lazy: UserAttendanceDashboard,
    },
    {
        path: 'adblue',
        lazy: AdblueLevelComponent,
    },
];
export default reportsRoutes;
