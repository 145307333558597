import {
    find,
    floor,
    forEach,
    get,
    includes,
    isEmpty,
    join,
    last,
    map,
    reduce,
    round,
    set,
    size,
    toSafeInteger,
    uniq,
    split,
    first,
    filter,
    toLower,
    clone,
    groupBy,
    orderBy,
} from 'lodash';
import {
    fetchJobsV2,
    fetchSlabListing,
    fetchJobListNewV2,
    fetchLastJobAllVehicles,
    fetchTimeOnSiteAnalytics,
    fetchWaitingJobs,
    fetchVehicleOnSiteAnalytics,
    fetchVehiclesJobShareUrl,
    fetchJobsReport,
    fetchJobAuditHistoryById,
} from '../../actions/dashboard/route_dispatch/jobs_action';
import {
    addDataRow,
    addHeader,
    createReportFilename,
    getWOverFlow,
    ORIENTATION,
    REPORT_FORMAT,
    startJobReportCreation,
    startReportCreation,
    generateCustomOrderingForHeader,
    aryaTransColMapping,
    shreeMarutiCourierTATTimeReportMapping,
    shreeMarutiCourierJobCustomisedReportMapping,
    getColHorizontalJobReportMappingForAccount,
} from '../report_utils';
import {
    getVehicleStatusUIText,
    JOB_STATUS_VALUES,
    VEHICLE_STATUS_NAME_JOB,
    VEHICLE_JOB_STATE,
    findvehicleStateFromId,
    getJobStatus,
    getLabelForActualDistance,
    RISK_LEVEL_DEF,
} from '../job_route_utils';
import { fetchLiveVehicleData } from '../../actions/dashboard/analytics_action';
import {
    DATE_FORMAT_HUMANIZE,
    DATE_FORMAT_HUMANIZE_DAY,
    DATE_FORMAT_REPORT,
    DATE_FORMAT,
    getMomentTime,
    getTimeDiff,
    getTimeDiffHours,
    DATE_FORMAT_TIME_24_REPORTS,
    getHumanizeTime,
    getDiffFromHHMMSS,
    getHHMMSSFromString,
    getReportTime,
    DATE_FORMAT_UNIX_TIMESTAMP,
    HOURS_24,
    HOURS_6,
    HOURS_1,
    DATE_FORMAT_TIMESTAMP_DAY,
    DATE_FORMAT_ONLY_TIME,
    getNumberWithOrdinal,
} from '../date_utils';

import moment from 'moment-timezone';
import {
    isDtdcAccount,
    getStartDateColumnName,
    getStartDateFormatType,
    areRiskyPointsAvailable,
    getDefaultDistanceFormula,
    getDefaultDelayFormula,
    getGradeThreshold,
    getRiskThreshold,
    isRiskyPointPresenceSupercedeScore,
    getRiskyPointMinuteThreshold,
    getParkingAddressBookId,
    isHorizontalReportEnabled,
    isBirlaDurgapurAccount,
    isJSWAccount,
    FLEETX_ACCOUNTS,
    getColumnNameForHorizontalReportKM,
    isColCustomOrderingForAccount,
    getHaltLimitForAccount,
    showTATCompareColumnsForDiff,
    isTotalStoppageColumnActiveAccount,
    isStoppagePointPresenceSupercedeScore,
    isAllowedAddressPointColumnForAccount,
    isjobPercentDownTimeOpenForAccount,
    isCommodityOpenToAccount,
    isCustomGroupConfigEnabledForAccount,
    getGroupBasedJobReportItems,
    showCustomFieldsInJobSummaryReport,
    isShowColumnsToAccount,
    showDeviceSrNoInHorizontalJobReport,
    isSunFlagAccount,
    isBigBasketAccount,
    showActualDistanceKmInJobTimeline,
    showJobAlarmsForAccount,
    showJobBooking,
    isAdaniCoalAccount,
    jobSummaryExtraColsAllowed,
    isShreeCementOrBirla,
    isMaerskAccount,
    isShreeCement8000,
    showScheduledArrivalForAccount,
    isjklcAccount,
    accountsForChanderia1505LikeJobSummaryReport,
    getRemoveColumnMappingForAccount,
    isMiaharRakeAccount,
    isIntercityCareersAccount,
    isShreemanLogisticsAccount,
} from '../account_utils';
import { fetchTripsWithoutAggregations } from '../../actions/dashboard/trips_action';
import {
    createStoppageData,
    getMergedListWithVehicles,
    isKnownRiskyPoint,
    isKnownRoutePoint,
    delay,
    parseJobNotes,
    isAllowedStopPoint,
    EXCEL_COL,
    PLANNED_VERSUS_ACTUAL,
    parseAddress,
    getGroupName,
    handleError,
} from '../../constant';
import { createShareLocation, fetchAnalyticsLive } from '../../actions/dashboard/realtime_action';
import { addFromToBranchStationDataToRow, getTagNamesList } from '../tag_utils';
import { refactorCurrentStatus, VEHICLE_STATUS } from '../vehicle_utils';
import { getCustomFieldObjectFromIds, getCustomFieldObjectFromKeyName } from '../custom_field_utils';
import { getTaxSlabValue } from '../finance_utils';
import { getGoogleMapLink } from '../map/map_utils';
import { fetchMetrics } from '../../actions/dashboard/planned_vs_actual_actions';
import { fetchUserListMiniV2, fetchUsersByType } from '../../actions/dashboard/users_action';
import { BILL_NUMBER_TYPES, getJobDistanceRoundedValue } from '../job_utils';
import { getCommaSeparatedCommodites } from '../commodity_utils';
import { fetchAccountVehicleAnalytics } from '../../actions/dashboard/vehicles_action';
import {
    fetchJobTransactionSummary,
    fetchTransactionExpensesForJobs,
} from '../../actions/dashboard/finances/transaction_action';
import { arrayToObject, sortListNew } from '../list_utils';
import { showJobDowntime } from 'utils/accountUtils/jobs';

export const DEAFULT_EXPIRY_DAYS = 2;

export function getDistanceFormula(routeId, column, index, type, loggedInUser) {
    let formula;
    const route = find(isKnownRoutePoint, { id: routeId });
    if (route) {
        formula =
            type === 'RISK'
                ? `+IF(${column}${index}<${route.thresholdsDistance[0]},"N",IF(${column}${index}<${route.thresholdsDistance[1]},"C","E"))`
                : `+IF(${column}${index}<${route.thresholdsDistance[0]},0,IF(${column}${index}<${route.thresholdsDistance[1]},3,5))`;
    } else {
        formula = getDefaultDistanceFormula(loggedInUser, type, column, index);
    }
    return formula;
}

export function getStoppageThresholdInMinutes(loggedInUser) {
    const accountId = get(loggedInUser, 'accountId', '');
    switch (accountId) {
        case FLEETX_ACCOUNTS.SHREE_CEMENT:
            return 75;
        default:
            return 30;
    }
}

export function getStoppageTimeThresholdInMinutes(loggedInUser) {
    const accountId = get(loggedInUser, 'accountId', '');
    switch (accountId) {
        case FLEETX_ACCOUNTS.FLEETX:
        case FLEETX_ACCOUNTS.BHARATHI_CEMENT:
            return 60;
        default:
            return 60;
    }
}

export function getDelayFormula(routeId, column, index, type, loggedInUser) {
    let formula;
    const route = find(isKnownRoutePoint, { id: routeId });
    if (route) {
        formula =
            type === 'RISK'
                ? `+IF(${column}${index}<${route.thresholdsDelay[0]},"N",IF(${column}${index}<${route.thresholdsDelay[1]},"C","E"))`
                : `+IF(${column}${index}<${route.thresholdsDelay[0]},0,IF(${column}${index}<${route.thresholdsDelay[1]},3,5))`;
    } else {
        formula = getDefaultDelayFormula(loggedInUser, type, column, index);
    }
    return formula;
}

export async function triggerJupiterJobReportDownload(
    accesstoken,
    reportFormat,
    filterConfig,
    jobsSummary,
    vehicleId,
    groupId,
    startDate,
    endDate,
    currentStatusFilter,
    pointStatusFilter,
    routeIdFilter,
    notesFilter,
    onlyPointsFilter,
    groupName,
    vehicleNumber,
    customer,
    customerId,
    returnData
) {
    if (!jobsSummary || size(jobsSummary) <= 0) {
        return;
    }

    const jobSize = reduce(
        jobsSummary,
        function (result, value, key) {
            if (includes(['EARLY', 'LATE', 'ONTIME', 'SCHEDULED'], key)) {
                return result + value;
            }
            return result;
        },
        0
    );

    const jobResult = await fetchJobListNewV2(accesstoken, {
        vehicleId,
        groupId,
        from: startDate,
        to: endDate,
        sortByDateAsc: false,
        currentStatusFilter,
        pointStatusFilter,
        routeIdFilter,
        notesFilter,
        onlyPointsFilter,
        selectedCustomerId: customerId,
        page: 1,
        size: jobSize,
    }).promise;

    const reportName = createReportFilename(
        reportFormat,
        'Jupiter Express-Trip Sheet',
        vehicleNumber,
        groupName,
        moment(startDate),
        moment(endDate),
        null,
        null,
        customer
    );

    const headers = [];
    const footerRow = {};

    let liveData;

    const includeJobDistance = get(filterConfig, 'includeJobDistance', true);
    const includePercentJobCompleted = get(filterConfig, 'includePercentJobCompleted', true);
    const includePredictedDelay = get(filterConfig, 'includePredictedDelay', true);
    const includeJobNotes = get(filterConfig, 'includeJobNotes', true);
    const includeTat = get(filterConfig, 'includeTat', true);
    const includeNotes = get(filterConfig, 'includeNotes', true);
    const includeCurrentLocation = get(filterConfig, 'includeCurrentLocation', true);
    const includeCurrentStatus = get(filterConfig, 'includeCurrentStatus', true);
    const includeWeight = get(filterConfig, 'includeWeight', true);
    const includeDocketNumber = get(filterConfig, 'includeDocketNumber', true);
    const includeInvoiceNumber = get(filterConfig, 'includeInvoiceNumber', true);
    const includePackageCount = get(filterConfig, 'includePackageCount', true);
    const includeVehicleLoad = get(filterConfig, 'includeVehicleLoad', true);
    const includeFreightFare = get(filterConfig, 'includeFreightFare', true);
    const wName = 80,
        wOrigin = 80,
        wStartTime = 90,
        wDestination = 100,
        wEndTime = 80,
        wConsignor = 90,
        wVehicleState = 70,
        wAllottedTat = 50,
        wActualTat = 100,
        wStatus = 80,
        wStatusText = 100,
        wDuration = 80,
        wDistance = 80;

    const jobs = jobResult.data.content;

    if (includeCurrentLocation || includeCurrentStatus) {
        const idsArr = [];
        forEach(jobs, function (job) {
            if (job.status !== JOB_STATUS_VALUES.COMPLETED) {
                if (get(job, 'vehicle.id')) {
                    idsArr.push(job.vehicle.id);
                }
            }
        });

        if (idsArr.length > 0) {
            const liveResult = await fetchLiveVehicleData(accesstoken, join(uniq(idsArr), ',')).promise;
            liveData = liveResult.data;
        }
    }

    const isCSV = reportFormat === REPORT_FORMAT.CSV;

    let wOverflow = 0;
    wOverflow += getWOverFlow(includeVehicleLoad, wVehicleState);
    wOverflow += getWOverFlow(includeTat, wAllottedTat);
    wOverflow += getWOverFlow(includeTat, wActualTat);
    wOverflow += getWOverFlow(includeJobDistance, wDistance);

    wOverflow = floor(wOverflow / (1 + includeVehicleLoad + includeTat + includeTat + includeJobDistance));

    let noOfCols = 0;
    noOfCols += addHeader(true, headers, 1, 'Name', wName, footerRow);
    noOfCols += addHeader(vehicleNumber ? false : true, headers, 2, 'Vehicle Name', 80, footerRow);
    noOfCols += addHeader(vehicleNumber ? false : true, headers, 3, 'Vehicle Number', 80, footerRow);
    noOfCols += addHeader(vehicleNumber ? false : true, headers, 4, 'Driver Name', 80, footerRow);
    noOfCols += addHeader(vehicleNumber ? false : true, headers, 5, 'Driver Number', 80, footerRow);
    noOfCols += addHeader(vehicleNumber ? false : true, headers, 6, 'Date', 80, footerRow);
    noOfCols += addHeader(true, headers, 7, 'Origin', wOrigin, footerRow);
    noOfCols += addHeader(true, headers, 8, 'Start Time', wStartTime, footerRow);
    noOfCols += addHeader(true, headers, 9, 'Destination', wDestination, footerRow);
    noOfCols += addHeader(true, headers, 10, 'End Time', wEndTime, footerRow);
    noOfCols += addHeader(true, headers, 11, 'Consignor', wConsignor, footerRow);
    noOfCols += addHeader(includeVehicleLoad, headers, 12, 'State', wVehicleState + wOverflow, footerRow);
    noOfCols += addHeader(includeTat, headers, 13, 'TAT', wAllottedTat + wOverflow, footerRow);
    noOfCols += addHeader(includeTat, headers, 14, 'Actual TAT', wActualTat + wOverflow, footerRow);
    noOfCols += addHeader(true, headers, 15, 'Status', wStatus, footerRow);
    noOfCols += addHeader(true, headers, 16, 'Delay/Early', wStatusText, footerRow);
    noOfCols += addHeader(vehicleNumber ? false : true, headers, 17, 'Duration', wDuration, footerRow);
    noOfCols += addHeader(includeCurrentLocation && isCSV, headers, 18, 'Current Location ', 150, footerRow);
    noOfCols += addHeader(includeCurrentStatus && isCSV, headers, 19, 'Current Status', 150, footerRow);
    noOfCols += addHeader(includeJobDistance, headers, 20, 'Distance', wDistance + wOverflow, footerRow);
    noOfCols += addHeader(includePercentJobCompleted && isCSV, headers, 21, '% Job Completed', 150, footerRow);
    noOfCols += addHeader(includePredictedDelay && isCSV, headers, 22, 'Predicted Delay', 150, footerRow);
    noOfCols += addHeader(includeJobNotes && isCSV, headers, 23, 'Job Notes', 150, footerRow);
    noOfCols += addHeader(includeNotes && isCSV, headers, 24, 'Notes', 150, footerRow);
    noOfCols += addHeader(includeWeight && isCSV, headers, 25, 'Weight', 150, footerRow);
    noOfCols += addHeader(includeDocketNumber && isCSV, headers, 26, 'Docket Number', 150, footerRow);
    noOfCols += addHeader(includeInvoiceNumber && isCSV, headers, 27, 'Invoice Number', 150, footerRow);
    noOfCols += addHeader(includePackageCount && isCSV, headers, 28, 'Package Count', 150, footerRow);
    noOfCols += addHeader(includeFreightFare && isCSV, headers, 29, 'Freight Fare', 150, footerRow);
    const data = [];

    const length = jobs.length;
    let driverName, driverNumber;

    for (let i = 0; i < length; i++) {
        const job = jobs[i];
        const {
            jobAnalytics,
            name,
            currentFuel,
            currentKm,
            notes,
            predictedDelay,
            route,
            startDate,
            status,
            vehicle,
            customer,
            vehicleLoad,
            id,
        } = job;
        driverName = get(vehicle, 'activeAssignment.user.name', '');
        driverNumber = get(vehicle, 'activeAssignment.user.phoneNumber', '');
        const jobAnalyticsLength = jobAnalytics.length;
        if (jobAnalyticsLength === 1) {
            const dataRow = { id: id };
            addDataRow(true, dataRow, 1, name);
            addDataRow(vehicleNumber ? false : true, dataRow, 2, get(vehicle, 'name', '-'));
            addDataRow(vehicleNumber ? false : true, dataRow, 3, get(vehicle, 'licensePlate', '-'));
            addDataRow(vehicleNumber ? false : true, dataRow, 4, get(vehicle, 'activeAssignment.user.name', ''));
            addDataRow(vehicleNumber ? false : true, dataRow, 5, get(vehicle, 'activeAssignment.user.phoneNumber', ''));
            addDataRow(vehicleNumber ? false : true, dataRow, 6, getHumanizeTime(startDate));
            addDataRow(true, dataRow, 7, get(jobAnalytics[0], 'routePoint.address'));
            addDataRow(
                true,
                dataRow,
                8,
                jobAnalytics[0].actualDeparture
                    ? getMomentTime(jobAnalytics[0].actualDeparture).format(DATE_FORMAT_HUMANIZE)
                    : ''
            );
            addDataRow(true, dataRow, 9, '');
            addDataRow(true, dataRow, 10, '');
            addDataRow(true, dataRow, 11, customer ? customer.name : '');
            addDataRow(includeVehicleLoad, dataRow, 12, vehicleLoad ? getVehicleStatusUIText(vehicleLoad) : '');
            addDataRow(includeTat, dataRow, 13, '');
            addDataRow(includeTat, dataRow, 14, '');
            addDataRow(true, dataRow, 15, status);
            addDataRow(true, dataRow, 16, '');
            const time = jobAnalytics[0].actualArrival
                ? jobAnalytics[0].actualArrival
                : jobAnalytics[0].actualDeparture;
            addDataRow(
                vehicleNumber ? false : true,
                dataRow,
                17,
                time ? getTimeDiff(moment().valueOf() - time, false, true) : ''
            );

            const vehicleLive = get(vehicle, 'id') && find(liveData, { vehicleId: vehicle.id });
            addDataRow(includeCurrentLocation && isCSV, dataRow, 18, vehicleLive ? vehicleLive.address : '');
            addDataRow(
                includeCurrentStatus && isCSV,
                dataRow,
                19,
                vehicleLive ? VEHICLE_STATUS_NAME_JOB[vehicleLive.currentStatus] : ''
            );
            addDataRow(includeJobDistance, dataRow, 20, `${getJobDistanceRoundedValue(currentKm)} km`);
            addDataRow(includePercentJobCompleted && isCSV, dataRow, 21, '');
            addDataRow(includePredictedDelay && isCSV, dataRow, 22, '');
            addDataRow(includeJobNotes && isCSV, dataRow, 23, notes ? notes : '');
            addDataRow(includeNotes && isCSV, dataRow, 24, '');
            addDataRow(includeWeight && isCSV, dataRow, 25, '');
            addDataRow(includeDocketNumber && isCSV, dataRow, 26, '');
            addDataRow(includeInvoiceNumber && isCSV, dataRow, 27, '');
            addDataRow(includePackageCount && isCSV, dataRow, 28, '');
            addDataRow(includeFreightFare, dataRow, 29, '');
            data.push(dataRow);
        } else {
            for (let j = 1; j < jobAnalyticsLength; j++) {
                const dataRow = { id: id };
                addDataRow(true, dataRow, 1, name);
                addDataRow(vehicleNumber ? false : true, dataRow, 2, get(vehicle, 'name', '-'));
                addDataRow(vehicleNumber ? false : true, dataRow, 3, get(vehicle, 'licensePlate', '-'));
                addDataRow(vehicleNumber ? false : true, dataRow, 4, get(vehicle, 'activeAssignment.user.name', ''));
                addDataRow(
                    vehicleNumber ? false : true,
                    dataRow,
                    5,
                    get(vehicle, 'activeAssignment.user.phoneNumber', '')
                );
                addDataRow(vehicleNumber ? false : true, dataRow, 6, getHumanizeTime(startDate));
                addDataRow(true, dataRow, 7, get(jobAnalytics[0], 'routePoint.address'));
                addDataRow(
                    true,
                    dataRow,
                    8,
                    jobAnalytics[0].actualDeparture ? getHumanizeTime(jobAnalytics[0].actualDeparture) : ''
                );
                addDataRow(true, dataRow, 9, get(jobAnalytics[j], 'routePoint.address', '-'));
                addDataRow(
                    true,
                    dataRow,
                    10,
                    jobAnalytics[j].actualArrival ? getHumanizeTime(jobAnalytics[j].actualArrival) : ''
                );
                addDataRow(true, dataRow, 11, customer ? customer.name : '');
                const tat = jobAnalytics[j - 1] ? jobAnalytics[j - 1].transitTime : null;
                addDataRow(
                    includeVehicleLoad,
                    dataRow,
                    12,
                    jobAnalytics[j - 1].vehicleState ? getVehicleStatusUIText(jobAnalytics[j - 1].vehicleState) : ''
                );
                const transitTime =
                    jobAnalytics[j].scheduledArrival && jobAnalytics[j - 1].scheduledDeparture
                        ? jobAnalytics[j].scheduledArrival - jobAnalytics[j - 1].scheduledDeparture
                        : null;
                addDataRow(
                    includeTat,
                    dataRow,
                    13,
                    tat ? getTimeDiffHours(tat) : transitTime ? getTimeDiffHours(transitTime) : ''
                );
                const actualTransitTime =
                    jobAnalytics[j].actualArrival && jobAnalytics[j - 1].actualDeparture
                        ? jobAnalytics[j].actualArrival - jobAnalytics[j - 1].actualDeparture
                        : null;
                addDataRow(includeTat, dataRow, 14, actualTransitTime ? getTimeDiffHours(actualTransitTime) : '');
                const jobAnalyticsStatus = jobAnalytics[j].status;
                if (jobAnalyticsLength != 2 || (jobAnalyticsLength === 2 && jobAnalyticsStatus === 'MISSED')) {
                    addDataRow(true, dataRow, 15, jobAnalyticsStatus);
                } else {
                    addDataRow(true, dataRow, 15, status);
                }

                const tatVal = tat ? tat : transitTime ? transitTime : null;
                const delayVal = tatVal && actualTransitTime ? actualTransitTime - tatVal : null;
                addDataRow(
                    true,
                    dataRow,
                    16,
                    delayVal ? `${getTimeDiff(Math.abs(delayVal), false, true)} ${delayVal > 0 ? 'late' : 'early'}` : ''
                );
                const originTime = jobAnalytics[0].actualArrival
                    ? jobAnalytics[0].actualArrival
                    : jobAnalytics[0].actualDeparture;
                const destinationTime = jobAnalytics[j].actualDeparture
                    ? jobAnalytics[j].actualDeparture
                    : jobAnalytics[j].actualArrival;
                const isCurrent =
                    j + 1 === length ||
                    jobAnalytics[j].status !== 'COMPLETED' ||
                    jobAnalytics[j].status !== 'LATE' ||
                    jobAnalytics[j].status !== 'EARLY' ||
                    jobAnalytics[j].status !== 'MISSED';
                if (jobAnalyticsStatus === 'MISSED') {
                    addDataRow(vehicleNumber ? false : true, dataRow, 17, '');
                } else {
                    addDataRow(
                        vehicleNumber ? false : true,
                        dataRow,
                        17,
                        jobAnalytics[j].status === 'COMPLETED' ||
                            jobAnalytics[j].status === 'LATE' ||
                            jobAnalytics[j].status === 'EARLY'
                            ? getTimeDiff(destinationTime - originTime, false, true)
                            : isCurrent
                            ? getTimeDiff(moment().valueOf() - originTime, false, true)
                            : ''
                    );
                }

                const vehicleLive = get(vehicle, 'id') && find(liveData, { vehicleId: vehicle.id });
                addDataRow(includeCurrentLocation && isCSV, dataRow, 18, vehicleLive ? vehicleLive.address : '');
                addDataRow(
                    includeCurrentStatus && isCSV,
                    dataRow,
                    19,
                    vehicleLive ? VEHICLE_STATUS_NAME_JOB[vehicleLive.currentStatus] : ''
                );
                addDataRow(
                    includeJobDistance,
                    dataRow,
                    20,
                    isCurrent ? `${getJobDistanceRoundedValue(currentKm)} km` : ''
                );
                addDataRow(
                    includePercentJobCompleted && isCSV,
                    dataRow,
                    21,
                    status === JOB_STATUS_VALUES.STARTED && currentKm < route.distance && isCurrent
                        ? `${round((currentKm / route.distance) * 100, 2)}%`
                        : ''
                );
                addDataRow(
                    includePredictedDelay && isCSV,
                    dataRow,
                    22,
                    isCurrent && predictedDelay != 0 ? getTimeDiff(Math.abs(predictedDelay), false, true) : ''
                );
                addDataRow(includeJobNotes && isCSV, dataRow, 23, notes ? notes : '');

                addDataRow(includeNotes && isCSV, dataRow, 24, jobAnalytics[j].notes ? jobAnalytics[j].notes : '');
                addDataRow(includeWeight && isCSV, dataRow, 25, jobAnalytics[j].weight ? jobAnalytics[j].weight : '');
                addDataRow(
                    includeDocketNumber && isCSV,
                    dataRow,
                    26,
                    jobAnalytics[j].docketNumber ? jobAnalytics[j].docketNumber : ''
                );
                addDataRow(
                    includeInvoiceNumber && isCSV,
                    dataRow,
                    27,
                    jobAnalytics[j].invoiceNumber ? jobAnalytics[j].invoiceNumber : ''
                );
                addDataRow(
                    includePackageCount && isCSV,
                    dataRow,
                    28,
                    jobAnalytics[j].packageCount ? jobAnalytics[j].packageCount : ''
                );

                addDataRow(
                    includeFreightFare && isCSV,
                    dataRow,
                    29,
                    jobAnalytics[j].freightFare ? jobAnalytics[j].freightFare : ''
                );
                data.push(dataRow);
            }
        }
    }
    const totalRow = {};

    addDataRow(true, totalRow, 1, `TOTAL \n${data.length} Jobs`);
    addDataRow(vehicleNumber ? false : true, totalRow, 2, `-`);
    addDataRow(vehicleNumber ? false : true, totalRow, 3, `-`);
    addDataRow(vehicleNumber ? false : true, totalRow, 4, `-`);
    addDataRow(vehicleNumber ? false : true, totalRow, 5, `-`);
    addDataRow(vehicleNumber ? false : true, totalRow, 6, `-`);
    addDataRow(true, totalRow, 7, `-`);
    addDataRow(true, totalRow, 8, `-`);
    addDataRow(true, totalRow, 9, `-`);
    addDataRow(true, totalRow, 10, `-`);
    addDataRow(true, totalRow, 11, `-`);
    addDataRow(includeVehicleLoad, totalRow, 12, `-`);
    addDataRow(includeTat, totalRow, 13, `-`);
    addDataRow(includeTat, totalRow, 14, `-`);
    addDataRow(true, totalRow, 15, `-`);
    addDataRow(true, totalRow, 16, `-`);
    addDataRow(vehicleNumber ? false : true, totalRow, 17, `-`);
    addDataRow(includeCurrentLocation && isCSV, totalRow, 18, `-`);
    addDataRow(includeCurrentStatus && isCSV, totalRow, 19, `-`);
    addDataRow(includeJobDistance, totalRow, 20, `-`);
    addDataRow(includePercentJobCompleted && isCSV, totalRow, 21, `-`);
    addDataRow(includePredictedDelay && isCSV, totalRow, 22, `-`);
    addDataRow(includeJobNotes && isCSV, totalRow, 23, `-`);
    addDataRow(includeNotes && isCSV, totalRow, 24, `-`);
    addDataRow(includeWeight && isCSV, totalRow, 25, `-`);
    addDataRow(includeDocketNumber && isCSV, totalRow, 26, `-`);
    addDataRow(includeInvoiceNumber && isCSV, totalRow, 27, `-`);
    addDataRow(includePackageCount && isCSV, totalRow, 28, `-`);
    addDataRow(includeFreightFare && isCSV, totalRow, 29, `-`);

    if (returnData) {
        return [headers, data];
    } else {
        data.push(totalRow);
    }

    if (vehicleNumber) {
        startJobReportCreation(
            ORIENTATION.LANDSCAPE,
            headers,
            data,
            reportFormat,
            'Jupiter Express-Trip Sheet',
            moment(startDate),
            moment(endDate),
            vehicleNumber,
            reportName,
            noOfCols,
            driverName,
            driverNumber
        );
    } else {
        startReportCreation(
            ORIENTATION.LANDSCAPE,
            headers,
            data,
            reportFormat,
            'Jupiter Express-Trip Sheet',
            moment(startDate),
            moment(endDate),
            vehicleNumber,
            reportName,
            noOfCols
        );
    }
}

export const horizontalJobReportColOrdering = () => {
    const accountId = get(window.FLEETX_LOGGED_IN_USER, 'accountId');
    if (accountId === FLEETX_ACCOUNTS.SOTC) {
        return [
            'jobName',
            'consignor',
            'vehicleName',
            'vehicleNumber',
            'vehicleModel',
            'groupNames',
            'driverName',
            'driverNumber',
            'startDate',
            'originLocation',
            'destination',
            'arrivalTimeOrigin',
            'departureTimeOrigin',
            'unloadingDuration',
            1, // job status
        ];
    } else {
        return [];
    }
};

export function orderHeaders(headers, fieldListInOrder = horizontalJobReportColOrdering()) {
    if (!isEmpty(fieldListInOrder)) {
        headers.sort(function (h1, h2) {
            // asc
            const findIndexH1 = fieldListInOrder.indexOf(h1.name);
            const findIndexH2 = fieldListInOrder.indexOf(h2.name);
            if (findIndexH1 !== -1 && findIndexH2 !== -1) {
                return findIndexH1 > findIndexH2 ? 1 : -1;
            } else if (findIndexH1 !== -1) {
                return -1;
            } else {
                return 1;
            }
        });
    }
    return headers;
}

export async function triggerHorizontalJobReportDownload(
    accesstoken,
    filterConfig,
    jobResult,
    reportFormat,
    liveData,
    startDate,
    endDate,
    vehicleNumber,
    reportName,
    loggedInUser,
    addressBookAsMap,
    groupBasedReportItemEnabled,
    { jobsTransactionExpenses, jobsTransactionExpensesUniqueCols },
    tags,
    groupList,
    userListMiniAsMap,
    isAryanTransReport,
    weighmentJob,
    movementType
) {
    let accountId = get(loggedInUser, 'accountId');
    let groupBasedReportItems = null;
    if (groupBasedReportItemEnabled) {
        groupBasedReportItems = getGroupBasedJobReportItems(loggedInUser);
    }
    //it will only support 10
    let headers = [];
    const footerRow = {};
    const includeJobName = get(filterConfig, 'includeJobName', true);
    const includeVehicleNumber = get(filterConfig, 'includeVehicleNumber', true);
    const includeJobStartTime = get(filterConfig, 'includeJobStartTime', true);
    const includePlantEntryTime = get(filterConfig, 'includePlantEntryTime', true);
    const includePlantExitTime = get(filterConfig, 'includePlantExitTime', true);
    const includePlantTAT = get(filterConfig, 'includePlantTAT', true);
    const includeDestinationEntryTime = get(filterConfig, 'includeDestinationEntryTime', true);
    const includeDestinationExitTime = get(filterConfig, 'includeDestinationExitTime', true);
    const includeReturnTripTAT = get(filterConfig, 'includeReturnTripTAT', true);
    const includeRoundTripTAT = get(filterConfig, 'includeRoundTripTAT', true);
    const includeJobDistance = get(filterConfig, 'includeJobDistance', true);
    const includeActualDistance = showActualDistanceKmInJobTimeline();
    const includePercentJobCompleted = get(filterConfig, 'includePercentJobCompleted', true);
    const includePredictedDelay = get(filterConfig, 'includePredictedDelay', true);
    const includeJobNotes = get(filterConfig, 'includeJobNotes', true);
    const includeTat = get(filterConfig, 'includeTat', true);
    const includeQuantity = isCommodityOpenToAccount(loggedInUser);
    const includeNotes = get(filterConfig, 'includeNotes', true);
    const includeCurrentLocation = get(filterConfig, 'includeCurrentLocation', true);
    const includeCurrentStatus = get(filterConfig, 'includeCurrentStatus', true);
    const includeWeight = get(filterConfig, 'includeWeight', true);
    const includeDocketNumber = get(filterConfig, 'includeDocketNumber', true);
    const includeInvoiceNumber = get(filterConfig, 'includeInvoiceNumber', true);
    const includePackageCount = get(filterConfig, 'includePackageCount', true);
    const includeVehicleLoad = get(filterConfig, 'includeVehicleLoad', true);
    const includeVehicleLoadState = get(filterConfig, 'includeVehicleLoadState', true);
    const includeFreightFare = get(filterConfig, 'includeFreightFare', true);
    const includeOpeningOdo = get(filterConfig, 'includeOpeningOdo', true);
    const includeClosingOdo = get(filterConfig, 'includeClosingOdo', true);
    const includeBillNumbers = get(filterConfig, 'includeBillNumbers', false);
    const includeCustomField = get(filterConfig, 'includeCustomField', true);
    const includeTransporter = get(filterConfig, 'includeTransporter', false);
    const includeJobExpenses = get(filterConfig, 'includeJobExpenses', false);
    const includeProfitAndLoss = get(filterConfig, 'includeProfitAndLoss', false);
    const includeTags = get(filterConfig, 'includeTags', false);
    const includeGroups = get(filterConfig, 'includeGroups', false);
    const includeJobLink = get(filterConfig, 'includeJobLink', false);
    const isEnableForBigBasket = isBigBasketAccount(loggedInUser);
    const isEnableForAdaniCoal = isAdaniCoalAccount(loggedInUser);
    const includeJobAlarms = get(filterConfig, 'includeJobAlarm', false);
    const includeEPODDetails = get(filterConfig, 'includeEPOD', false);

    const isShriMarutiCourierTATTimeReport = get(filterConfig, 'isShriMarutiCourierTATTimeReport', false);
    const isShriMarutiCourierJobCustomizedReport = get(filterConfig, 'isShriMarutiCourierJobCustomizedReport', false);

    const includeVendor = get(filterConfig, 'includeVendor', true);
    const includeDeviationKm = get(filterConfig, 'includeDeviationKm', true);
    const includeDeviationCount = get(filterConfig, 'includeDeviationCount', true);

    const includeRfVerifiedTime = get(filterConfig, 'includeRfVerifiedTime', false);

    const includeDeviceSerialNo =
        showDeviceSrNoInHorizontalJobReport(loggedInUser) ||
        get(filterConfig, 'includeDeviceSerialNo', false) ||
        includes([2857], accountId);
    const isMiaharAccount = isMiaharRakeAccount(loggedInUser);
    const intercityCareersAccount = isIntercityCareersAccount(loggedInUser);
    const isShreemanAccount = isShreemanLogisticsAccount(loggedInUser);

    let timezone = get(loggedInUser, 'timezone');
    const jobs = jobResult;
    const length = jobs.length;
    let maxJobPointLength = 0;
    let allCustomFieldsPresent = [];
    const collectedJobIds = [];
    for (let i = 0; i < length; i++) {
        const job = jobs[i];
        collectedJobIds.push(job.id);
        const jobAnalytics = jobs[i].jobAnalytics;
        if (jobAnalytics && jobAnalytics.length > maxJobPointLength) {
            maxJobPointLength = jobAnalytics.length;
        }
        if (includeCustomField && job.customFields) {
            for (let i = 0; i < job.customFields.length; i++) {
                allCustomFieldsPresent.push(get(job.customFields[i], 'field.id'));
            }
        }
        if (includeCustomField && jobAnalytics.length > 1) {
            for (let i = 0; i < jobAnalytics.length; i++) {
                if (jobAnalytics[i].customFields) {
                    for (let j = 0; j < jobAnalytics[i].customFields.length; j++) {
                        allCustomFieldsPresent.push(get(jobAnalytics[i].customFields[j], 'field.id'));
                    }
                }
            }
        }
    }

    const showFuelDiffSummary = includeJobExpenses;
    let jobTransactionSummary = {};
    // job transaction summary
    if (showFuelDiffSummary && !isEmpty(collectedJobIds)) {
        try {
            const result = await fetchJobTransactionSummary(accesstoken, collectedJobIds).promise;
            jobTransactionSummary = arrayToObject(get(result, 'data', []), 'jobId');
        } catch (e) {
            console.log(e);
        }
    }

    let pickedHeaders = [];
    if (isShriMarutiCourierJobCustomizedReport) {
        pickedHeaders = shreeMarutiCourierJobCustomisedReportMapping; //inital
    }

    allCustomFieldsPresent = uniq(allCustomFieldsPresent);
    allCustomFieldsPresent = await getCustomFieldObjectFromIds(allCustomFieldsPresent, accesstoken);

    const isCSV = reportFormat === REPORT_FORMAT.CSV;

    let noOfCols = 0;
    let j = 0;

    noOfCols += addHeader(
        (includeJobName || isEnableForBigBasket) && !isShreemanAccount,
        headers,
        'jobName',
        'Job Name',
        150,
        footerRow
    );
    noOfCols += addHeader(isShreemanAccount, headers, 'jobName', 'Token Name', 150, footerRow);
    noOfCols += addHeader(
        !isEnableForBigBasket && !isEnableForAdaniCoal && !isShreemanAccount,
        headers,
        'consignor',
        'Consignor',
        150,
        footerRow
    );
    noOfCols += addHeader(
        !isEnableForBigBasket && !isShreemanAccount,
        headers,
        'vehicleName',
        'Vehicle Name',
        150,
        footerRow
    );
    noOfCols += addHeader(isShreemanAccount, headers, 'vehicleName', 'Vehicle Type', 150, footerRow);
    noOfCols += addHeader(!isShreemanAccount, headers, 'vehicleModel', 'Vehicle Model', 150, footerRow);
    noOfCols += addHeader(isShreemanAccount, headers, 'vehicleModel', 'Vehicle Make & Model', 150, footerRow);
    noOfCols += addHeader(includeVehicleNumber, headers, 'vehicleNumber', 'Vehicle Number', 150, footerRow);
    noOfCols += addHeader(includeGroups && !isEnableForBigBasket, headers, 'groupNames', 'Group', 150, footerRow);
    noOfCols += addHeader(includeDeviceSerialNo, headers, 'deviceSerialNo', 'Device Serial No', 150, footerRow);
    noOfCols += addHeader(
        !isEnableForBigBasket && !isShreemanAccount,
        headers,
        'driverName',
        'Driver Name',
        150,
        footerRow
    );
    noOfCols += addHeader(
        !isEnableForBigBasket && !isShreemanAccount,
        headers,
        'driverNumber',
        'Driver Number',
        150,
        footerRow
    );
    noOfCols += addHeader(
        !isEnableForBigBasket && !intercityCareersAccount && !isShreemanAccount,
        headers,
        'haltTime',
        isEnableForAdaniCoal ? 'Planned Loading TAT' : 'Halt Time',
        150,
        footerRow
    );

    noOfCols += addHeader(
        isAryanTransReport,
        headers,
        'arrivalTimeAtDestination',
        'Arrival Time At Destination(End Point)',
        150,
        footerRow
    );
    noOfCols += addHeader(
        isAryanTransReport,
        headers,
        'scheduledArrivalTimeAtDestination',
        'Scheduled Arrival Time At Destination(End Point)',
        150,
        footerRow
    );

    noOfCols += addHeader(
        (groupBasedReportItemEnabled ? get(groupBasedReportItems, 'routeId', false) : true) &&
            !isEnableForBigBasket &&
            !isShreemanAccount,
        headers,
        'routeId',
        'Route Id',
        150,
        footerRow
    );
    noOfCols += addHeader(
        (groupBasedReportItemEnabled ? get(groupBasedReportItems, 'routeName', false) : true) &&
            !isEnableForBigBasket &&
            !isShreemanAccount,
        headers,
        'routeName',
        'Route Name',
        150,
        footerRow
    );
    noOfCols += addHeader(
        (groupBasedReportItemEnabled ? get(groupBasedReportItems, 'routeName', false) : true) && isShreemanAccount,
        headers,
        'routeName',
        'Route Points',
        150,
        footerRow
    );

    noOfCols += addHeader(
        includeTags && !isEnableForBigBasket && !intercityCareersAccount && !isShreemanAccount,
        headers,
        'vehicleTags',
        'Vehicle Tags',
        0,
        null
    );
    noOfCols += addHeader(includeJobLink && !isEnableForBigBasket, headers, 'jobLink', 'Job Link', 350, null);
    noOfCols += addHeader(
        !isEnableForBigBasket,
        headers,
        'createdDate',
        isEnableForAdaniCoal ? 'Job Created On' : 'Created On',
        350,
        null
    );
    noOfCols += addHeader(!isEnableForBigBasket && !isShreemanAccount, headers, 'createdBy', 'Created By', 350, null);
    noOfCols += addHeader(showJobBooking(), headers, 'jobBookingNumber', 'Job Booking Number', 0, null);
    noOfCols += addHeader(
        !isEnableForBigBasket && !isEnableForAdaniCoal && !isShreemanAccount,
        headers,
        'stationaryNumber',
        'Stationary Number',
        150,
        footerRow
    );

    noOfCols += addHeader(
        (groupBasedReportItemEnabled ? includeJobStartTime && get(groupBasedReportItems, 'startDate', false) : true) &&
            !isEnableForAdaniCoal &&
            !isMiaharAccount &&
            !isShreemanAccount,
        headers,
        'startDate',
        groupBasedReportItemEnabled ? get(groupBasedReportItems, 'startDate.label', false) : 'Start Date',
        150,
        footerRow
    );
    noOfCols += addHeader(
        (groupBasedReportItemEnabled ? includeJobStartTime && get(groupBasedReportItems, 'startDate', false) : true) &&
            isShreemanAccount,
        headers,
        'startDate',
        groupBasedReportItemEnabled ? get(groupBasedReportItems, 'startDate.label', false) : 'Trip Start Date & Time',
        150,
        footerRow
    );
    noOfCols += addHeader(
        (groupBasedReportItemEnabled ? includeJobStartTime && get(groupBasedReportItems, 'startDate', false) : true) &&
            isMiaharAccount,
        headers,
        'jobStartDate',
        groupBasedReportItemEnabled ? get(groupBasedReportItems, 'startDate.label', false) : 'Job Start Date',
        150,
        footerRow
    );
    noOfCols += addHeader(
        (groupBasedReportItemEnabled ? includeJobStartTime && get(groupBasedReportItems, 'startDate', false) : true) &&
            isMiaharAccount,
        headers,
        'jobStartTime',
        groupBasedReportItemEnabled ? get(groupBasedReportItems, 'startDate.label', false) : 'Job Start Time',
        150,
        footerRow
    );

    noOfCols += addHeader(
        groupBasedReportItemEnabled ? get(groupBasedReportItems, 'originLocation', false) : true,
        headers,
        'originLocation',
        isEnableForAdaniCoal ? 'Origin' : 'Origin Location',
        150,
        footerRow
    );
    noOfCols += addHeader(
        (groupBasedReportItemEnabled ? get(groupBasedReportItems, 'destination', false) : true) &&
            !isEnableForAdaniCoal &&
            !isShreemanAccount,
        headers,
        'destination',
        'Destination',
        150,
        footerRow
    );
    noOfCols += addHeader(
        showScheduledArrivalForAccount(),
        headers,
        'scheduledArrivalTimeOrigin',
        'Scheduled Arrival At Origin',
        150,
        footerRow
    );
    noOfCols += addHeader(
        (groupBasedReportItemEnabled
            ? includePlantEntryTime && get(groupBasedReportItems, 'arrivalTimeOrigin', false)
            : true) &&
            !isEnableForAdaniCoal &&
            !isShreemanAccount,
        headers,
        'arrivalTimeOrigin',
        groupBasedReportItemEnabled
            ? get(groupBasedReportItems, 'arrivalTimeOrigin.label', false)
            : 'Arrival Time At Origin',
        150,
        footerRow
    );
    noOfCols += addHeader(
        (groupBasedReportItemEnabled
            ? includePlantEntryTime && get(groupBasedReportItems, 'arrivalTimeOrigin', false)
            : true) && isShreemanAccount,
        headers,
        'arrivalTimeOrigin',
        groupBasedReportItemEnabled
            ? get(groupBasedReportItems, 'arrivalTimeOrigin.label', false)
            : 'Arrival Time at SRCP',
        150,
        footerRow
    );

    noOfCols += addHeader(
        (groupBasedReportItemEnabled
            ? includePlantExitTime && get(groupBasedReportItems, 'scheduledDepartureTimeOrigin', false)
            : true) &&
            !isEnableForAdaniCoal &&
            !isShreemanAccount,
        headers,
        'scheduledDepartureTimeOrigin',
        'Schedule Departure Time At Origin',
        150,
        footerRow
    );

    noOfCols += addHeader(
        groupBasedReportItemEnabled
            ? includePlantExitTime && get(groupBasedReportItems, 'departureTimeOrigin', false)
            : !isShreemanAccount,
        headers,
        'departureTimeOrigin',
        isEnableForAdaniCoal
            ? 'Trip Start Time at Origin'
            : groupBasedReportItemEnabled
            ? get(groupBasedReportItems, 'departureTimeOrigin.label', false)
            : 'Departure Time From Origin',
        150,
        footerRow
    );
    noOfCols += addHeader(
        groupBasedReportItemEnabled
            ? includePlantExitTime && get(groupBasedReportItems, 'departureTimeOrigin', false)
            : true,
        headers,
        'departureTimeOrigin',
        isEnableForAdaniCoal
            ? 'Trip Start Time at Origin'
            : groupBasedReportItemEnabled
            ? get(groupBasedReportItems, 'departureTimeOrigin.label', false)
            : 'Departure Time at SRCP',
        150,
        footerRow
    );
    noOfCols += addHeader(isEnableForAdaniCoal, headers, 'avgSpeed', 'Avg Speed(KM/h)', 150, footerRow);

    noOfCols += addHeader(
        !isEnableForBigBasket && !isEnableForAdaniCoal && !isShreemanAccount,
        headers,
        'unloadingDuration',
        'Unloading Duration',
        150,
        footerRow
    );
    noOfCols += addHeader(
        (groupBasedReportItemEnabled ? includePlantTAT && get(groupBasedReportItems, 'plantTAT', false) : true) &&
            !isEnableForBigBasket &&
            !isEnableForAdaniCoal &&
            !isShreemanAccount,
        headers,
        'plantTAT',
        'Plant TAT',
        150,
        footerRow
    );
    noOfCols += addHeader(
        (groupBasedReportItemEnabled ? includePlantTAT && get(groupBasedReportItems, 'plantTAT', false) : true) &&
            isShreemanAccount,
        headers,
        'plantTAT',
        'SRCP Plant TAT',
        150,
        footerRow
    );
    noOfCols += addHeader(
        (groupBasedReportItemEnabled ? get(groupBasedReportItems, 'delayOrigin', false) : true) &&
            !isEnableForBigBasket &&
            !isEnableForAdaniCoal &&
            !isShreemanAccount,
        headers,
        'delayOrigin',
        'Delay / Early From Origin',
        150,
        footerRow
    );

    const jobStatusKey = ++j;
    if (!isShreemanAccount) {
        noOfCols += addHeader(
            (groupBasedReportItemEnabled ? get(groupBasedReportItems, 'status', false) : true) && !isEnableForBigBasket,
            headers,
            jobStatusKey,
            isEnableForAdaniCoal ? 'Job Status' : 'Status',
            150,
            footerRow
        );
    } else {
        noOfCols += addHeader(
            groupBasedReportItemEnabled ? get(groupBasedReportItems, 'status', false) : true,
            headers,
            jobStatusKey,
            'Trip Status',
            150,
            footerRow
        );
    }

    noOfCols += addHeader(
        (groupBasedReportItemEnabled ? get(groupBasedReportItems, 'vehicleStatus', false) : true) &&
            !isEnableForBigBasket &&
            !isShreemanAccount,
        headers,
        'vehicleStatus',
        'Vehicle Status',
        150,
        footerRow
    );
    noOfCols += addHeader(isEnableForAdaniCoal, headers, 'jobEtaStatus', 'Job ETA Status', 150, footerRow);
    noOfCols += addHeader(
        isEnableForAdaniCoal,
        headers,
        'remainingKmToNextPoint',
        'Remaining Km To Next Point',
        150,
        footerRow
    );

    const totalDelayArrivalKey = ++j;
    noOfCols += addHeader(
        (groupBasedReportItemEnabled ? get(groupBasedReportItems, 'totalDelayArrival', false) : true) &&
            !isEnableForBigBasket &&
            !isShreemanAccount,
        headers,
        totalDelayArrivalKey,
        isEnableForAdaniCoal ? 'Delay' : 'Total Delay (arrival)',
        150,
        footerRow
    );

    noOfCols += addHeader(
        (groupBasedReportItemEnabled ? get(groupBasedReportItems, 'endDate', false) : true) &&
            !isEnableForBigBasket &&
            !isMiaharAccount &&
            !isShreemanAccount,
        headers,
        'endDate',
        isEnableForAdaniCoal ? 'Job End Time' : 'End Date',
        150,
        footerRow
    );
    noOfCols += addHeader(
        (groupBasedReportItemEnabled ? get(groupBasedReportItems, 'endDate', false) : true) && isShreemanAccount,
        headers,
        'endDate',
        'Trip End Date',
        150,
        footerRow
    );
    noOfCols += addHeader(
        (groupBasedReportItemEnabled ? get(groupBasedReportItems, 'endDate', false) : true) && isMiaharAccount,
        headers,
        'jobEndDate',
        'Job End Date',
        150,
        footerRow
    );
    noOfCols += addHeader(
        (groupBasedReportItemEnabled ? get(groupBasedReportItems, 'endDate', false) : true) && isMiaharAccount,
        headers,
        'jobEndTime',
        'Job End Time',
        150,
        footerRow
    );
    noOfCols += addHeader(
        (groupBasedReportItemEnabled ? get(groupBasedReportItems, 'endDate', false) : true) && isMiaharAccount,
        headers,
        'commodity',
        'Commodity',
        150,
        footerRow
    );
    noOfCols += addHeader(
        (groupBasedReportItemEnabled ? get(groupBasedReportItems, 'duration', false) : true) &&
            !isEnableForBigBasket &&
            !isShreemanAccount,
        headers,
        'duration',
        'Duration',
        150,
        footerRow
    );
    noOfCols += addHeader(
        (groupBasedReportItemEnabled ? get(groupBasedReportItems, 'duration', false) : true) && isShreemanAccount,
        headers,
        'duration',
        'Trip Duration',
        150,
        footerRow
    );
    noOfCols += addHeader(!isMiaharAccount, headers, 'stopTime', 'Stop Time', 150, footerRow);
    noOfCols += addHeader(!isShreemanAccount, headers, 'maxSpeed', 'Max Speed', 150, footerRow);

    const currentLocationKey = ++j;
    noOfCols += addHeader(
        includeCurrentLocation &&
            (groupBasedReportItemEnabled ? get(groupBasedReportItems, 'currentLocation', false) : true) &&
            !isEnableForBigBasket &&
            !isShreemanAccount,
        headers,
        currentLocationKey,
        'Current Location ',
        150,
        footerRow
    );

    const currentStatusKey = ++j;
    noOfCols += addHeader(
        includeCurrentStatus &&
            (groupBasedReportItemEnabled ? get(groupBasedReportItems, 'currentStatus', false) : true) &&
            !isEnableForBigBasket &&
            !isShreemanAccount,
        headers,
        currentStatusKey,
        'Current Status',
        150,
        footerRow
    );
    let totalKmId = 0;

    const jobDistanceKey = ++j;
    noOfCols += addHeader(
        includeJobDistance &&
            (groupBasedReportItemEnabled ? get(groupBasedReportItems, 'jobDistance', false) : true) &&
            !isEnableForBigBasket,
        headers,
        jobDistanceKey,
        'Job Distance (km)',
        150,
        footerRow
    );

    const scheduledDistanceKey = ++j;
    noOfCols += addHeader(
        includeJobDistance &&
            (groupBasedReportItemEnabled ? get(groupBasedReportItems, 'jobScheduledDistance', false) : true) &&
            !isEnableForBigBasket,
        headers,
        scheduledDistanceKey,
        'Scheduled Distance (km)',
        150,
        footerRow
    );

    noOfCols += addHeader(
        includeJobDistance && intercityCareersAccount,
        headers,
        'diffInJobDistance',
        'Differences In Job Distance',
        150,
        footerRow
    );

    totalKmId = j;
    noOfCols += addHeader(
        includeVehicleLoadState &&
            (groupBasedReportItemEnabled ? get(groupBasedReportItems, 'vehicleLoadState', false) : true) &&
            !isEnableForBigBasket &&
            !isEnableForAdaniCoal &&
            !isShreemanAccount,
        headers,
        'vehicleLoadState',
        'Vehicle Load State (Source)',
        150,
        footerRow
    );
    noOfCols += addHeader(
        includeJobDistance &&
            (groupBasedReportItemEnabled ? get(groupBasedReportItems, 'missingKM', false) : true) &&
            !isEnableForBigBasket &&
            !isEnableForAdaniCoal &&
            !isShreemanAccount,
        headers,
        ++j,
        'Missing KM',
        150,
        footerRow
    );
    noOfCols += addHeader(
        includeJobDistance &&
            (groupBasedReportItemEnabled ? get(groupBasedReportItems, 'percentageDowntime', false) : true) &&
            !isEnableForBigBasket &&
            !isEnableForAdaniCoal &&
            !isShreemanAccount,
        headers,
        ++j,
        '% Downtime',
        150,
        footerRow
    );
    const percentJobCompletedKey = ++j;
    noOfCols += addHeader(
        includePercentJobCompleted &&
            (groupBasedReportItemEnabled ? get(groupBasedReportItems, 'percentageJobCompleted', false) : true) &&
            !isEnableForBigBasket &&
            !isShreemanAccount,
        headers,
        percentJobCompletedKey,
        '% Job Completed',
        150,
        footerRow
    );
    noOfCols += addHeader(
        includePredictedDelay &&
            (groupBasedReportItemEnabled ? get(groupBasedReportItems, 'predictedDelay', false) : true) &&
            !isEnableForBigBasket &&
            !isShreemanAccount,
        headers,
        ++j,
        'Predicted Delay',
        150,
        footerRow
    );
    noOfCols += addHeader(
        includePredictedDelay && !isEnableForBigBasket && !isShreemanAccount,
        headers,
        'expectedTimeOfArrival',
        'Expected Time Of Arrival',
        150,
        footerRow
    );
    noOfCols += addHeader(
        includeJobNotes &&
            (groupBasedReportItemEnabled ? get(groupBasedReportItems, 'jobNotes', false) : true) &&
            !isEnableForBigBasket &&
            !isEnableForAdaniCoal &&
            !isShreemanAccount,
        headers,
        'jobNotes',
        'Job Notes',
        150,
        footerRow
    );
    if (includeJobExpenses && !isEnableForBigBasket && !isShreemanAccount) {
        noOfCols += addHeader(true, headers, 'driverExpenses', 'Driver Expenses', 0);
        noOfCols += addHeader(
            !isEnableForAdaniCoal,
            headers,
            'driverExpensesPerKMEmptyTrips',
            'Driver Expense Per KM For Empty Trips(₹)',
            0
        );
        noOfCols += addHeader(true, headers, 'fuelCost', 'Fuel Cost', 0);
        noOfCols += addHeader(true, headers, 'fuelQuantityInLtr', 'Fuel (l)', 0);

        if (showFuelDiffSummary) {
            noOfCols += addHeader(true, headers, 'totalFuelQuantity', 'Consumed Fuel (l)', 0);
            noOfCols += addHeader(true, headers, 'totalFuelExpense', 'Consumed Fuel Cost', 0);
            noOfCols += addHeader(true, headers, 'fuelCostDiff', 'Difference in Fuel Cost', 0);
        }

        noOfCols += addHeader(true, headers, 'rtoExpenses', 'RTO Expenses', 0);
        noOfCols += addHeader(true, headers, 'tollExpenses', 'Toll Expenses', 0);
        noOfCols += addHeader(true, headers, 'otherExpenses', 'Other Expenses', 0);
        noOfCols += addHeader(true, headers, 'moreExpenses', 'More Expenses', 0);
    }
    noOfCols += addHeader(
        !isEnableForBigBasket && !isEnableForAdaniCoal && !isShreemanAccount,
        headers,
        'scheduleTotalTat',
        'Scheduled Total TAT',
        150,
        footerRow
    );
    noOfCols += addHeader(
        !isEnableForBigBasket && !isShreemanAccount,
        headers,
        'actualTotalTat',
        isEnableForAdaniCoal ? 'Total Actual TAT for Trip' : 'Actual Total TAT',
        150,
        footerRow
    );
    noOfCols += addHeader(intercityCareersAccount, headers, 'differenceInTAT', 'Difference In TAT', 150, footerRow);

    noOfCols += addHeader(
        includeTransporter &&
            (groupBasedReportItemEnabled ? get(groupBasedReportItems, 'transporter', false) : true) &&
            !isEnableForBigBasket,
        headers,
        'transporter',
        'Transporter Name',
        150,
        footerRow
    );
    if (isCSV) {
        noOfCols += addHeader(
            includeQuantity &&
                (groupBasedReportItemEnabled ? get(groupBasedReportItems, 'scheduledCount', false) : true),
            headers,
            'scheduledCount',
            'Scheduled Quantity Source',
            0,
            null
        );
        noOfCols += addHeader(
            includeQuantity && (groupBasedReportItemEnabled ? get(groupBasedReportItems, 'actualCount', false) : true),
            headers,
            'actualCount',
            'Actual Quantity Source',
            0,
            null
        );
        noOfCols += addHeader(
            includeOpeningOdo &&
                (groupBasedReportItemEnabled ? get(groupBasedReportItems, 'openingOdo', false) : true) &&
                !isEnableForBigBasket &&
                !isEnableForAdaniCoal &&
                !isShreemanAccount,
            headers,
            'openingOdo',
            'Opening Odometer',
            0,
            null
        );
        noOfCols += addHeader(
            includeClosingOdo &&
                (groupBasedReportItemEnabled ? get(groupBasedReportItems, 'closingOdo', false) : true) &&
                !isEnableForBigBasket &&
                !isEnableForAdaniCoal &&
                !isShreemanAccount,
            headers,
            'closingOdo',
            'Closing Odometer',
            0,
            null
        );
        noOfCols += addHeader(
            (groupBasedReportItemEnabled ? get(groupBasedReportItems, 'dtdcKm', false) : true) &&
                !isEnableForBigBasket &&
                !isEnableForAdaniCoal &&
                !isShreemanAccount,
            headers,
            'dtdcKm',
            getColumnNameForHorizontalReportKM(loggedInUser),
            0,
            null
        );

        noOfCols += addHeader(
            includeFreightFare && !isEnableForBigBasket && !isEnableForAdaniCoal && !isShreemanAccount,
            headers,
            'freightFare',
            'Freight Fare',
            0,
            null
        );
        noOfCols += addHeader(!isShreemanAccount, headers, 'freightInvoiceNumber', 'Freight Invoice No.', 0, null);

        noOfCols += addHeader(!isShreemanAccount, headers, 'detentionInvoiceNumber', 'Detention Invoice No.', 0, null);

        noOfCols += addHeader(
            includeDeviationCount && !isEnableForBigBasket && !isEnableForAdaniCoal,
            headers,
            'deviationCount',
            'Deviation Count',
            0,
            null
        );

        noOfCols += addHeader(
            includeDeviationKm && !isEnableForBigBasket && !isEnableForAdaniCoal,
            headers,
            'deviationKm',
            'Deviation Km',
            0,
            null
        );

        noOfCols += addHeader(
            includeRfVerifiedTime && !isEnableForBigBasket && !isEnableForAdaniCoal,
            headers,
            'rfVerifiedTime',
            'Barcode/rfid Scanning Time',
            0,
            null
        );

        if (includeProfitAndLoss && !isEnableForBigBasket) {
            forEach(jobsTransactionExpensesUniqueCols, (colKey) => {
                if (colKey === 'Fuel') {
                    noOfCols += addHeader(true, headers, 'expenses-fuelQuantity-DR', 'Fuel Quantity(DR)', 0, null);
                    noOfCols += addHeader(true, headers, `expense-${colKey}-DR`, 'Fuel Amount(Rs.)(DR.)', 0, null);

                    noOfCols += addHeader(true, headers, 'expenses-fuelQuantity-CR', 'Fuel Quantity(CR)', 0, null);
                    noOfCols += addHeader(true, headers, `expense-${colKey}-CR`, 'Fuel Amount(Rs.)(CR.)', 0, null);
                } else {
                    noOfCols += addHeader(true, headers, `expense-${colKey}-DR`, `${colKey}(Rs.)(DR.)`, 0, null);
                    noOfCols += addHeader(true, headers, `expense-${colKey}-CR`, `${colKey}(Rs.)(CR.)`, 0, null);
                }
            });

            // more cols
            noOfCols += addHeader(true, headers, 'expenses-advance', 'Advance(Rs.)', 0, null);
            noOfCols += addHeader(true, headers, 'expenses-totalExpenses', 'Total Expenses(Rs.)', 0, null);
            noOfCols += addHeader(true, headers, 'expenses-balance', 'Balance(Rs.)', 0, null);
            noOfCols += addHeader(true, headers, 'expenses-profit', 'Profit(Rs.)', 0, null);
        }
    }

    if (includeBillNumbers && !isEnableForBigBasket && !isShreemanAccount) {
        forEach(BILL_NUMBER_TYPES, (type) => {
            if (type === BILL_NUMBER_TYPES.EWAY) {
                noOfCols += addHeader(
                    includeBillNumbers &&
                        (groupBasedReportItemEnabled ? get(groupBasedReportItems, 'billNumbers', false) : true),
                    headers,
                    type,
                    `${type}(expiry)`,
                    0,
                    null
                );
            } else {
                noOfCols += addHeader(
                    includeBillNumbers &&
                        (groupBasedReportItemEnabled ? get(groupBasedReportItems, 'billNumbers', false) : true),
                    headers,
                    type,
                    type,
                    0,
                    null
                );
            }
        });
    }

    if (includeBillNumbers && isShreemanAccount) {
        forEach(BILL_NUMBER_TYPES, (type) => {
            if (type !== BILL_NUMBER_TYPES.EWAY && type !== BILL_NUMBER_TYPES.LR) {
                noOfCols += addHeader(
                    includeBillNumbers &&
                        (groupBasedReportItemEnabled ? get(groupBasedReportItems, 'billNumbers', false) : true),
                    headers,
                    type,
                    type,
                    0,
                    null
                );
            }
        });
    }

    //EPOD DATA
    if (includeEPODDetails) {
        noOfCols += addHeader(true, headers, 'epodStatus', 'EPOD Status', 150, footerRow);
        noOfCols += addHeader(true, headers, 'epodComments', 'EPOD Comment', 150, footerRow);
        noOfCols += addHeader(true, headers, 'epodLatitude', 'EPOD Latitude', 150, footerRow);
        noOfCols += addHeader(true, headers, 'epodLongitude', 'EPOD Longitude', 150, footerRow);
        noOfCols += addHeader(true, headers, 'epodUploadTime', 'EPOD Time', 150, footerRow);
        noOfCols += addHeader(true, headers, 'epodUploadedBy', 'EPOD Uploaded by', 150, footerRow);
        noOfCols += addHeader(true, headers, 'epodReason', 'EPOD Reason', 150, footerRow);
    }

    noOfCols += addHeader(isEnableForAdaniCoal, headers, 'plannedUnloading', 'Planned Unloading TAT', 150, footerRow);
    noOfCols += addHeader(
        isEnableForAdaniCoal,
        headers,
        'totalTatOfTrip',
        'Total Planned TAT for Trip',
        150,
        footerRow
    );

    const distanceAtoBColId = [];
    j = 10;
    const pointAddress = [];
    const jobAnalyticsPoints = get(jobs, '[0].jobAnalytics', []);
    forEach(jobAnalyticsPoints, (item) => {
        const addressPoint = get(item, 'routePoint.address', '');
        const temp = addressPoint.split(',');
        pointAddress.push(temp[0]);
    });

    for (let i = 1; i < maxJobPointLength; i++) {
        let atDestinationTouchPointAddress = ``;
        let idealArrivalAtDestination = '';
        let haltLoadingDurationAtDestination = '';
        let tatForDestination = '';
        let actualTatForDestination = '';
        let idealDepartureTimeFromDestination = '';
        if (i === 1) {
            atDestinationTouchPointAddress = 'Loading Location';
            idealArrivalAtDestination = 'Actual Arrival Time at Loading Location';
            haltLoadingDurationAtDestination = 'Actual Loading TAT';
            tatForDestination = 'Planned Empty Movement TAT (Hrs)';
            actualTatForDestination = 'Actual Empty Movement TAT (Hrs)';
            idealDepartureTimeFromDestination = 'Actual Departure Time from Loading location';
        }
        if (i === 2) {
            atDestinationTouchPointAddress = 'Unloading Location';
            idealArrivalAtDestination = 'Actual Arrival Time At Unloading Location';
            haltLoadingDurationAtDestination = 'Actual Unloading TAT';
            tatForDestination = 'Planned Loaded Movement TAT (Hrs)';
            actualTatForDestination = 'Actual Loaded Movement TAT (Hrs)';
            idealDepartureTimeFromDestination = 'Trip End time at Unloading Location';
        }
        if (isMiaharAccount) {
            noOfCols += addHeader(
                groupBasedReportItemEnabled ? get(groupBasedReportItems, 'destinationTouchPointAddress', false) : true,
                headers,
                ++j,
                isEnableForAdaniCoal
                    ? atDestinationTouchPointAddress
                    : `Destination ${getNumberWithOrdinal(i)} (touch point ${pointAddress[i]}) Address`,
                150,
                footerRow
            );
        } else if (isShreemanAccount) {
            noOfCols += addHeader(
                groupBasedReportItemEnabled ? get(groupBasedReportItems, 'destinationTouchPointAddress', false) : true,
                headers,
                ++j,
                isEnableForAdaniCoal
                    ? atDestinationTouchPointAddress
                    : `${getNumberWithOrdinal(i)} Destination (${pointAddress[i]})`,
                150,
                footerRow
            );
        } else {
            noOfCols += addHeader(
                groupBasedReportItemEnabled ? get(groupBasedReportItems, 'destinationTouchPointAddress', false) : true,
                headers,
                ++j,
                isEnableForAdaniCoal
                    ? atDestinationTouchPointAddress
                    : `Destination ${getNumberWithOrdinal(i)} touch point Address`,
                150,
                footerRow
            );
        }

        if (isShriMarutiCourierJobCustomizedReport) {
            let destinationLabel = `Touch Point ${getNumberWithOrdinal(i)}`;
            if (i === maxJobPointLength - 1) {
                destinationLabel = 'Destination';
            }
            pickedHeaders.push({
                prompt: destinationLabel,
                name: j,
                order: 9,
            });
        }

        if (isMiaharAccount) {
            noOfCols += addHeader(
                groupBasedReportItemEnabled
                    ? includeDestinationEntryTime && get(groupBasedReportItems, 'arrivalTimeDestination', false)
                    : !isMiaharAccount,
                headers,
                ++j,
                isEnableForAdaniCoal
                    ? actualArrivalAtDestination
                    : groupBasedReportItemEnabled
                    ? `${get(groupBasedReportItems, 'arrivalTimeDestination.label', false)} ${pointAddress[i]}`
                    : `Arrival Time At ${getNumberWithOrdinal(i)} Destination ${pointAddress[i]}`,
                150,
                footerRow
            );
        } else if (isShreemanAccount) {
            noOfCols += addHeader(
                groupBasedReportItemEnabled
                    ? includeDestinationEntryTime && get(groupBasedReportItems, 'arrivalTimeDestination', false)
                    : !isMiaharAccount,
                headers,
                ++j,
                isEnableForAdaniCoal
                    ? actualArrivalAtDestination
                    : groupBasedReportItemEnabled
                    ? `${get(groupBasedReportItems, 'arrivalTimeDestination.label', false)} ${pointAddress[i]}`
                    : `Arrival Time At ${pointAddress[i]}`,
                150,
                footerRow
            );
        } else {
            noOfCols += addHeader(
                groupBasedReportItemEnabled
                    ? includeDestinationEntryTime && get(groupBasedReportItems, 'arrivalTimeDestination', false)
                    : !isMiaharAccount,
                headers,
                ++j,
                isEnableForAdaniCoal
                    ? actualArrivalAtDestination
                    : groupBasedReportItemEnabled
                    ? `${get(groupBasedReportItems, 'arrivalTimeDestination.label', false)} ${pointAddress[i]}`
                    : `Arrival Time At ${getNumberWithOrdinal(i)} Destination`,
                150,
                footerRow
            );
        }

        if (isShriMarutiCourierJobCustomizedReport) {
            let destinationLabel = `Touch Point ${getNumberWithOrdinal(i)} In`;
            if (i === maxJobPointLength - 1) {
                destinationLabel = 'Destination In';
            }
            pickedHeaders.push({
                prompt: destinationLabel,
                name: j,
                order: 9,
            });
        }
        if (isMiaharAccount) {
            noOfCols += addHeader(
                (groupBasedReportItemEnabled
                    ? get(groupBasedReportItems, 'idealArrivalTimeDestination', false)
                    : true) &&
                    !isEnableForBigBasket &&
                    !isShreemanAccount,
                headers,
                ++j,
                isEnableForAdaniCoal
                    ? idealArrivalAtDestination
                    : `Ideal Arrival Time At ${getNumberWithOrdinal(i)} Destination ${pointAddress[i]}`,
                150,
                footerRow
            );
        } else {
            noOfCols += addHeader(
                (groupBasedReportItemEnabled
                    ? get(groupBasedReportItems, 'idealArrivalTimeDestination', false)
                    : true) &&
                    !isEnableForBigBasket &&
                    !isShreemanAccount,
                headers,
                ++j,
                isEnableForAdaniCoal
                    ? idealArrivalAtDestination
                    : `Ideal Arrival Time At ${getNumberWithOrdinal(i)} Destination`,
                150,
                footerRow
            );
        }

        if (isMiaharAccount) {
            noOfCols += addHeader(
                (groupBasedReportItemEnabled
                    ? includeDestinationExitTime && get(groupBasedReportItems, 'departureTimeDestination', false)
                    : true) && !isEnableForAdaniCoal,
                headers,
                ++j,
                groupBasedReportItemEnabled
                    ? `${get(groupBasedReportItems, 'departureTimeDestination.label', false)} ${pointAddress[i]}`
                    : `Departure Time From ${getNumberWithOrdinal(i)} Destination ${pointAddress[i]}`,
                150,
                footerRow
            );
        } else if (isShreemanAccount) {
            noOfCols += addHeader(
                (groupBasedReportItemEnabled
                    ? includeDestinationExitTime && get(groupBasedReportItems, 'departureTimeDestination', false)
                    : true) && !isEnableForAdaniCoal,
                headers,
                ++j,
                groupBasedReportItemEnabled
                    ? `${get(groupBasedReportItems, 'departureTimeDestination.label', false)} ${pointAddress[i]}`
                    : `Departure Time From ${pointAddress[i]}`,
                150,
                footerRow
            );
        } else {
            noOfCols += addHeader(
                (groupBasedReportItemEnabled
                    ? includeDestinationExitTime && get(groupBasedReportItems, 'departureTimeDestination', false)
                    : true) && !isEnableForAdaniCoal,
                headers,
                ++j,
                groupBasedReportItemEnabled
                    ? `${get(groupBasedReportItems, 'departureTimeDestination.label', false)} ${pointAddress[i]}`
                    : `Departure Time From ${getNumberWithOrdinal(i)} Destination`,
                150,
                footerRow
            );
        }

        if (isShriMarutiCourierJobCustomizedReport) {
            let destinationLabel = `Touch Point ${getNumberWithOrdinal(i)} Out`;
            if (i === maxJobPointLength - 1) {
                destinationLabel = 'Destination Out';
            }
            pickedHeaders.push({
                prompt: destinationLabel,
                name: j,
                order: 9,
            });
        }
        if (isMiaharAccount) {
            noOfCols += addHeader(
                (groupBasedReportItemEnabled
                    ? get(groupBasedReportItems, 'idealDepartureTimeDestination', false)
                    : true) &&
                    !isEnableForBigBasket &&
                    !isShreemanAccount,
                headers,
                ++j,
                isEnableForAdaniCoal
                    ? idealDepartureTimeFromDestination
                    : `Ideal Departure Time From ${getNumberWithOrdinal(i)} Destination ${pointAddress[i]}`,
                150,
                footerRow
            );
        } else {
            noOfCols += addHeader(
                (groupBasedReportItemEnabled
                    ? get(groupBasedReportItems, 'idealDepartureTimeDestination', false)
                    : true) &&
                    !isEnableForBigBasket &&
                    !isShreemanAccount,
                headers,
                ++j,
                isEnableForAdaniCoal
                    ? idealDepartureTimeFromDestination
                    : `Ideal Departure Time From ${getNumberWithOrdinal(i)} Destination`,
                150,
                footerRow
            );
        }

        if (isMiaharAccount) {
            noOfCols += addHeader(
                (groupBasedReportItemEnabled
                    ? get(groupBasedReportItems, 'haltUnloadingDurationDestination', false)
                    : true) && !isEnableForBigBasket,
                headers,
                ++j,
                isEnableForAdaniCoal
                    ? haltLoadingDurationAtDestination
                    : `Halt Unloading Duration at ${getNumberWithOrdinal(i)} Destination ${pointAddress[i]}`,
                150,
                footerRow
            );
        } else if (isShreemanAccount) {
            noOfCols += addHeader(
                (groupBasedReportItemEnabled
                    ? get(groupBasedReportItems, 'haltUnloadingDurationDestination', false)
                    : true) && !isEnableForBigBasket,
                headers,
                ++j,
                isEnableForAdaniCoal ? haltLoadingDurationAtDestination : `TAT - ${pointAddress[i]}`,
                150,
                footerRow
            );
        } else {
            noOfCols += addHeader(
                (groupBasedReportItemEnabled
                    ? get(groupBasedReportItems, 'haltUnloadingDurationDestination', false)
                    : true) && !isEnableForBigBasket,
                headers,
                ++j,
                isEnableForAdaniCoal
                    ? haltLoadingDurationAtDestination
                    : `Halt Unloading Duration at ${getNumberWithOrdinal(i)} Destination`,
                150,
                footerRow
            );
        }

        if (isMiaharAccount) {
            noOfCols += addHeader(
                includeTat &&
                    (groupBasedReportItemEnabled ? get(groupBasedReportItems, 'tatDestination', false) : true) &&
                    !isEnableForBigBasket &&
                    !isShreemanAccount,
                headers,
                ++j,
                isEnableForAdaniCoal
                    ? tatForDestination
                    : groupBasedReportItemEnabled
                    ? `${get(groupBasedReportItems, 'tatDestination.label', false)} ${pointAddress[i]}`
                    : `TAT For ${getNumberWithOrdinal(i)} Destination ${pointAddress[i]}`,
                150,
                footerRow
            );
        } else {
            noOfCols += addHeader(
                includeTat &&
                    (groupBasedReportItemEnabled ? get(groupBasedReportItems, 'tatDestination', false) : true) &&
                    !isEnableForBigBasket &&
                    !isShreemanAccount,
                headers,
                ++j,
                isEnableForAdaniCoal
                    ? tatForDestination
                    : groupBasedReportItemEnabled
                    ? `${get(groupBasedReportItems, 'tatDestination.label', false)} ${pointAddress[i]}`
                    : `TAT For ${getNumberWithOrdinal(i)} Destination`,
                150,
                footerRow
            );
        }

        if (isMiaharAccount) {
            noOfCols += addHeader(
                includeTat &&
                    (groupBasedReportItemEnabled ? get(groupBasedReportItems, 'actualTatDestination', false) : true) &&
                    !isEnableForBigBasket,
                headers,
                ++j,
                isEnableForAdaniCoal
                    ? actualTatForDestination
                    : `Actual TAT For ${getNumberWithOrdinal(i)} Destination ${pointAddress[i]}`,
                150,
                footerRow
            );
        } else if (isShreemanAccount) {
            noOfCols += addHeader(
                includeTat &&
                    (groupBasedReportItemEnabled ? get(groupBasedReportItems, 'actualTatDestination', false) : true) &&
                    !isEnableForBigBasket,
                headers,
                ++j,
                isEnableForAdaniCoal
                    ? actualTatForDestination
                    : `Transit Time from ${pointAddress[i - 1]} to ${pointAddress[i]}`,
                150,
                footerRow
            );
        } else {
            noOfCols += addHeader(
                includeTat &&
                    (groupBasedReportItemEnabled ? get(groupBasedReportItems, 'actualTatDestination', false) : true) &&
                    !isEnableForBigBasket,
                headers,
                ++j,
                isEnableForAdaniCoal
                    ? actualTatForDestination
                    : `Actual TAT For ${getNumberWithOrdinal(i)} Destination`,
                150,
                footerRow
            );
        }

        noOfCols += addHeader(
            includeQuantity &&
                (groupBasedReportItemEnabled ? get(groupBasedReportItems, 'scheduledQuantity', false) : true),
            headers,
            ++j,
            `Scheduled Quantity ${getNumberWithOrdinal(i)}`,
            150,
            footerRow
        );
        noOfCols += addHeader(
            includeQuantity &&
                (groupBasedReportItemEnabled ? get(groupBasedReportItems, 'actualQuantity', false) : true),
            headers,
            ++j,
            `Actual Quantity ${getNumberWithOrdinal(i)}`,
            150,
            footerRow
        );

        noOfCols += addHeader(isEnableForBigBasket, headers, ++j, `Variance ${i}`, 150, footerRow);
        if (isMiaharAccount) {
            noOfCols += addHeader(
                (groupBasedReportItemEnabled ? get(groupBasedReportItems, 'delayDestination', false) : true) &&
                    !isEnableForBigBasket &&
                    !isEnableForAdaniCoal &&
                    !isShreemanAccount,
                headers,
                ++j,
                `Delay For ${getNumberWithOrdinal(i)} Destination ${pointAddress[i]}`,
                150,
                footerRow
            );
        } else {
            noOfCols += addHeader(
                (groupBasedReportItemEnabled ? get(groupBasedReportItems, 'delayDestination', false) : true) &&
                    !isEnableForBigBasket &&
                    !isEnableForAdaniCoal &&
                    !isShreemanAccount,
                headers,
                ++j,
                `Delay For ${getNumberWithOrdinal(i)} Destination`,
                150,
                footerRow
            );
        }
        if (isMiaharAccount) {
            noOfCols += addHeader(
                (groupBasedReportItemEnabled ? get(groupBasedReportItems, 'statusDestination', false) : true) &&
                    !isEnableForBigBasket &&
                    !isEnableForAdaniCoal &&
                    !isShreemanAccount,
                headers,
                ++j,
                `Status For ${getNumberWithOrdinal(i)} Destination ${pointAddress[i]}`,
                150,
                footerRow
            );
        } else {
            noOfCols += addHeader(
                (groupBasedReportItemEnabled ? get(groupBasedReportItems, 'statusDestination', false) : true) &&
                    !isEnableForBigBasket &&
                    !isEnableForAdaniCoal &&
                    !isShreemanAccount,
                headers,
                ++j,
                `Status For ${getNumberWithOrdinal(i)} Destination`,
                150,
                footerRow
            );
        }

        noOfCols += addHeader(includeActualDistance, headers, ++j, getLabelForActualDistance(i), 150, footerRow);
        noOfCols += addHeader(
            isMiaharAccount,
            headers,
            `scheduledDistance-${i}`,
            `Scheduled Distance ${getNumberWithOrdinal(i)} at ${pointAddress[i]}`,
            150,
            footerRow
        );
        noOfCols += addHeader(
            isMiaharAccount,
            headers,
            `actualDistance-${i}`,
            `Actual Distance ${getNumberWithOrdinal(i)} at ${pointAddress[i]}`,
            150,
            footerRow
        );
        distanceAtoBColId.push(j);
    }
    noOfCols += addHeader(
        (groupBasedReportItemEnabled
            ? includeReturnTripTAT && get(groupBasedReportItems, 'returnTripTAT', false)
            : true) &&
            !isEnableForBigBasket &&
            !isEnableForAdaniCoal &&
            !isShreemanAccount,
        headers,
        'returnTripTAT',
        `Return Trip TAT`,
        150,
        footerRow
    );
    noOfCols += addHeader(isAryanTransReport, headers, 'tripTAT', `Trip TAT`, 150, footerRow);

    noOfCols += addHeader(
        (groupBasedReportItemEnabled
            ? includeRoundTripTAT && get(groupBasedReportItems, 'roundTripTAT', false)
            : true) &&
            !isEnableForBigBasket &&
            !isEnableForAdaniCoal &&
            !isShreemanAccount,
        headers,
        'roundTripTAT',
        `Round Trip TAT`,
        150,
        footerRow
    );
    noOfCols += addHeader(
        (groupBasedReportItemEnabled
            ? includeRoundTripTAT && get(groupBasedReportItems, 'roundTripTAT', false)
            : true) && isShreemanAccount,
        headers,
        'roundTripTAT',
        `Trip Total TAT`,
        150,
        footerRow
    );

    let customHeaderStartIndex = -1;
    let customHeaderEndIndex = -1;
    if (includeCustomField && allCustomFieldsPresent && !isEnableForBigBasket) {
        customHeaderStartIndex = headers.length;
        map(allCustomFieldsPresent, (cf) => {
            noOfCols += addHeader(
                includeCustomField &&
                    (groupBasedReportItemEnabled ? get(groupBasedReportItems, 'customField', false) : true),
                headers,
                `${cf.id}-${cf.keyName}`,
                `${cf.keyName}`,
                0,
                null
            );
        });
        customHeaderEndIndex = headers.length;
    }
    if (includeJobAlarms && !isShreemanAccount) {
        noOfCols += addHeader(includeJobAlarms, headers, 'hardAcceleration', 'Hard acceleration', 150, null);
        noOfCols += addHeader(includeJobAlarms, headers, 'hardDeceleration', 'Hard deceleration', 150, null);
        noOfCols += addHeader(
            includeJobAlarms && !isEnableForAdaniCoal,
            headers,
            'sharpTurns',
            'Sharp Turns',
            150,
            null
        );
        noOfCols += addHeader(includeJobAlarms, headers, 'overspeeding', 'Over Speeding', 150, null);
        noOfCols += addHeader(includeJobAlarms, headers, 'fatigueDrivingCount', 'Fatigue Driving', 150, null);
        noOfCols += addHeader(includeJobAlarms, headers, 'nightDriving', 'Night Driving', 150, null);
        noOfCols += addHeader(isMiaharAccount, headers, 'haltTimeUp', 'Halt Time Up', 150, null);
        noOfCols += addHeader(isMiaharAccount, headers, 'haltTimeDown', 'Halt Time Down', 150, null);
        noOfCols += addHeader(isMiaharAccount, headers, 'totalHaltTime', 'Total Halt Time', 150, null);
    }

    if (isAryanTransReport) {
        const getDistanceAtoBCols = filter(headers, (h) => {
            return includes(distanceAtoBColId, h.name);
        });
        let aryaTransColMappingModified = [...aryaTransColMapping, ...getDistanceAtoBCols];
        aryaTransColMappingModified.splice(14, 0, { prompt: 'Total KM', order: 15, name: totalKmId });
        headers = generateCustomOrderingForHeader(headers, aryaTransColMappingModified, false);
    }
    if (isShriMarutiCourierTATTimeReport) {
        headers = shreeMarutiCourierTATTimeReportMapping;
    }

    if (isShriMarutiCourierJobCustomizedReport) {
        headers = orderBy(pickedHeaders, 'order');
    }

    const getCustomColMappingHeaders = getColHorizontalJobReportMappingForAccount();
    const getCustomFieldHeaderArr = () => {
        if (customHeaderStartIndex == -1 || customHeaderEndIndex === -1) {
            return [];
        }
        return map(headers.slice(customHeaderStartIndex, customHeaderEndIndex), (h) => h.name);
    };
    if (getCustomColMappingHeaders) {
        let extraHeadersToInclude = [];
        if (intercityCareersAccount) {
            extraHeadersToInclude = [
                'delayOrigin',
                totalDelayArrivalKey,
                jobDistanceKey,
                scheduledDistanceKey,
                'diffInJobDistance',
                ...getCustomFieldHeaderArr(),
                'jobName',
                'vehicleName',
                'vehicleNumber',
                'driverName',
                'driverNumber',
                'haltTime',
                'jobLink',
                'createdBy',
                'createdDate',
                'originLocation',
                'destination',
                'status',
                'maxSpeed',
                'routeName',
                'startDate',
                'endDate',
                'scheduleTotalTat',
                'actualTotalTat',
                'differenceInTAT',
                'scheduledDepartureTimeOrigin',
                'departureTimeOrigin',
                'unloadingDuration',
                'stopTime',
                currentLocationKey,
                jobStatusKey,
                currentStatusKey,
                percentJobCompletedKey,
                'jobNotes',
                'driverExpenses',
                'otherExpenses',
                'tollExpenses',
                'rtoExpenses',
                'moreExpenses',
                'dtdcKm',
            ];
        }
        headers = generateCustomOrderingForHeader(
            headers,
            getCustomColMappingHeaders,
            false,
            extraHeadersToInclude,
            intercityCareersAccount
        );
    }

    const columnsToBeRemoved = getRemoveColumnMappingForAccount(loggedInUser);
    if (columnsToBeRemoved && columnsToBeRemoved.length > 0) {
        headers = filter(headers, (header) => !includes(columnsToBeRemoved, header.name));
    }

    const data = [];
    for (let i = 0; i < length; i++) {
        const job = jobs[i];
        const {
            jobAnalytics,
            jobAlarmCounts,
            name,
            driver,
            currentFuel,
            currentKm,
            predictedDelay,
            route,
            startDate,
            status,
            vehicle,
            totalDuration = 0,
            openingOdo = '',
            closingOdo = '',
            missingKm,
            percentDownTime,
            jobBudget,
            endDate,
            id,
            customer,
            stationaryNumber,
            createdDate,
            createdBy,
            jobBookingNumber,
            totalTransitTime = 0,
            runningDuration,
            avgSpeed,
            currentStatus,
            remainingKmToNextPoint,
            deviationCount = '',
            deviationKm = '',
            maxSpeed,
            rfVerifiedTime,
            freightInvoiceNumber,
            detentionInvoiceNumber,
        } = job;
        const notes = get(jobAnalytics, '[0].notes', '');
        const jobAnalyticsLength = jobAnalytics.length;
        j = 0;
        const dataRow = {};
        const routePoints = get(route, 'routePoints', '[]');
        const lastRoutePointIndex = routePoints.length - 1;
        addDataRow(
            includePredictedDelay,
            dataRow,
            'expectedTimeOfArrival',
            predictedDelay != 0 ? getTimeDiff(Math.abs(predictedDelay), false, true) : ''
        );
        addDataRow(true, dataRow, 'jobName', name);
        addDataRow(true, dataRow, 'consignor', customer ? customer.name : '');
        addDataRow(true, dataRow, 'vehicleName', get(vehicle, 'name', '-'));
        addDataRow(true, dataRow, 'jobEtaStatus', currentStatus);
        addDataRow(true, dataRow, 'remainingKmToNextPoint', remainingKmToNextPoint);
        addDataRow(true, dataRow, 'vehicleNumber', get(vehicle, 'licensePlate', '-'));
        addDataRow(includeDeviceSerialNo, dataRow, 'deviceSerialNo', get(job, 'deviceSerialNumber', ''));
        addDataRow(true, dataRow, 'vehicleModel', get(vehicle, 'vehicleModel.name', ''));
        addDataRow(true, dataRow, 'driverName', get(driver, 'name', ''));
        addDataRow(true, dataRow, 'driverNumber', get(driver, 'phoneNumber', ''));
        addDataRow(true, dataRow, 'routeId', get(route, 'name', '-'));
        addDataRow(true, dataRow, 'routeName', get(route, 'name', '-'));
        addDataRow(true, dataRow, 'stationaryNumber', stationaryNumber ? stationaryNumber : '');
        addDataRow(isEnableForAdaniCoal, dataRow, 'avgSpeed', avgSpeed ? avgSpeed : '');
        addDataRow(includeJobDistance, dataRow, 'jobDistance', `${getJobDistanceRoundedValue(currentKm)}`);

        let attachedTags = get(job, 'vehicle.tagIdsList', []);
        let tagNames = getTagNamesList(tags, attachedTags).join(',');
        addDataRow(includeTags, dataRow, 'vehicleTags', tagNames);

        const groupName = getGroupName(get(vehicle, 'group.id'), groupList);
        addDataRow(includeGroups, dataRow, 'groupNames', `${groupName ? groupName : '-'}`);

        addDataRow(includeJobLink, dataRow, 'jobLink', `https://app.fleetx.io/dashboard/routes/jobs/${id}`);

        addDataRow(true, dataRow, 'createdDate', getReportTime(createdDate));

        addDataRow(includeDeviationCount, dataRow, 'deviationCount', deviationCount);
        addDataRow(includeDeviationKm, dataRow, 'deviationKm', deviationKm);

        addDataRow(
            includeRfVerifiedTime,
            dataRow,
            'rfVerifiedTime',
            rfVerifiedTime ? getReportTime(rfVerifiedTime) : ''
        );

        const createdByUser = get(userListMiniAsMap, createdBy);
        addDataRow(
            true,
            dataRow,
            'createdBy',
            `${get(createdByUser, '[1]') ? get(createdByUser, '[1]') : ''} ${
                get(createdByUser, '[2]') ? get(createdByUser, '[2]') : ''
            }`
        );

        addDataRow(true, dataRow, 'startDate', getReportTime(getMomentTime(startDate).valueOf()));
        addDataRow(isMiaharAccount, dataRow, 'jobStartDate', getMomentTime(startDate).format(DATE_FORMAT));
        addDataRow(isMiaharAccount, dataRow, 'jobStartTime', getMomentTime(startDate).format(DATE_FORMAT_ONLY_TIME));
        const totalIdleTime = totalDuration - totalTransitTime;
        addDataRow(true, dataRow, 'haltTime', getTimeDiffHours(totalIdleTime, false, true));

        let startAddress = get(jobAnalytics[0].routePoint, 'address', '');
        const startAddressAddressBookId = get(jobAnalytics[0], 'routePoint.addressBookId', '');
        if (startAddressAddressBookId) {
            startAddress = get(addressBookAsMap, `${startAddressAddressBookId}.name`, startAddress);
        }
        addDataRow(true, dataRow, 'originLocation', startAddress);

        let endAddress = get(jobAnalytics[jobAnalyticsLength - 1].routePoint, 'address', '');
        const endAddressAddressBookId = get(jobAnalytics[jobAnalyticsLength - 1], 'routePoint.addressBookId', '');
        if (endAddressAddressBookId) {
            endAddress = get(addressBookAsMap, `${endAddressAddressBookId}.name`, endAddress);
        }
        addDataRow(true, dataRow, 'destination', endAddress);

        if (jobAnalytics[0].vehicleState && jobAnalytics[0].vehicleState === 'UNLOADED') {
            addDataRow(true, dataRow, 'unloadingDuration', '');
        } else {
            addDataRow(
                true,
                dataRow,
                'unloadingDuration',
                jobAnalytics[j].actualDeparture && jobAnalytics[j].actualArrival
                    ? getTimeDiff(
                          Math.abs(jobAnalytics[j].actualDeparture - jobAnalytics[j].actualArrival),
                          false,
                          true
                      )
                    : ''
            );
        }
        addDataRow(
            showScheduledArrivalForAccount(),
            dataRow,
            'scheduledArrivalTimeOrigin',
            jobAnalytics[0].scheduledArrival ? getReportTime(jobAnalytics[0].scheduledArrival) : ''
        );

        addDataRow(
            true,
            dataRow,
            'arrivalTimeOrigin',
            jobAnalytics[0].actualArrival ? getReportTime(jobAnalytics[0].actualArrival) : ''
        );
        addDataRow(
            true,
            dataRow,
            'departureTimeOrigin',
            jobAnalytics[0].actualDeparture ? getReportTime(jobAnalytics[0].actualDeparture) : ''
        );

        addDataRow(
            true,
            dataRow,
            'scheduledDepartureTimeOrigin',
            jobAnalytics[0].scheduledDeparture ? getReportTime(jobAnalytics[0].scheduledDeparture) : ''
        );
        addDataRow(
            !isShreemanAccount,
            dataRow,
            'plantTAT',
            jobAnalytics[0].actualArrival && jobAnalytics[0].actualDeparture
                ? getTimeDiffHours(
                      Math.abs(jobAnalytics[0].actualDeparture - jobAnalytics[0].actualArrival),
                      false,
                      true
                  )
                : ''
        );
        addDataRow(
            isShreemanAccount,
            dataRow,
            'plantTAT',
            jobAnalytics[0].actualArrival && jobAnalytics[0].actualDeparture
                ? getTimeDiffHours(
                      Math.abs(jobAnalytics[0].actualDeparture - jobAnalytics[0].actualArrival),
                      false,
                      true,
                      true
                  )
                : ''
        );
        addDataRow(includeTransporter, dataRow, 'transporter', get(job, 'transporter.name', ''));
        addDataRow(showJobBooking(), dataRow, 'jobBookingNumber', jobBookingNumber);

        const delayValOrigin =
            jobAnalytics[0].actualDeparture && jobAnalytics[0].scheduledDeparture
                ? jobAnalytics[0].actualDeparture - jobAnalytics[0].scheduledDeparture
                : 0;

        addDataRow(
            true,
            dataRow,
            'delayOrigin',
            delayValOrigin
                ? `${getTimeDiff(Math.abs(delayValOrigin), false, true)} ${delayValOrigin > 0 ? 'late' : 'early'}`
                : ''
        );

        if (includeJobExpenses) {
            let moreExpenses = '';
            const customJobBudgets = get(jobBudget, 'customJobBudgets', []);
            const moreExpensesList = map(
                customJobBudgets,
                (expense, index) =>
                    (moreExpenses += `${expense.expenseName}: ${expense.amount}${
                        index < customJobBudgets.length - 1 ? ', ' : ''
                    }`)
            );
            addDataRow(includeJobExpenses, dataRow, 'driverExpenses', get(jobBudget, 'estimatedDriverExpense', ''));
            addDataRow(
                includeJobExpenses,
                dataRow,
                'driverExpensesPerKMEmptyTrips',
                get(jobBudget, 'estimatedDriverExpenseEmptyPerKmRate', '')
            );
            addDataRow(includeJobExpenses, dataRow, 'fuelCost', get(jobBudget, 'estimatedFuel', ''));
            addDataRow(includeJobExpenses, dataRow, 'fuelQuantityInLtr', get(jobBudget, 'estimatedFuelInLtr', ''));
            addDataRow(includeJobExpenses, dataRow, 'otherExpenses', get(jobBudget, 'estimatedOtherMiscExpense', ''));
            addDataRow(includeJobExpenses, dataRow, 'tollExpenses', get(jobBudget, 'estimatedTollExpense', ''));
            addDataRow(includeJobExpenses, dataRow, 'rtoExpenses', get(jobBudget, 'estimatedTotalTaxRTO', ''));
            addDataRow(includeJobExpenses, dataRow, 'moreExpenses', moreExpenses);

            if (showFuelDiffSummary) {
                const transactionSummaryForJob = jobTransactionSummary[id];
                let totalFuelQuantity = round(get(transactionSummaryForJob, 'totalFuelQuantity', 0), 2);
                let totalFuelExpense = round(get(transactionSummaryForJob, 'totalFuelExpense', 0), 2);
                let fuelCostDiff = round(totalFuelExpense - get(jobBudget, 'estimatedFuel', 0), 2);
                addDataRow(includeJobExpenses, dataRow, 'totalFuelQuantity', totalFuelQuantity);
                addDataRow(includeJobExpenses, dataRow, 'totalFuelExpense', totalFuelExpense);
                addDataRow(includeJobExpenses, dataRow, 'fuelCostDiff', fuelCostDiff);
            }
        }

        addDataRow(true, dataRow, ++j, status);

        if (isCSV) {
            const sourceJaPoint = get(jobAnalytics, '0');
            const commodities = get(sourceJaPoint, 'commodities', []);
            addDataRow(
                includeQuantity,
                dataRow,
                'scheduledCount',
                getCommaSeparatedCommodites(commodities).scheduledQuantity
            );
            addDataRow(
                includeQuantity,
                dataRow,
                'actualCount',
                getCommaSeparatedCommodites(commodities).actualQuantity
            );
            addDataRow(includeOpeningOdo, dataRow, 'openingOdo', openingOdo);
            addDataRow(includeClosingOdo, dataRow, 'closingOdo', closingOdo);
            addDataRow(
                true,
                dataRow,
                'dtdcKm',
                openingOdo && closingOdo ? toSafeInteger(closingOdo - openingOdo) : '-'
            );
        }

        let delay = 0;
        let totalHaltTimeUp = 0;
        let totalHaltTimeDown = 0;
        let totalHaltTime = 0;
        let totalScheduledDis = 0;
        map(jobAnalytics, (analytics, index) => {
            if (analytics.delay && analytics.actualArrival) {
                delay = analytics.delay;
            }
            if (isMiaharAccount) {
                const routePoint = get(analytics, 'routePoint', null);
                totalScheduledDis += get(routePoint, 'distance', 0);
                const actualDistance = get(analytics, 'actualDistance', 0);
                const isReversed = get(routePoint, 'reversePoint');
                if (!isReversed) {
                    totalHaltTimeUp = totalHaltTimeUp + get(analytics, 'haltTime', 0);
                } else {
                    totalHaltTimeDown = totalHaltTimeDown + get(analytics, 'haltTime', 0);
                }
                addDataRow(isMiaharAccount, dataRow, `scheduledDistance-${index + 1}`, totalScheduledDis);
                addDataRow(isMiaharAccount, dataRow, `actualDistance-${index + 1}`, actualDistance);
            }
        });
        totalHaltTime = getTimeDiffHours(totalHaltTimeUp + totalHaltTimeDown, false);
        totalHaltTimeUp = getTimeDiffHours(totalHaltTimeUp, false);
        totalHaltTimeDown = getTimeDiffHours(totalHaltTimeDown, false);
        addDataRow(isMiaharAccount, dataRow, 'haltTimeUp', totalHaltTimeUp);
        addDataRow(isMiaharAccount, dataRow, 'haltTimeDown', totalHaltTimeDown);
        addDataRow(isMiaharAccount, dataRow, 'totalHaltTime', totalHaltTime);

        let text = 'On Time';
        if (delay != 0) {
            text = getTimeDiff(Math.abs(delay), false, true);
            if (delay > 0) {
                text += ' late';
            } else {
                text += ' early';
            }
        }
        addDataRow(
            true,
            dataRow,
            ++j,
            status === JOB_STATUS_VALUES.STARTED || status === JOB_STATUS_VALUES.COMPLETED ? text : ''
        );

        addDataRow(true, dataRow, 'vehicleStatus', findvehicleStateFromId(job.jobStatus, loggedInUser));
        const unloadingArrival = get(jobAnalytics[jobAnalyticsLength - 1], 'scheduledArrival');
        const unloadingDeparture = get(jobAnalytics[jobAnalyticsLength - 1], 'scheduledDeparture');
        let unloadingTAT = '-';
        if (unloadingArrival && unloadingDeparture) {
            unloadingTAT = getTimeDiffHours(unloadingDeparture - unloadingArrival);
        }
        addDataRow(true, dataRow, 'plannedUnloading', unloadingTAT);
        addDataRow(true, dataRow, 'totalTatOfTrip', unloadingTAT);
        addDataRow(!isMiaharAccount, dataRow, 'endDate', getReportTime(endDate));
        if (isMiaharAccount) {
            const point = get(jobAnalytics, '0');
            const commoData = get(get(point, 'commodities[0]', {}), 'commodity.name', '-');
            addDataRow(isMiaharAccount, dataRow, `commodity`, commoData);
            addDataRow(isMiaharAccount, dataRow, 'jobEndDate', getMomentTime(endDate).format(DATE_FORMAT));
            addDataRow(isMiaharAccount, dataRow, 'jobEndTime', getMomentTime(endDate).format(DATE_FORMAT_ONLY_TIME));
        }
        const time = jobAnalytics[0].actualArrival ? jobAnalytics[0].actualArrival : jobAnalytics[0].actualDeparture;
        let duration;
        if (
            jobAnalytics[jobAnalytics.length - 1].status === 'COMPLETED' ||
            jobAnalytics[jobAnalytics.length - 1].status === 'LATE' ||
            jobAnalytics[jobAnalytics.length - 1].status === 'ONTIME' ||
            jobAnalytics[jobAnalytics.length - 1].status === 'EARLY'
        ) {
            duration = totalDuration;
        } else {
            duration = time ? moment().valueOf() - time : '';
        }
        addDataRow(!isShreemanAccount, dataRow, 'duration', duration ? getTimeDiff(duration, false, true) : '');
        addDataRow(
            isShreemanAccount,
            dataRow,
            'duration',
            duration ? getTimeDiffHours(duration, false, true, true) : ''
        );
        addDataRow(
            !isMiaharAccount && !isShreemanAccount,
            dataRow,
            'stopTime',
            totalTransitTime > 0 ? getTimeDiffHours(totalTransitTime - runningDuration) : ''
        );
        addDataRow(
            isShreemanAccount,
            dataRow,
            'stopTime',
            totalTransitTime > 0 ? getTimeDiffHours(totalTransitTime - runningDuration, false, true, true) : ''
        );
        addDataRow(true, dataRow, 'maxSpeed', maxSpeed ? maxSpeed : '-');

        const vehicleLive = get(vehicle, 'id') && find(liveData, { vehicleId: vehicle.id });
        addDataRow(includeCurrentLocation, dataRow, ++j, vehicleLive ? vehicleLive.address : '');

        addDataRow(
            includeCurrentStatus,
            dataRow,
            ++j,
            vehicleLive ? VEHICLE_STATUS_NAME_JOB[vehicleLive.currentStatus] : ''
        );

        let jobPercentDownTime;
        if (status === 'COMPLETED' && jobAnalytics.length > 1) {
            const firstPointDepartTime = jobAnalytics[0].actualDeparture;
            const lastPointArrivalTime = jobAnalytics[jobAnalytics.length - 1].actualArrival;
            const diff = lastPointArrivalTime - firstPointDepartTime;
            if (percentDownTime > 0 && percentDownTime < diff) {
                jobPercentDownTime = (percentDownTime / diff) * 100;
            }
        }

        addDataRow(includeVehicleLoadState, dataRow, 'vehicleLoadState', jobAnalytics[0].vehicleState);
        addDataRow(includeJobDistance, dataRow, ++j, `${getJobDistanceRoundedValue(currentKm)}`);

        let scheduledDistance = get(job, 'scheduledDistance');
        scheduledDistance = scheduledDistance ? scheduledDistance : get(job, 'route.distance', 0);
        if (!scheduledDistance && get(jobAnalytics, `[${jobAnalytics.length - 1}].routePoint.distance`)) {
            scheduledDistance = get(jobAnalytics, `[${jobAnalytics.length - 1}].routePoint.distance`, 0);
        }
        addDataRow(includeJobDistance, dataRow, ++j, `${getJobDistanceRoundedValue(scheduledDistance)}`);

        addDataRow(
            includeJobDistance,
            dataRow,
            'diffInJobDistance',
            `${getJobDistanceRoundedValue(scheduledDistance - currentKm)}`
        );

        addDataRow(includeJobDistance, dataRow, ++j, missingKm ? `${round(missingKm)}` : '');
        addDataRow(includeJobDistance, dataRow, ++j, jobPercentDownTime ? `${round(jobPercentDownTime)} %` : '');
        addDataRow(
            includePercentJobCompleted,
            dataRow,
            ++j,
            status === JOB_STATUS_VALUES.STARTED && currentKm < get(route, 'distance')
                ? `${round((currentKm / route.distance) * 100, 2)}%`
                : ''
        );
        addDataRow(
            includePredictedDelay,
            dataRow,
            ++j,
            predictedDelay != 0 ? getTimeDiff(Math.abs(predictedDelay), false, true) : ''
        );
        addDataRow(includeJobNotes, dataRow, 'jobNotes', notes ? notes : '');
        for (let k = 0; k < jobAnalyticsLength; k++) {
            let customFields = get(jobAnalytics[k], 'customFields', []);
            if (job.customFields) {
                map(job.customFields, (cf) => {
                    customFields.push(cf);
                });
            }
            map(customFields, (cf) => {
                addDataRow(includeCustomField, dataRow, `${cf.field.id}-${cf.field.keyName}`, get(cf, 'value', ''));
            });
        }

        const freightFare = get(jobAnalytics[jobAnalyticsLength - 1], 'freightFare', 0);
        addDataRow(includeFreightFare, dataRow, 'freightFare', freightFare);
        addDataRow(true, dataRow, 'freightInvoiceNumber', freightInvoiceNumber);
        addDataRow(true, dataRow, 'detentionInvoiceNumber', detentionInvoiceNumber);

        if (includeProfitAndLoss && isCSV) {
            const transactionExpensesDetails = jobsTransactionExpenses[id];

            let totalExpense = 0;
            forEach(jobsTransactionExpensesUniqueCols, (colKey) => {
                const foundExp = get(find(transactionExpensesDetails, { expenseType: colKey }), 'transactionList', []);

                if (colKey === 'Fuel') {
                    addDataRow(
                        true,
                        dataRow,
                        'expenses-fuelQuantity-DR',
                        get(find(foundExp, { transactionType: 'DR' }), 'quantity', 0)
                    );
                    addDataRow(
                        true,
                        dataRow,
                        'expenses-fuelQuantity-CR',
                        get(find(foundExp, { transactionType: 'CR' }), 'quantity', 0)
                    );
                }
                const transactionTypeDRAmount = get(find(foundExp, { transactionType: 'DR' }), 'amount', 0);
                const transactionTypeCRAmount = get(find(foundExp, { transactionType: 'CR' }), 'amount', 0);
                addDataRow(true, dataRow, `expense-${colKey}-DR`, transactionTypeDRAmount);
                addDataRow(true, dataRow, `expense-${colKey}-CR`, transactionTypeCRAmount);
                totalExpense += transactionTypeDRAmount;
            });

            let advance = get(jobBudget, 'advance', 0);
            let balance = freightFare - advance;
            let profit = freightFare - totalExpense;

            // more cols
            addDataRow(true, dataRow, 'expenses-advance', advance); //freightFare
            addDataRow(true, dataRow, 'expenses-totalExpenses', totalExpense);
            addDataRow(true, dataRow, 'expenses-balance', balance);
            addDataRow(true, dataRow, 'expenses-profit', profit);
        }
        if (get(jobAnalytics[0], 'docketNumber', 0)) {
            addDataRow(includeBillNumbers, dataRow, BILL_NUMBER_TYPES.DOCKET, get(jobAnalytics[0], 'docketNumber', ''));
        }
        j = 10;
        let tatTotal = 0;
        let tatTotalPlanned = 0;
        let transitTimeTotal = 0;
        for (let k = 1; k < maxJobPointLength; k++) {
            if (jobAnalytics[k]) {
                const jobAnalyticsPoint = jobAnalytics[k];

                let address = get(jobAnalyticsPoint.routePoint, 'address', '');
                const addressAddressBookId = get(jobAnalyticsPoint, 'routePoint.addressBookId', '');
                if (addressAddressBookId) {
                    address = get(addressBookAsMap, `${addressAddressBookId}.name`, address);
                }

                if (k === jobAnalyticsLength - 1) {
                    addDataRow(
                        true,
                        dataRow,
                        'arrivalTimeAtDestination',
                        jobAnalyticsPoint.actualArrival
                            ? getReportTime(getMomentTime(jobAnalyticsPoint.actualArrival, true).valueOf())
                            : ''
                    );

                    addDataRow(
                        true,
                        dataRow,
                        'scheduledArrivalTimeAtDestination',
                        jobAnalyticsPoint.scheduledArrival
                            ? getReportTime(getMomentTime(jobAnalyticsPoint.scheduledArrival, true).valueOf())
                            : ''
                    );
                }

                if (includeEPODDetails) {
                    let epodUser = get(userListMiniAsMap, jobAnalytics[k].epodUploadedBy);
                    addDataRow(true, dataRow, 'epodStatus', jobAnalytics[k].epodStatus);
                    addDataRow(true, dataRow, 'epodComments', jobAnalytics[k].epodComments);
                    addDataRow(true, dataRow, 'epodLongitude', jobAnalytics[k].epodLongitude);
                    addDataRow(true, dataRow, 'epodLatitude', jobAnalytics[k].epodLatitude);
                    addDataRow(
                        true,
                        dataRow,
                        'epodUploadTime',
                        jobAnalytics[k].epodUploadTime
                            ? getMomentTime(jobAnalytics[k].epodUploadTime, timezone).format(DATE_FORMAT)
                            : ''
                    );
                    addDataRow(true, dataRow, 'epodUploadedBy', `${get(epodUser, '1', '')} ${get(epodUser, '2', '')}`);
                    addDataRow(true, dataRow, 'epodReason', jobAnalytics[k].epodReason);

                    // jobAnalytics[j].vehicleState
                }

                addDataRow(true, dataRow, ++j, address);

                addDataRow(
                    true,
                    dataRow,
                    ++j,
                    jobAnalyticsPoint.actualArrival
                        ? getReportTime(getMomentTime(jobAnalyticsPoint.actualArrival, true).valueOf())
                        : ''
                );
                addDataRow(
                    true,
                    dataRow,
                    ++j,
                    jobAnalyticsPoint.scheduledArrival
                        ? getReportTime(getMomentTime(jobAnalyticsPoint.scheduledArrival, true).valueOf())
                        : ''
                );
                addDataRow(
                    true,
                    dataRow,
                    ++j,
                    jobAnalyticsPoint.actualDeparture
                        ? getReportTime(getMomentTime(jobAnalyticsPoint.actualDeparture, true).valueOf())
                        : ''
                );
                //Ideal Departure Time From Destination
                addDataRow(
                    true,
                    dataRow,
                    ++j,
                    jobAnalyticsPoint.scheduledDeparture
                        ? getReportTime(getMomentTime(jobAnalyticsPoint.scheduledDeparture, true).valueOf())
                        : ''
                );

                //Halt Unloading Duration at Destination
                if (!isShreemanAccount) {
                    if (jobAnalytics[0].vehicleState && jobAnalytics[0].vehicleState === 'UNLOADED' && k == 1) {
                        addDataRow(true, dataRow, ++j, '');
                    } else {
                        addDataRow(
                            true,
                            dataRow,
                            ++j,
                            jobAnalyticsPoint.actualDeparture && jobAnalyticsPoint.actualArrival
                                ? getTimeDiff(
                                      Math.abs(jobAnalyticsPoint.actualDeparture - jobAnalyticsPoint.actualArrival),
                                      false,
                                      true
                                  )
                                : ''
                        );
                    }
                } else {
                    if (jobAnalytics[0].vehicleState && jobAnalytics[0].vehicleState === 'UNLOADED' && k == 1) {
                        addDataRow(true, dataRow, ++j, '');
                    } else {
                        addDataRow(
                            true,
                            dataRow,
                            ++j,
                            jobAnalyticsPoint.actualDeparture && jobAnalyticsPoint.actualArrival
                                ? getTimeDiffHours(
                                      Math.abs(jobAnalyticsPoint.actualDeparture - jobAnalyticsPoint.actualArrival),
                                      false,
                                      true,
                                      true
                                  )
                                : ''
                        );
                    }
                }

                const tat = jobAnalytics[k - 1] ? get(jobAnalytics[k - 1], 'routePoint.transitTime') : null;
                const transitTime =
                    jobAnalyticsPoint.scheduledArrival && jobAnalytics[k - 1].scheduledDeparture
                        ? jobAnalyticsPoint.scheduledArrival - jobAnalytics[k - 1].scheduledDeparture
                        : null;
                const actualTransitTime =
                    jobAnalyticsPoint.actualArrival && jobAnalytics[k - 1].actualDeparture
                        ? jobAnalyticsPoint.actualArrival - jobAnalytics[k - 1].actualDeparture
                        : null;
                const tatVal = tat ? tat : transitTime ? transitTime : null;
                const delayVal = tatVal && actualTransitTime ? actualTransitTime - tatVal : null;

                addDataRow(
                    includeTat,
                    dataRow,
                    ++j,
                    tat ? getTimeDiffHours(tat) : transitTime ? getTimeDiffHours(transitTime) : ''
                );

                // let actualTransitTimeTotal = null;
                if (k - 1 >= 0) {
                    tatTotal =
                        // tatTotal +
                        jobAnalytics[k].actualArrival && jobAnalytics[0].actualDeparture
                            ? jobAnalytics[k].actualArrival - jobAnalytics[0].actualDeparture
                            : 0;
                    // tatTotal + jobAnalytics[k - 1] ? jobAnalytics[k - 1].transitTime : null;
                    transitTimeTotal =
                        // transitTimeTotal +
                        jobAnalytics[k].scheduledArrival && jobAnalytics[0].scheduledDeparture
                            ? jobAnalytics[k].scheduledArrival - jobAnalytics[0].scheduledDeparture
                            : 0;

                    tatTotalPlanned =
                        tatTotalPlanned + jobAnalytics[k].scheduledArrival && jobAnalytics[0].scheduledDeparture
                            ? jobAnalytics[k].scheduledArrival - jobAnalytics[0].scheduledDeparture
                            : 0;
                }
                if (!isShreemanAccount) {
                    addDataRow(includeTat, dataRow, ++j, actualTransitTime ? getTimeDiffHours(actualTransitTime) : '');
                } else {
                    addDataRow(
                        includeTat,
                        dataRow,
                        ++j,
                        actualTransitTime ? getTimeDiffHours(actualTransitTime, false, true, true) : ''
                    );
                }

                const commodities = get(jobAnalyticsPoint, 'commodities', []);
                addDataRow(includeQuantity, dataRow, ++j, getCommaSeparatedCommodites(commodities).scheduledQuantity);
                addDataRow(includeQuantity, dataRow, ++j, getCommaSeparatedCommodites(commodities).actualQuantity);
                addDataRow(isEnableForBigBasket, dataRow, ++j, getCommaSeparatedCommodites(commodities).variance);

                addDataRow(
                    true,
                    dataRow,
                    ++j,
                    delayVal ? `${getTimeDiff(Math.abs(delayVal), false, true)} ${delayVal > 0 ? 'late' : 'early'}` : ''
                );

                addDataRow(true, dataRow, ++j, jobAnalyticsPoint.status);
                addDataRow(includeActualDistance, dataRow, ++j, get(jobAnalyticsPoint, 'actualDistance', ''));

                if (includeBillNumbers) {
                    const billNumbers = get(jobAnalyticsPoint, 'billNumbers', []);
                    let docketsFallback = [];

                    if (k === 1 && includes([FLEETX_ACCOUNTS.RKMPowergen], get(loggedInUser, 'accountId'))) {
                        docketsFallback = filter(get(jobAnalytics[0], 'billNumbers', []), {
                            type: BILL_NUMBER_TYPES.DOCKET,
                        });
                    }

                    if (!isEmpty(billNumbers) || !isEmpty(docketsFallback)) {
                        forEach(BILL_NUMBER_TYPES, (type) => {
                            let allBillsForType = filter(billNumbers, { type: type });
                            if (type === BILL_NUMBER_TYPES.DOCKET && isEmpty(allBillsForType) && k === 1) {
                                allBillsForType = docketsFallback;
                            }

                            let valueString = reduce(
                                allBillsForType,
                                (acc, item) => {
                                    if (type === BILL_NUMBER_TYPES.EWAY && item.expiryDate) {
                                        return `${acc}${acc ? ',' : ''} ${item.value}(${getReportTime(
                                            item.expiryDate
                                        )})`;
                                    } else {
                                        let billNumberValue = item.value;
                                        if (
                                            !billNumberValue &&
                                            k === 1 &&
                                            type === BILL_NUMBER_TYPES.DOCKET &&
                                            includes([FLEETX_ACCOUNTS.RKMPowergen], get(loggedInUser, 'accountId'))
                                        ) {
                                            billNumberValue = get(jobAnalytics[0], 'docketNumber', '');
                                        }
                                        return `${acc}${acc ? ',' : ''} ${billNumberValue}`;
                                    }
                                },
                                ''
                            );
                            addDataRow(includeBillNumbers, dataRow, type, valueString);
                        });
                    }
                }
            } else {
                addDataRow(true, dataRow, ++j, '');
                addDataRow(true, dataRow, ++j, '');
                addDataRow(true, dataRow, ++j, '');
                addDataRow(true, dataRow, ++j, '');
                addDataRow(true, dataRow, ++j, '');
                addDataRow(true, dataRow, ++j, '');
                addDataRow(includeTat, dataRow, ++j, '');
                addDataRow(includeTat, dataRow, ++j, '');
                addDataRow(true, dataRow, ++j, '');
                addDataRow(true, dataRow, ++j, '');
            }
        }

        addDataRow(
            includeTat,
            dataRow,
            'scheduleTotalTat',
            transitTimeTotal ? getTimeDiffHours(transitTimeTotal, false, false, false) : '-'
        );

        addDataRow(includeTat, dataRow, 'actualTotalTat', getTimeDiffHours(tatTotal, false, false, false));

        const differenceInTATString =
            transitTimeTotal && tatTotal
                ? getTimeDiffHours(Math.abs(tatTotal - transitTimeTotal), false, false, false)
                : '';
        addDataRow(
            includeTat,
            dataRow,
            'differenceInTAT',
            differenceInTATString !== '0 min' && differenceInTATString !== ''
                ? `${differenceInTATString} ${tatTotal - transitTimeTotal > 0 ? 'late' : 'early'}`
                : '-'
        );

        addDataRow(includeTat, dataRow, 'totalTatOfTrip', getTimeDiffHours(tatTotalPlanned, false, false, false));

        let returnTripTAT = '';
        if (jobAnalytics.length > 1) {
            returnTripTAT =
                get(jobAnalytics, 'length') &&
                jobAnalytics[jobAnalytics.length - 1].actualArrival &&
                jobAnalytics[jobAnalytics.length - 2].actualDeparture &&
                getTimeDiff(
                    Math.abs(
                        jobAnalytics[jobAnalytics.length - 1].actualArrival -
                            jobAnalytics[jobAnalytics.length - 2].actualDeparture
                    ),
                    false,
                    true
                );
        }
        let roundTripTAT = '';
        if (!isShreemanAccount) {
            roundTripTAT =
                get(jobAnalytics, 'length') &&
                jobAnalytics[jobAnalytics.length - 1].actualDeparture &&
                jobAnalytics[0].actualArrival &&
                getTimeDiff(
                    Math.abs(jobAnalytics[jobAnalytics.length - 1].actualDeparture - jobAnalytics[0].actualArrival),
                    false,
                    true
                );
        } else {
            roundTripTAT =
                get(jobAnalytics, 'length') &&
                jobAnalytics[jobAnalytics.length - 1].actualDeparture &&
                jobAnalytics[0].actualArrival &&
                getTimeDiffHours(
                    Math.abs(jobAnalytics[jobAnalytics.length - 1].actualDeparture - jobAnalytics[0].actualArrival),
                    false,
                    true,
                    true
                );
        }

        const tripTAT =
            get(jobAnalytics, 'length') &&
            jobAnalytics[jobAnalytics.length - 1].actualArrival &&
            jobAnalytics[0].actualDeparture &&
            getTimeDiff(
                Math.abs(jobAnalytics[jobAnalytics.length - 1].actualArrival - jobAnalytics[0].actualDeparture),
                false,
                true
            );
        addDataRow(true, dataRow, 'tripTAT', tripTAT);

        addDataRow(includeReturnTripTAT, dataRow, 'roundTripTAT', roundTripTAT);
        addDataRow(includeRoundTripTAT, dataRow, 'returnTripTAT', returnTripTAT);
        if (includeJobAlarms) {
            addDataRow(includeJobAlarms, dataRow, 'hardAcceleration', get(jobAlarmCounts, 'hardAcc', 0));
            addDataRow(includeJobAlarms, dataRow, 'hardDeceleration', get(jobAlarmCounts, 'hardDec', 0));
            addDataRow(includeJobAlarms, dataRow, 'fatigueDrivingCount', get(jobAlarmCounts, 'fatigueDrive', 0));
            addDataRow(includeJobAlarms, dataRow, 'overspeeding', get(jobAlarmCounts, 'overSpeed', 0));
            addDataRow(includeJobAlarms, dataRow, 'sharpTurns', get(jobAlarmCounts, 'sharpTurns', 0));
            addDataRow(includeJobAlarms, dataRow, 'nightDriving', get(jobAlarmCounts, 'nightDriving', 0));
        }

        data.push(dataRow);
    }

    startReportCreation(
        ORIENTATION.LANDSCAPE,
        orderHeaders(headers),
        data,
        reportFormat,
        'Job Report',
        moment(startDate),
        moment(endDate),
        vehicleNumber,
        reportName,
        noOfCols,
        '',
        '',
        true,
        '',
        '',
        {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
        },
        false
    );
}

export async function triggerRiskReportDownload(
    accesstoken,
    reportFormat,
    filterConfig,
    vehicleId,
    groupId,
    startDate,
    endDate,
    jobs,
    currentStatusFilter,
    pointStatusFilter,
    onlyPointsFilter,
    routeIdFilter,
    notesFilter,
    jobsSummary,
    customerId,
    vehicleListMiniAsMap,
    loggedInUser,
    jobSize,
    addressBookAsMap,
    includePastRunning,
    includeCompleteDuring
) {
    try {
        const {
            data: { content: jobResult },
        } = await fetchJobListNewV2(accesstoken, {
            vehicleId,
            groupId,
            from: startDate,
            to: endDate,
            sortByDateAsc: false,
            currentStatusFilter,
            pointStatusFilter,
            routeIdFilter,
            notesFilter,
            onlyPointsFilter,
            selectedCustomerId: customerId,
            page: 1,
            size: jobSize,
            includePastRunning,
            includeEndsBetween: includeCompleteDuring,
        }).promise;
        let totalJobs = jobResult.length;
        let jobFilter = [];
        let timeoutIds = [];
        let totalCount = 0;
        // Checking separately if the number of jobs that match the criteria are greater than 200
        // also since we are filtering, creating a list of filetered jobs to iterate over later
        for (let i = 0; i < totalJobs; i++) {
            let job = jobResult[i];
            let jobAnalyticsLength = job.jobAnalytics.length;
            if (
                job.jobAnalytics &&
                jobAnalyticsLength > 1 &&
                job.jobAnalytics[jobAnalyticsLength - 1].actualArrival &&
                job.jobAnalytics[0].actualDeparture
            ) {
                if (totalCount > 200) {
                    alert('Too many jobs! Please reduce the time span');
                    return;
                }
                totalCount++;
                jobFilter.push(jobResult[i]);
            }
        }
        const totalFilteredJobs = jobFilter.length;

        const isAllowedAddressPointColumn = isAllowedAddressPointColumnForAccount(loggedInUser);
        const isTotalStoppageColumnActive = isTotalStoppageColumnActiveAccount(loggedInUser);
        const areRiskyPointsAvailableFlag = areRiskyPointsAvailable(loggedInUser);

        const initialInfoColumns = 4;
        const numberOfParameterColumns =
            4 + isTotalStoppageColumnActive + isAllowedAddressPointColumn + 2 * areRiskyPointsAvailableFlag; // + 4 because stoppage, Distance, TAT , TAT minutes cols
        const numberOfScoreColumns = 3 + areRiskyPointsAvailableFlag;
        const numberOfRisksColumns = 3 + areRiskyPointsAvailableFlag;

        const totalScoreColumns = 4 - !areRiskyPointsAvailableFlag;
        const totalScore = totalScoreColumns * 5;
        const gradeThreshold = getGradeThreshold(totalScore);
        const riskThreshold = getRiskThreshold(totalScore);

        const mergeColIndexParametersStart = 5;
        const mergeColIndexParametersEnd =
            mergeColIndexParametersStart +
            3 +
            2 * areRiskyPointsAvailableFlag +
            isTotalStoppageColumnActive +
            isAllowedAddressPointColumn;
        const mergeColIndexRisksStart = mergeColIndexParametersEnd + 1;
        const mergeColIndexRisksEnd = mergeColIndexRisksStart + 2 + areRiskyPointsAvailableFlag;
        const mergeColIndexScoresStart = mergeColIndexRisksEnd + 1;
        const mergeColIndexScoresEnd = mergeColIndexScoresStart + 2 + areRiskyPointsAvailableFlag;

        let colIndex = initialInfoColumns;

        const colStoppageIndex = initialInfoColumns + 1;
        const colStoppage = EXCEL_COL[initialInfoColumns + 1];

        const colDistanceIndex = colStoppageIndex + isAllowedAddressPointColumn + isTotalStoppageColumnActive + 1;
        const colDistance = EXCEL_COL[colDistanceIndex];

        colIndex = colIndex + 2;
        const colDelayIndex = colDistanceIndex + 2;
        const colDelay = EXCEL_COL[colDelayIndex];

        const colRisky = EXCEL_COL[colDelayIndex + 1];

        const totalScoreStartIndex = initialInfoColumns + numberOfParameterColumns + numberOfRisksColumns + 1;
        const totalScoreEndIndex = totalScoreStartIndex + numberOfScoreColumns - 1;

        const colTotalScoreStart = EXCEL_COL[totalScoreStartIndex];
        const colTotalScoreEnd = EXCEL_COL[totalScoreEndIndex];
        const colTotalScore = EXCEL_COL[totalScoreEndIndex + 1];

        let jobFinal = [];
        for (let i = 0; i < totalFilteredJobs; i++) {
            let job = jobFilter[i];
            let riskyPointCount = 0;
            let riskyPointMinuteDurations = [];
            let jobAnalyticsLength = job.jobAnalytics.length;
            const duration = toSafeInteger(
                moment
                    .duration(
                        job.jobAnalytics[jobAnalyticsLength - 1].actualArrival - job.jobAnalytics[0].actualDeparture
                    )
                    .asMinutes()
            );
            const durationAsHours = getTimeDiffHours(
                moment.duration(
                    job.jobAnalytics[jobAnalyticsLength - 1].actualArrival - job.jobAnalytics[0].actualDeparture
                )
            );
            set(job, 'duration', duration);
            set(job, 'durationAsHours', durationAsHours);
            set(job, 'vehicleName', get(job, 'vehicle.name', '-'));
            set(job, 'vehicleId', get(job, 'vehicle.id', '-'));
            set(job, 'vehicleNumber', get(job, 'vehicle.licensePlate', '-'));
            set(job, 'driverName', `${get(job, 'driver.firstName', '-')} ${get(job, 'driver.lastName', '')} `);
            set(job, 'driverNumber', get(job, 'driver.phoneNumber', '-'));
            timeoutIds.push(await delay(600));
            const resp = await fetchTripsWithoutAggregations(
                accesstoken,
                getMomentTime(job.jobAnalytics[0].actualDeparture),
                getMomentTime(job.jobAnalytics[jobAnalyticsLength - 1].actualArrival),
                job.vehicle.id,
                '',
                1,
                500
            ).promise;
            const trips = resp.data ? resp.data.trips : [];
            const stopList = createStoppageData(
                getMergedListWithVehicles(trips, vehicleListMiniAsMap),
                getStoppageThresholdInMinutes(loggedInUser),
                getMomentTime(job.jobAnalytics[0].actualDeparture),
                getMomentTime(job.jobAnalytics[jobAnalyticsLength - 1].actualArrival),
                addressBookAsMap,
                loggedInUser,
                '',
                '',
                true
            );
            if (!isEmpty(stopList)) {
                set(job, 'noOfStops', stopList.length);
            } else {
                set(job, 'noOfStops', 0);
            }
            let totalStopDuration = 0;
            let totalKnownStops = 0;
            let totalAllowedStops = 0;
            let allowedAddressStops = isAllowedStopPoint[get(loggedInUser, 'accountId', '')];
            if (isAllowedAddressPointColumn || isTotalStoppageColumnActive) {
                map(stopList, (stop) => {
                    totalStopDuration = totalStopDuration + get(stop, 'duration', 0);
                    if (get(stop, 'nearestAddress.isInside')) {
                        totalKnownStops++;
                        if (
                            checkInsideAllowedStopPoint(
                                allowedAddressStops,
                                get(stop, 'nearestAddress.id'),
                                get(job, 'route.id')
                            )
                        ) {
                            totalAllowedStops++;
                        }
                    }
                });
                set(job, 'totalStopDuration', totalStopDuration);
                set(job, 'totalKnownStops', totalKnownStops);
                set(job, 'totalAllowedStops', totalAllowedStops);
            }

            const { data: timeOnSite } = await fetchTimeOnSiteAnalytics(
                accesstoken,
                '',
                job.vehicle.id,
                job.jobAnalytics[0].actualDeparture,
                job.jobAnalytics[jobAnalyticsLength - 1].actualArrival
            ).promise;
            if (timeOnSite && timeOnSite.knownSites) {
                map(timeOnSite.knownSites, (knownSite) => {
                    if (find(isKnownRiskyPoint[get(loggedInUser, 'accountId', '')], { id: knownSite.id })) {
                        let riskyPointDuration = toSafeInteger(
                            knownSite.timeOnSite ? moment.duration(knownSite.timeOnSite).asMinutes() : 0
                        );
                        if (riskyPointDuration > getRiskyPointMinuteThreshold(loggedInUser)) {
                            riskyPointMinuteDurations.push(riskyPointDuration);
                            riskyPointCount++;
                        }
                    }
                });
            }
            set(job, 'riskyPointCount', riskyPointCount);
            set(job, 'riskyPointMinuteDurations', join(riskyPointMinuteDurations, ','));
            jobFinal.push(job);
            if (!isEmpty(timeoutIds)) {
                map(timeoutIds, (timeout) => {
                    clearTimeout(timeout);
                });
                timeoutIds = [];
            }
        }

        const mergeCell = [
            {
                startColumn: EXCEL_COL[mergeColIndexParametersStart],
                endColumn: EXCEL_COL[mergeColIndexParametersEnd],
                text: 'Parameters',
                row: '2',
                alignment: { vertical: 'middle', horizontal: 'center' },
                font: { name: 'Arial', family: 2, size: 11, bold: true },
            },
            {
                startColumn: EXCEL_COL[mergeColIndexRisksStart],
                endColumn: EXCEL_COL[mergeColIndexRisksEnd],
                text: 'Risks',
                row: '2',
                alignment: { vertical: 'middle', horizontal: 'center' },
                font: { name: 'Arial', family: 2, size: 11, bold: true },
            },
            {
                startColumn: EXCEL_COL[mergeColIndexScoresStart],
                endColumn: EXCEL_COL[mergeColIndexScoresEnd],
                text: 'Scores',
                row: '2',
                alignment: { vertical: 'middle', horizontal: 'center' },
                font: { name: 'Arial', family: 2, size: 11, bold: true },
            },
        ];
        const reportName = createReportFilename(
            reportFormat,
            'Jobs-Risky-Point-Report',
            vehicleId,
            '',
            moment(startDate),
            moment(endDate),
            null,
            null,
            null
        );
        if (!isEmpty(jobFinal)) {
            const headers = [];
            const isCSV = reportFormat === REPORT_FORMAT.CSV;
            let noOfCols = 0;
            noOfCols += addHeader(true, headers, 'srNo', 'Sr. No.', 150);
            noOfCols += addHeader(true, headers, 'truckNo', 'Truck No.', 150);
            noOfCols += addHeader(true, headers, 'driverName', 'Driver Name', 150);
            noOfCols += addHeader(true, headers, 'driverNo', 'Driver No.', 150);
            noOfCols += addHeader(
                true,
                headers,
                'stoppage',
                `Stoppage >=${getStoppageThresholdInMinutes(loggedInUser)}m`,
                150
            );
            noOfCols += addHeader(
                isAllowedAddressPointColumn,
                headers,
                'totalAllowedStops',
                `Allowed Address Stop  >=${getStoppageThresholdInMinutes(loggedInUser)}m`,
                150
            );
            noOfCols += addHeader(
                isTotalStoppageColumnActive,
                headers,
                'totalStopDuration',
                `Stoppage Total Time`,
                150
            );
            noOfCols += addHeader(true, headers, 'distance', 'Distance', 150);
            noOfCols += addHeader(true, headers, 'TAThrs', 'TAT hours', 150);
            noOfCols += addHeader(true, headers, 'TATmins', 'TAT minutes', 150);
            noOfCols += addHeader(
                areRiskyPointsAvailableFlag,
                headers,
                'riskyPointsCount',
                `Risky Points > ${getRiskyPointMinuteThreshold(loggedInUser)}m`,
                150
            );
            noOfCols += addHeader(
                areRiskyPointsAvailableFlag,
                headers,
                'riskyPointsStoppageMins',
                `Risky Points Stoppage Times(m)`,
                150
            );
            noOfCols += addHeader(
                true,
                headers,
                'stoppageRisk',
                `Stoppage >=${getStoppageThresholdInMinutes(loggedInUser)}m`,
                150
            );
            noOfCols += addHeader(true, headers, 'distanceRisk', 'Distance', 150);
            noOfCols += addHeader(true, headers, 'delayRisk', 'Delay Jobs', 150);
            noOfCols += addHeader(areRiskyPointsAvailableFlag, headers, 'riskyPointsRisk', 'Risky Points', 150);
            noOfCols += addHeader(
                true,
                headers,
                'stoppageScore',
                `Stoppage >=${getStoppageThresholdInMinutes(loggedInUser)}m`,
                150
            );
            noOfCols += addHeader(true, headers, 'distanceScore', 'Distance', 150);
            noOfCols += addHeader(true, headers, 'delayJobsScore', 'Delay Jobs', 150);
            noOfCols += addHeader(areRiskyPointsAvailableFlag, headers, 'riskyPointScore', 'Risky Points', 150);
            noOfCols += addHeader(true, headers, 'totalScore', 'Total Score', 150);
            noOfCols += addHeader(true, headers, 'grade', 'Grade', 150);
            noOfCols += addHeader(true, headers, 'riskLevel', 'Risk Level', 150);
            const data = [];
            const length = jobFinal.length;
            for (let i = 0; i < length; i++) {
                const index = i + 4;
                const job = jobFinal[i];
                const {
                    duration,
                    vehicleNumber,
                    noOfStops,
                    totalAllowedStops,
                    totalStopDuration,
                    riskyPointCount,
                    riskyPointMinuteDurations,
                    currentKm,
                    durationAsHours,
                    driverName,
                    driverNumber,
                    route,
                } = job;
                const dataRow = {};
                addDataRow(true, dataRow, 'srNo', i + 1);
                addDataRow(true, dataRow, 'truckNo', vehicleNumber);
                addDataRow(true, dataRow, 'driverName', driverName);
                addDataRow(true, dataRow, 'driverNo', driverNumber);
                addDataRow(true, dataRow, 'stoppage', noOfStops);
                addDataRow(true, dataRow, 'totalAllowedStops', totalAllowedStops);
                addDataRow(
                    isTotalStoppageColumnActive,
                    dataRow,
                    'totalStopDuration',
                    getTimeDiffHours(totalStopDuration)
                );
                addDataRow(true, dataRow, 'distance', currentKm);
                addDataRow(true, dataRow, 'TAThrs', durationAsHours);
                addDataRow(true, dataRow, 'TATmins', duration);
                addDataRow(true, dataRow, 'riskyPointsCount', riskyPointCount);
                addDataRow(true, dataRow, 'riskyPointsStoppageMins', riskyPointMinuteDurations);

                //Stoppage Risk
                addDataRow(true, dataRow, 'stoppageRisk', {
                    formula: `+IF(${colStoppage}${index}>3,"E",IF(${colStoppage}${index}>2,"C","N"))`,
                });
                // +IF(M8="No risk",0,IF(M8="Low",1,IF(M8="medium",3,5)))
                //Stoppage Score
                addDataRow(true, dataRow, 'stoppageScore', {
                    formula: `+IF(${colStoppage}${index}>3,5,IF(${colStoppage}${index}>2,3,0))`,
                });

                //Distance
                //Risk
                let distanceFormula, distanceFormulaScore;
                distanceFormula = `${getDistanceFormula(job.route.id, colDistance, index, 'RISK', loggedInUser)}`;
                distanceFormulaScore = `${getDistanceFormula(job.route.id, colDistance, index, 'SCORE', loggedInUser)}`;
                addDataRow(true, dataRow, 'distanceRisk', {
                    formula: `${distanceFormula}`,
                });
                //=+IF(N10="No risk",0,IF(N10="Low",1,IF(N10="medium",3,5)))
                //Score
                addDataRow(true, dataRow, 'distanceScore', {
                    formula: `${distanceFormulaScore}`,
                });

                //Delay
                //Risk
                let delayFormula, delayFormulaScore;
                delayFormula = `${getDelayFormula(job.route.id, colDelay, index, 'RISK', loggedInUser)}`;
                delayFormulaScore = `${getDelayFormula(job.route.id, colDelay, index, 'SCORE', loggedInUser)}`;
                addDataRow(true, dataRow, 'delayRisk', {
                    formula: `${delayFormula}`,
                });
                //Score
                //=+IF(O11="No risk",0,IF(O11="Low",1,IF(O11="medium",3,5)))
                addDataRow(true, dataRow, 'delayJobsScore', {
                    formula: `${delayFormulaScore}`,
                });

                //Risky Point
                //Risk
                addDataRow(true, dataRow, 'riskyPointsRisk', {
                    formula: `+IF(${colRisky}${index}<1,"N","E")`,
                });
                //Score
                // =+IF(P8="No risk",0,IF(P8="Low",1,IF(P8="medium",3,5)))
                addDataRow(true, dataRow, 'riskyPointScore', {
                    formula: `+IF(${colRisky}${index}<1,0,5)`,
                });
                // Total Score
                addDataRow(true, dataRow, 'totalScore', {
                    formula: `${totalScore}-SUM(${colTotalScoreStart}${index}:${colTotalScoreEnd}${index})`,
                });
                //Grade
                let stoppageSuperecedesScore;
                if (isStoppagePointPresenceSupercedeScore(loggedInUser)) {
                    stoppageSuperecedesScore = checkStoppageSupercedesCondition(
                        noOfStops,
                        totalAllowedStops,
                        totalStopDuration,
                        get(route, 'id')
                    );
                }
                if (
                    (areRiskyPointsAvailableFlag &&
                        isRiskyPointPresenceSupercedeScore(loggedInUser) &&
                        riskyPointCount > 0) ||
                    stoppageSuperecedesScore
                ) {
                    addDataRow(true, dataRow, 'grade', 'D');
                    addDataRow(true, dataRow, 'riskLevel', 'E');
                } else {
                    addDataRow(true, dataRow, 'grade', {
                        formula: `IF(${colTotalScore}${index}>${gradeThreshold[0]},"A",IF(${colTotalScore}${index}>=${gradeThreshold[1]},"B",IF(${colTotalScore}${index}>=${gradeThreshold[2]},"C","D")))`,
                    });
                    addDataRow(true, dataRow, 'riskLevel', {
                        formula: `IF(${colTotalScore}${index}>=${riskThreshold[0]},"N",IF(${colTotalScore}${index}>=${riskThreshold[1]},"C","E"))`,
                    });
                }
                data.push(dataRow);
            }
            startReportCreation(
                ORIENTATION.LANDSCAPE,
                headers,
                data,
                reportFormat,
                'Job Risk Report',
                moment(startDate),
                moment(endDate),
                vehicleId,
                reportName,
                noOfCols,
                '',
                '',
                true,
                '',
                3,
                {
                    top: { style: 'thin' },
                    left: { style: 'thin' },
                    bottom: { style: 'thin' },
                    right: { style: 'thin' },
                },
                false,
                mergeCell
            );
        }
    } catch (error) {
        alert('Something went wrong while downloading report. Please try again later.');
        console.log('error', error);
        if (window.Sentry) {
            Sentry.captureException(error);
        }
    }
}

export async function triggerTurnoverReportDownload(
    accesstoken,
    reportFormat,
    vehicleId,
    groupId,
    startDate,
    endDate,
    currentStatusFilter,
    pointStatusFilter,
    onlyPointsFilter,
    routeIdFilter,
    notesFilter,
    customerId,
    jobSize
) {
    try {
        const {
            data: { content: jobs },
        } = await fetchJobsV2(accesstoken, {
            vehicleId,
            groupId,
            from: startDate,
            to: endDate,
            sortByDateAsc: false,
            currentStatusFilter,
            pointStatusFilter,
            routeIdFilter,
            notesFilter,
            onlyPointsFilter,
            selectedCustomerId: customerId,
            page: 1,
            size: jobSize,
        }).promise;

        const reportName = createReportFilename(
            reportFormat,
            'Job Turnover Report',
            '',
            '',
            moment(startDate),
            moment(endDate),
            null,
            null,
            null
        );

        const headers = [];
        const footerRow = {};
        const isCSV = true;
        let noOfCols = 0;
        noOfCols += addHeader(true, headers, 'jobName', 'Job Name', 150, footerRow);
        noOfCols += addHeader(true, headers, 'loadingDate', 'Loading Date', 150, footerRow);
        noOfCols += addHeader(true, headers, 'unloadingDate', 'Unloading Date', 150, footerRow);
        noOfCols += addHeader(true, headers, 'client', 'Client', 150, footerRow);
        noOfCols += addHeader(true, headers, 'project', 'Project', 150, footerRow);
        noOfCols += addHeader(true, headers, 'TSP', 'TSP', 150, footerRow);
        noOfCols += addHeader(true, headers, 'vehicleNo', 'Vehicle No.', 150, footerRow);
        noOfCols += addHeader(true, headers, 'driverName', 'Driver Name', 150, footerRow);
        noOfCols += addHeader(true, headers, 'driverNumber', 'Driver No.', 150, footerRow);
        noOfCols += addHeader(true, headers, 'crusher', 'Crusher', 150, footerRow);
        noOfCols += addHeader(true, headers, 'royaltyFranchisor', 'Royalty Franchisor', 150, footerRow);
        noOfCols += addHeader(true, headers, 'materialType', 'Material Type', 150, footerRow);
        noOfCols += addHeader(true, headers, 'loadingWeight', 'Loading Weight', 150, footerRow);
        noOfCols += addHeader(true, headers, 'unloadingWeight', 'Unloading Weight', 150, footerRow);
        noOfCols += addHeader(true, headers, 'minimumOfTwoWeights', 'Minimum Of Two Weights', 150, footerRow);
        noOfCols += addHeader(true, headers, 'freightRate', 'Freight Rate', 150, footerRow);
        noOfCols += addHeader(true, headers, 'grossTurnover', 'Gross Turnover', 150, footerRow);
        noOfCols += addHeader(true, headers, 'materialRate', 'Material Rate', 150, footerRow);
        noOfCols += addHeader(true, headers, 'materialCost', 'Material Cost', 150, footerRow);
        noOfCols += addHeader(true, headers, 'royaltyValue', 'Royalty Value', 150, footerRow);
        noOfCols += addHeader(true, headers, 'netTurnover', 'Net Turnonver', 150, footerRow);
        noOfCols += addHeader(true, headers, 'transportRate', 'Transport Rate', 150, footerRow);
        noOfCols += addHeader(true, headers, 'transportCost', 'Transport Cost', 150, footerRow);
        noOfCols += addHeader(true, headers, 'grossProfit', 'Gross Profit', 150, footerRow);
        noOfCols += addHeader(true, headers, 'taxClient', 'Tax Client', 150, footerRow);
        noOfCols += addHeader(true, headers, 'taxCrusher', 'Tax Crusher', 150, footerRow);
        noOfCols += addHeader(true, headers, 'taxTP', 'Tax TP', 150, footerRow);
        const data = [];
        const length = get(jobs, 'length', 0);
        for (let i = 0; i < length; i++) {
            const job = jobs[i];
            const { jobAnalytics, name, route, vehicle, transporter, vendor, customFields } = job;
            const jobAnalyticsLength = jobAnalytics.length;
            const routePoints = get(route, 'routePoints', '[]');
            const driverName = get(vehicle, 'activeAssignment.user.name', '');
            const driverNumber = get(vehicle, 'activeAssignment.user.phoneNumber', '');
            const vehicleNo = get(vehicle, 'licensePlate', '-');
            const vendorName = get(vendor, 'name', '-');
            const materialRate = get(vendor, 'rate', 0);
            const materialType = get(vendor, 'materialType', '-');
            const taxCrusher = getTaxSlabValue(get(vendor, 'tax', 0));
            const transporterName = get(transporter, 'name', '-');
            const transporterRate = get(transporter, 'rate', 0);
            const taxTP = getTaxSlabValue(get(transporter, 'tax', 0));
            const transporterWeightLogic = get(transporter, 'weightLogic', '-');
            const royaltyParty = getCustomFieldObjectFromKeyName(customFields, 'Royalty Party Name');
            const royaltyRate = getCustomFieldObjectFromKeyName(customFields, 'Royalty Rate');
            const royaltyVolume = getCustomFieldObjectFromKeyName(customFields, 'Volume');
            const royaltyValue = get(royaltyRate, 'value', 0) * get(royaltyVolume, 'value', 0);
            const loadingWeightColumn = 'M';
            const unloadingWeightColumn = 'N';
            const minimumOfTwoWeightColumn = 'O';
            const freightRateCol = 'P';
            const grossTurnOverCol = 'Q';
            const materialRateCol = 'R';
            const materialCostCol = 'S';
            const royaltyValueCol = 'T';
            const netTurnoverCol = 'U';
            const transporterRateCol = 'V';
            const transportCostCol = 'W';
            let transporterWeightLogicCol = loadingWeightColumn;
            transporterWeightLogicCol =
                transporterWeightLogic === 'UNLOADED'
                    ? unloadingWeightColumn
                    : transporterWeightLogic === 'LOADED'
                    ? loadingWeightColumn
                    : minimumOfTwoWeightColumn;
            if (jobAnalyticsLength === 1) {
                const dataRow = {};
                const loadingDate = get(jobAnalytics[0], 'actualDeparture')
                    ? getMomentTime(get(jobAnalytics[0], 'actualDeparture'))
                    : '-';
                const customerName = get(jobAnalytics[0], 'customer.name', '-');
                addDataRow(true, dataRow, 'jobName', name);
                addDataRow(true, dataRow, 'loadingDate', loadingDate);
                addDataRow(true, dataRow, 'unloadingDate', '-');
                addDataRow(true, dataRow, 'client', customerName);
                addDataRow(true, dataRow, 'project', '-');
                addDataRow(true, dataRow, 'TSP', transporterName);
                addDataRow(true, dataRow, 'vehcileNo', vehicleNo);
                addDataRow(true, dataRow, 'driverName', driverName);
                addDataRow(true, dataRow, 'driverNumber', driverNumber);
                addDataRow(true, dataRow, 'crusher', vendorName);
                addDataRow(true, dataRow, 'royaltyFranchisor', get(royaltyParty, 'field.keyName', '-'));
                addDataRow(true, dataRow, 'materialType', materialType);
                addDataRow(true, dataRow, 'loadingWeight', '-');
                addDataRow(true, dataRow, 'unloadingWeight', '-');
                addDataRow(true, dataRow, 'minimumOfTwoWeights', '-');
                addDataRow(true, dataRow, 'freightRate', '-');
                addDataRow(true, dataRow, 'grossTurnover', '-');
                addDataRow(true, dataRow, 'materialRate', '-');
                addDataRow(true, dataRow, 'materialCost', '-');
                addDataRow(true, dataRow, 'royaltyValue', '-');
                addDataRow(true, dataRow, 'netTurnover', '-');
                addDataRow(true, dataRow, 'transportRate', '-');
                addDataRow(true, dataRow, 'transportCost', '-');
                addDataRow(true, dataRow, 'grossProfit', '-');
                addDataRow(true, dataRow, 'taxClient', '-');
                addDataRow(true, dataRow, 'taxCrusher', '-');
                addDataRow(true, dataRow, 'taxTP', '-');
            } else {
                for (let j = 1; j < jobAnalyticsLength; j++) {
                    const dataRow = {};
                    const loadingDate = get(jobAnalytics[0], 'actualDeparture')
                        ? getHumanizeTime(get(jobAnalytics[0], 'actualDeparture'))
                        : '-';
                    const unloadingDate = get(jobAnalytics[j], 'actualDeparture')
                        ? getHumanizeTime(get(jobAnalytics[j], 'actualDeparture'))
                        : '-';
                    const customerName = get(jobAnalytics[j], 'customer.name', '-');
                    const projectName = get(jobAnalytics[j], 'project.name', '-');
                    const taxClient = getTaxSlabValue(get(jobAnalytics[j], 'project.tax', 0));
                    const loadingWeight = getCustomFieldObjectFromKeyName(
                        jobAnalytics[j].customFields,
                        'Loading Weight'
                    );
                    const unloadingWeight = getCustomFieldObjectFromKeyName(
                        jobAnalytics[j].customFields,
                        'Unloading Weight'
                    );
                    const weightLogic = get(job, 'project.weightLogic', '-');
                    let weightLogicCol =
                        weightLogic === 'UNLOADED'
                            ? unloadingWeightColumn
                            : weightLogic === 'LOADED'
                            ? loadingWeightColumn
                            : minimumOfTwoWeightColumn;
                    const vehicleNo = get(job, 'vehicle.name', '-');
                    const taxTP = getTaxSlabValue(get(transporter, 'tax', 0));
                    const rowIndex = get(data, 'length', 0) + 2;
                    addDataRow(true, dataRow, 'jobName', name);
                    addDataRow(true, dataRow, 'loadingDate', loadingDate);
                    addDataRow(true, dataRow, 'unloadingDate', unloadingDate);
                    addDataRow(true, dataRow, 'client', customerName);
                    addDataRow(true, dataRow, 'project', projectName);
                    addDataRow(true, dataRow, 'TSP', transporterName);
                    addDataRow(true, dataRow, 'vehicleNo', vehicleNo);
                    addDataRow(true, dataRow, 'driverName', driverName);
                    addDataRow(true, dataRow, 'driverNumber', driverNumber);
                    addDataRow(true, dataRow, 'crusher', vendorName);
                    addDataRow(true, dataRow, 'royaltyFranchisor', get(royaltyParty, 'field.keyName', '-'));
                    addDataRow(true, dataRow, 'materialType', materialType);
                    addDataRow(true, dataRow, 'loadingWeight', get(loadingWeight, 'value', 0));
                    addDataRow(true, dataRow, 'unloadingWeight', get(unloadingWeight, 'value', 0));
                    addDataRow(true, dataRow, 'minimumOfTwoWeights', {
                        formula: `+IF(${loadingWeightColumn}${rowIndex}<${unloadingWeightColumn}${rowIndex},${loadingWeightColumn}${rowIndex},${unloadingWeightColumn}${rowIndex})`,
                    });
                    addDataRow(true, dataRow, 'freightRate', get(jobAnalytics[j], 'project.freightRate', 0));
                    addDataRow(true, dataRow, 'grossTurnover', {
                        formula: `${weightLogicCol}${rowIndex}*${freightRateCol}${rowIndex}`,
                    });
                    addDataRow(true, dataRow, 'materialRate', materialRate);
                    addDataRow(true, dataRow, 'materialCost', {
                        formula: `${loadingWeightColumn}${rowIndex}*${materialRateCol}${rowIndex}`,
                    });
                    addDataRow(true, dataRow, 'royaltyValue', royaltyValue);
                    addDataRow(true, dataRow, 'netTurnover', {
                        formula: `${grossTurnOverCol}${rowIndex} - ${materialCostCol}${rowIndex} - ${royaltyValueCol}${rowIndex} `,
                    });
                    addDataRow(true, dataRow, 'transportRate', transporterRate);
                    addDataRow(true, dataRow, 'transportCost', {
                        formula: `${transporterWeightLogicCol}${rowIndex} * ${transporterRateCol}${rowIndex}`,
                    });
                    addDataRow(true, dataRow, 'grossProfit', {
                        formula: `${netTurnoverCol}${rowIndex} - ${transportCostCol}${rowIndex} `,
                    });
                    addDataRow(true, dataRow, 'taxClient', {
                        formula: `(${grossTurnOverCol}${rowIndex}*${taxClient})/100`,
                    });

                    addDataRow(true, dataRow, 'taxCrusher', {
                        formula: `(${materialCostCol}${rowIndex}*${taxCrusher})/100`,
                    });
                    addDataRow(true, dataRow, 'taxTP', {
                        formula: `(${transportCostCol}${rowIndex}*${taxTP})/100`,
                    });
                    data.push(dataRow);
                }
            }
        }
        const headerStyles = {
            font: { name: 'Arial', family: 2, size: 12, bold: true },
            alignment: { wrapText: true, vertical: 'middle', horizontal: 'center' },
            // fill: {
            //     type: 'pattern',
            //     pattern: 'solid',
            //     fgColor: { argb: '99CCFF' },
            // },
        };
        startReportCreation(
            ORIENTATION.LANDSCAPE,
            headers,
            data,
            reportFormat,
            'Job Report',
            moment(startDate),
            moment(endDate),
            '',
            reportName,
            noOfCols,
            '',
            '',
            '',
            '',
            '',
            {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' },
            },
            false,
            '',
            headerStyles,
            false
        );
    } catch (error) {
        alert('Something went wrong while downloading report. Please try again later.');
        console.log('error', error);
        if (window.Sentry) {
            Sentry.captureException(error);
        }
    }
}

export const getETA = (predictedDelay, job) => {
    if (!predictedDelay) {
        predictedDelay = 0;
    } else {
        predictedDelay = +predictedDelay;
    }
    const lastPoint = last(job.jobAnalytics);
    if (!lastPoint) {
        return '-';
    }

    const etaTime = lastPoint.actualArrival
        ? lastPoint.actualArrival
        : lastPoint.scheduledArrival
        ? getMomentTime(lastPoint.scheduledArrival).valueOf() + predictedDelay
        : null;

    return etaTime ? getHumanizeTime(etaTime) : '-';
};

export const getParkingInTime = (allParkingData, job, loadingArrival) => {
    const data = {};
    let found = false;
    const vehicleId = get(job, 'vehicle.id');
    const vehicleJobs = filter(allParkingData, { 'vehicleId': +vehicleId });

    if (!vehicleJobs || get(vehicleJobs, 'length', -1) < 1) {
        return { parkingIn: '-', parkingOut: '-' };
    } else {
        map(vehicleJobs, (timeOnSite) => {
            const timeRanges = get(timeOnSite, 'timeRanges', []);
            map(timeRanges, (timeRange) => {
                const startTime = get(timeRange, 'startTime')
                    ? getMomentTime(get(timeRange, 'startTime')).format(DATE_FORMAT_UNIX_TIMESTAMP)
                    : '';
                const endTime = get(timeRange, 'endTime')
                    ? getMomentTime(get(timeRange, 'endTime')).format(DATE_FORMAT_UNIX_TIMESTAMP)
                    : '';
                if (startTime && endTime && loadingArrival && startTime <= loadingArrival) {
                    set(data, 'parkingIn', getMomentTime(startTime).format(DATE_FORMAT_TIME_24_REPORTS));
                    set(data, 'parkingOut', endTime ? getMomentTime(endTime).format(DATE_FORMAT_TIME_24_REPORTS) : '-');
                    set(data, 'parkingTAT', endTime - startTime);
                    found = true;
                } else {
                    if (!found) {
                        set(data, 'parkingIn', '-');
                        set(data, 'parkingOut', '-');
                        set(data, 'parkingTAT', 0);
                    }
                }
            });
        });
    }
    return data;
};

export async function triggerJobReportBirlaDurgapur(
    accesstoken,
    loggedInUser,
    reportFormat,
    filterConfig,
    jobSize = 0,
    vehicleId,
    groupId,
    startDate,
    endDate,
    tags,
    currentStatusFilter,
    pointStatusFilter,
    routeIdFilter,
    notesFilter,
    onlyPointsFilter,
    groupName,
    vehicleNumber,
    customer,
    customerId,
    rfidMismatch,
    includePastRunning,
    includeEndsBetween,
    manualEntry,
    ewayExpired,
    epodUploaded,
    byCreatedDate,
    weighmentJobs,
    byEndDate
) {
    // if (!jobsSummary || size(jobsSummary) <= 0) {
    //     return;
    // }
    // const jobSize = reduce(
    //     jobsSummary,
    //     function(result, value, key) {
    //         if (includes(['EARLY', 'LATE', 'ONTIME', 'SCHEDULED'], key)) {
    //             return result + value;
    //         }
    //         return result;
    //     },
    //     0
    // );

    const accountId = get(loggedInUser, 'accountId');
    const isChanderiaAccount = accountId === FLEETX_ACCOUNTS.CHANDERIA;
    const reportLikeChanderiaAccount = accountsForChanderia1505LikeJobSummaryReport();
    const extraColumnAllowed = isShreeCementOrBirla(loggedInUser);
    const extraColsAllowed = jobSummaryExtraColsAllowed(loggedInUser);

    const thresholdTimeSpent = true;
    const geofenceStatus = true;
    const showSlabData = isJSWAccount(loggedInUser);

    const jobResult = await fetchJobsV2(accesstoken, {
        vehicleId,
        groupId,
        from: startDate,
        to: endDate,
        sortByDateAsc: false,
        currentStatusFilter,
        pointStatusFilter,
        routeIdFilter,
        notesFilter,
        onlyPointsFilter,
        selectedCustomerId: customerId,
        page: 1,
        size: jobSize,
        rfidMismatch,
        distanceBetweenScheduledAndUnloadingPoint: extraColsAllowed,
        includePastRunning,
        includeEndsBetween,
        manualEntry,
        ewayExpired,
        epodUploaded,
        byCreatedDate,
        weighmentJobs,
        byEndDate,
        thresholdTimeSpent,
        geofenceStatus,
        powerDisconnectRiskyPoints: !!extraColsAllowed,
        updatedScheduledDestinationAddress: !!extraColsAllowed,
        slabFields: showSlabData,
    }).promise;
    let jobConfigListing = [];
    if (showSlabData) {
        const jobConfigListingResult = await fetchSlabListing(accesstoken).promise;
        jobConfigListing = jobConfigListingResult.data.content;
    }
    const jobs = jobResult.data.content;
    const parkingAddressBookId = getParkingAddressBookId(loggedInUser);

    let liveData;
    let liveLocationLink;

    const jobConfigListingIdsArr = [];
    const idsArr = [];
    const jobIdsList = [];
    let idsArrShare = [];
    let allDisconnected = [];
    let allParkingDataResp = [];
    let minStartDate = '';
    let maxEndDate = '';

    let allCustomFieldsPresent = [];
    // maxjobAnalyticsLengthInMainReport: The variable stores the maximum length value of jobAnalytics for COMPLETED Jobs
    let maxjobAnalyticsLengthInMainReport = 0;
    // maxjobAnalyticsLengthInRunningReport: The variable stores the maximum length value of jobAnalytics for STARTED Jobs
    let maxjobAnalyticsLengthInRunningReport = 0;

    const showDisconnectAlarm = reportLikeChanderiaAccount;

    if (showCustomFieldsInJobSummaryReport(loggedInUser)) {
        forEach(jobs, (job) => {
            const { jobAnalytics, status } = job;
            if (jobAnalytics.length > 1) {
                if (status === 'COMPLETED') {
                    if (jobAnalytics.length > maxjobAnalyticsLengthInMainReport) {
                        maxjobAnalyticsLengthInMainReport = jobAnalytics.length;
                    }
                }
                if (status === 'STARTED') {
                    if (jobAnalytics.length > maxjobAnalyticsLengthInRunningReport) {
                        maxjobAnalyticsLengthInRunningReport = jobAnalytics.length;
                    }
                }
                // Get all type of Custom Fields present in jobs object
                for (let i = 0; i < jobAnalytics.length; i++) {
                    if (jobAnalytics[i].customFields) {
                        for (let j = 0; j < jobAnalytics[i].customFields.length; j++) {
                            allCustomFieldsPresent.push(get(jobAnalytics[i].customFields[j], 'field.id'));
                        }
                    }
                }
            }
        });

        allCustomFieldsPresent = uniq(allCustomFieldsPresent);
        allCustomFieldsPresent = await getCustomFieldObjectFromIds(allCustomFieldsPresent, accesstoken);
    }

    const isDurgapurCementAccount = isBirlaDurgapurAccount(loggedInUser);

    forEach(jobs, function (job) {
        if (job.status !== JOB_STATUS_VALUES.COMPLETED && job.vehicle && get(job, 'vehicle.id') && get(job, 'id')) {
            idsArr.push(job.vehicle.id);
            jobIdsList.push(job.id);
        } else if (job.status === JOB_STATUS_VALUES.COMPLETED && job.vehicle && get(job, 'vehicle.id')) {
            const { jobAnalytics } = job;
            const length = get(jobAnalytics, 'length', 0);
            if (length > 1) {
                let actualArrival = get(jobAnalytics[length - 1], 'actualArrival');
                if (!minStartDate) {
                    minStartDate = get(job, 'createdDate');
                } else {
                    let createdDate = get(job, 'createdDate');
                    if (createdDate < minStartDate) {
                        minStartDate = createdDate;
                    }
                }
                if (!maxEndDate) {
                    maxEndDate = actualArrival;
                } else {
                    if (actualArrival > maxEndDate) {
                        maxEndDate = actualArrival;
                    }
                }
            }
        }
    });

    if (minStartDate && maxEndDate && parkingAddressBookId) {
        allParkingDataResp = await fetchVehicleOnSiteAnalytics(
            accesstoken,
            '',
            '',
            parkingAddressBookId,
            minStartDate - 4 * HOURS_24,
            maxEndDate
        ).promise;
    }
    allParkingDataResp = get(allParkingDataResp, 'data.vehiclesOnSite', []);

    if (idsArr.length > 0) {
        const liveResult = await fetchLiveVehicleData(accesstoken, join(uniq(idsArr))).promise;
        let allLive = get(liveResult, 'data', []);
        allLive = map(allLive, (vehicle) => {
            return refactorCurrentStatus(vehicle);
        });
        const allFilter = filter(allLive, (vehicle) => {
            if (
                vehicle.currentStatus === 'REMOVED' ||
                vehicle.currentStatus === VEHICLE_STATUS.UNREACHABLE ||
                vehicle.currentStatus === VEHICLE_STATUS.DISCONNECTED
            ) {
                return false;
            } else return true;
        });
        allDisconnected = filter(allLive, (vehicle) => {
            return (
                vehicle.currentStatus == VEHICLE_STATUS.DISCONNECTED ||
                vehicle.currentStatus == VEHICLE_STATUS.UNREACHABLE
            );
        });
        if (allFilter) {
            idsArrShare = map(allFilter, (vehicle) => {
                return vehicle.vehicleId;
            });
        }
        liveData = liveResult.data;
    }

    if (idsArr.length > 0) {
        // if (showTATCompareColumnsForDiff(loggedInUser)) {
        //     const jobLocation = await fetchVehiclesJobShareUrl(
        //         accesstoken,
        //         'Running jobs',
        //         uniq(idsArrShare),
        //         minStartDate.valueOf(),
        //         moment.duration(DEAFULT_EXPIRY_DAYS * HOURS_24, 'seconds').toISOString()
        //     ).promise;
        //     liveLocationLink = get(jobLocation, 'payload.data.url');
        // } else {
        const shareLocation = await createShareLocation(
            accesstoken,
            'Running jobs',
            uniq(idsArrShare),
            getMomentTime(),
            getMomentTime().add(2, 'days')
        ).promise;
        liveLocationLink = get(shareLocation, 'data.url', '-');
        //}
    }

    const showPincodeHit = !(reportLikeChanderiaAccount || isDurgapurCementAccount);
    const showDeviationType = !(reportLikeChanderiaAccount || isDurgapurCementAccount);

    const reportName = createReportFilename(
        reportFormat,
        'Job',
        vehicleNumber,
        groupName,
        moment(startDate),
        moment(endDate),
        null,
        null,
        customer
    );
    const allHeaders = {};
    const mainHeaders = [];
    const idleHeaders = [];
    const disconnectedHeaders = [];
    const isCSV = reportFormat === REPORT_FORMAT.CSV;
    const footerRow = {};

    const isDeviceTypeShownToAccount = isjklcAccount();
    const showParkingCols = !!parkingAddressBookId;
    const showLoadingCols = !includes([7571, 7572, 7573, 7575, 8199, 914], accountId);
    const showRemarks = !includes([7571, 7572, 7573, 7575, 8199, 914], accountId);

    const mainColumnList = [
        {
            label: 'Date',
            key: 'date',
            show: true,
            width: 15,
        },
        { label: 'Delivery No.', key: 'deliveryNo', show: true, width: 20 },
        { label: 'Vehicle No.', key: 'vehicleNo', show: true, width: 20 },
        { label: 'Transporter', key: 'transporter', show: true, width: 30 },
        { label: 'Group Name', key: 'groupName', show: true, width: 30 },
        { label: 'State', key: 'state', show: true, width: 20 },
        // { label: 'Warehouse Name', show: true, width: 35 },
        { label: 'Sold To Party', key: 'soldToParty', show: true, width: 30 },
        { label: 'Ship To Party', key: 'shipToParty', show: true, width: 30 },
        { label: 'Invoice Number', key: 'invoiceNumber', show: isShreeCement8000(), width: 30 },
        { label: 'Scheduled Destination', key: 'scheduledDestination', show: true, width: 30 },
        {
            label: 'SAP Destination Pincode',
            key: 'pinCode',
            show: reportLikeChanderiaAccount,
            width: 25,
        },
        {
            label: 'SAP Destination City',
            key: 'cityName',
            show: reportLikeChanderiaAccount,
            width: 25,
        },
        {
            label: 'Schedule Unloading Destination',
            key: 'notes',
            show: reportLikeChanderiaAccount,
            width: 25,
        },
        { label: 'PGI Date', key: 'pgiDate', show: true, width: 25 },
        { label: 'Date/Time Of Dispatch', key: 'dateOfDispath', show: true, width: 30 },
        { label: 'Job Start Date', key: 'jobStartDate', show: true, width: 30 },
        { label: 'Job End Date', key: 'jobEndDate', show: true, width: 70 },
        { label: 'Parking In', key: 'parkingIn', show: showParkingCols, width: 25 },
        { label: 'Parking Out', key: 'parkingOut', show: showParkingCols, width: 25 },
        { label: 'Parking TAT', key: 'parkingTAT', show: showParkingCols, width: 25 },
        {
            label: 'Parking >6hr',
            key: 'parkingDiff',
            show: showParkingCols && showTATCompareColumnsForDiff(loggedInUser),
            width: 25,
        },
        {
            label: `${isDurgapurCementAccount ? 'Check In' : 'Loading In'}`,
            key: 'checkIn',
            show: showLoadingCols,
            width: 25,
        },
        {
            label: `${isDurgapurCementAccount ? 'Gate Out' : 'Loading Out'}`,
            key: 'checkOut',
            show: showLoadingCols,
            width: 25,
        },
        { label: 'Loading TAT', key: 'loadingTAT', show: showLoadingCols, width: 25 },
        {
            label: 'Loading >6hr',
            key: 'loadingDiff',
            show: showLoadingCols && showTATCompareColumnsForDiff(loggedInUser),
            width: 25,
        },
        { label: 'Cust Entry Date & Time', key: 'custEntryDate', show: true, width: 25 },
        { label: 'Cust Exit Date and time', key: 'customerExitDate', show: true, width: 25 },
        { label: 'Unloading TAT', key: 'unloadingTAT', show: true, width: 25 },
        { label: 'Unloading >10hr', key: 'unloadingDiff', show: showTATCompareColumnsForDiff(loggedInUser), width: 25 },
        { label: 'Source Plant', key: 'sourcePlant', show: true, width: 30 },
        { label: 'Segment', key: 'segment', show: true, width: 25 },
        { label: 'Scheduled Unloading Destination', key: 'scheduledUnloadingDestination', show: true, width: 30 },
        { label: 'Travel Time (Plant to Destination) (hh:mm)', key: 'travelTime', show: true, width: 30 },
        { label: 'Halt Time', key: 'haltTime', show: true, width: 30 },
        { label: 'Scheduled Distance (km)', key: 'scheduledDistance', show: true, width: 30 },
        { label: 'Travelled Distance (km)', key: 'travelledDistance', show: true, width: 30 },
        // Pincode Hit
        { label: 'Pincode Hit', key: 'pincodeHit', show: showPincodeHit, width: 30 },
        { label: 'Difference (Travelled vs Scheduled)', key: 'difference', show: true, width: 25 },
        { label: 'Remarks', key: 'remarks', show: showRemarks, width: 30 },
        { label: 'Job Percentage Downtime', key: 'jobPercentageDowntime', show: true, width: 30 },
        { label: 'Actual Unloading Address', key: 'actualUnloadingAddress', show: true, width: 30 },
        // { label: 'Vehicle Entered Pincode', key: 'vehicleEnteredPincode', show: true, width: 30 },
        { label: 'Actual Unloading Map Link', key: 'actualUnloadingMapLink', show: true, width: 70 },
        { label: 'Vehicle Tag', key: 'vehicleTags', show: true, width: 40 },
        // { label: 'Inner Geofence Stay', key: 'thresholdTimeSpent', show: true, width: 30 },
        { label: 'Inner Geofence Entry', key: 'geofenceStatus', show: true, width: 30 },
        { label: 'Aerial Distance', key: 'aerialDistance', show: extraColumnAllowed, width: 30 },
        { label: 'Job Link', key: 'jobLink', show: true, width: 70 },
        {
            label: 'Distance (B/w Scheduled & Unloading Point)',
            key: 'distanceBetweenScheduledAndUnloadingPoint',
            show: extraColsAllowed,
            width: 25,
        },
        {
            label: 'Deviation Count',
            key: 'deviationCount',
            show: extraColsAllowed,
            width: 25,
        },
        {
            label: 'Deviation Type ',
            key: 'destinationDeviationType',
            show: extraColsAllowed && showDeviationType,
            width: 25,
        },

        {
            label: 'Job Close Reason',
            key: 'jobClosingReason',
            show: extraColsAllowed,
            width: 25,
        },
        {
            label: 'Point Close Reason',
            key: 'pointCloseReason',
            show: extraColsAllowed,
            width: 25,
        },
        {
            label: 'Disconnect Status',
            key: 'disconnectCount',
            show: showDisconnectAlarm || extraColumnAllowed,
            width: 25,
        },
        {
            label: 'Tampering Risk',
            key: 'riskLevel',
            show: extraColsAllowed,
            width: 25,
        },
        {
            label: 'Device Type',
            key: 'deviceType',
            show: isDeviceTypeShownToAccount,
            width: 25,
        },
    ];
    if (showSlabData) {
        map(jobConfigListing, (jcd, index) => {
            let label = '';
            switch (jcd.name) {
                case 'STOPPAGE_DETENTION_DISTANCE':
                    label = 'Detention Brackets KM';
                    break;
                case 'STOPPAGE_DETENTION_TIME':
                    label = 'Detention Brackets HRS';
                    break;
                case 'UNLOADING_STOPPAGE_DEVIATION':
                    label = 'Deviation Unloading Point';
                    break;
            }
            label += ` ${index % 4 == 3 ? 'Beyond ' : ''}${jcd.minThreshold}${
                index % 4 == 3 ? '' : ' to ' + jcd.maxThreshold
            }${jcd.name == 'UNLOADING_STOPPAGE_DEVIATION' ? ' Kms' : ''}`;

            mainColumnList.push({
                show: showSlabData,
                label: label,
                key: `${jcd.id}`,
                width: 40,
            });
            jobConfigListingIdsArr.push(jcd.id);
        });
    }

    if (showCustomFieldsInJobSummaryReport(loggedInUser)) {
        // Adding Dynamic CustomFields in Headers for mainColumnList
        for (let k = 0; k < maxjobAnalyticsLengthInMainReport; k++) {
            map(allCustomFieldsPresent, (cf) => {
                mainColumnList.push({
                    show: true,
                    label: `${cf.keyName}(${k === 0 ? 'source' : k})`,
                    key: `${cf.id}-${cf.keyName}-${k}`,
                    width: 30,
                });
            });
        }
    }

    // same as running column list
    const idleColumnList = [
        {
            label: 'Date',
            key: 'date',
            show: true,
            width: 15,
        },
        { label: 'Delivery No.', key: 'devliveryNo', show: true, width: 20 },
        { label: 'Vehicle No.', key: 'vehicleNo', show: true, width: 20 },
        { label: 'Transporter', key: 'transporter', show: true, width: 30 },
        { label: 'Group Name', key: 'groupName', show: true, width: 30 },
        { label: 'State', key: 'state', show: true, width: 20 },
        //{ label: 'Warehouse Name', show: true, width: 35 },
        { label: 'Sold To Party', key: 'soldToParty', show: true, width: 30 },
        { label: 'Ship To Party', key: 'shipToParty', show: true, width: 30 },
        { label: 'Invoice Number', key: 'invoiceNumber', show: isShreeCement8000(), width: 30 },
        { label: 'Scheduled Destination', key: 'scheduledDestination', show: true, width: 30 },
        { label: 'PGI Date', key: 'pgiDate', show: true, width: 25 },
        { label: 'Date/Time Of Dispatch', key: 'dateOfDispatch', show: true, width: 30 },
        { label: 'Job Start Date', key: 'jobStartDate', show: true, width: 30 },
        // { label: 'Cust Entry Date & Time', show: true, width: 25 },
        // { label: 'Cust Exit Date and time', show: true, width: 25 },
        { label: 'Source Plant', key: 'sourcePlant', show: true, width: 30 },
        { label: 'Segment', key: 'segment', show: true, width: 25 },
        { label: 'Present Location', key: 'presentLocation', show: true, width: 70 },
        { label: 'Distance Covered (km)', key: 'distanceCovered', show: true, width: 30 },
        { label: 'Vehicle Tag', key: 'vehicleTags', show: true, width: 40 },
        { label: 'Scheduled Distance (km)', key: 'scheduledDistance', show: true, width: 30 },
        { label: 'Halt Time', key: 'haltTime', show: true, width: 30 },
        // { label: 'ETA (Date & Time)', show: true, width: 30 },
        { label: 'Location Hyperlink', key: 'locationHyperlink', show: true, width: 35 },
        { label: 'Job Link', key: 'jobLink', show: true, width: 70 },
        { label: 'Remarks', key: 'remarks', show: showRemarks, width: 30 },
    ];

    if (showCustomFieldsInJobSummaryReport(loggedInUser)) {
        // Adding Dynamic CustomFields in Headers for idleColumnList
        for (let k = 0; k < maxjobAnalyticsLengthInRunningReport; k++) {
            map(allCustomFieldsPresent, (cf) => {
                idleColumnList.push({
                    show: true,
                    label: `${cf.keyName}(${k === 0 ? 'source' : k})`,
                    key: `${cf.id}-${cf.keyName}-${k}`,
                    width: 30,
                });
            });
        }
    }

    const disconnectedColumnList = [
        {
            label: 'Date',
            key: 'date',
            show: true,
            width: 15,
        },
        { label: 'Vehicle No.', key: 'vehicleNo', show: true, width: 20 },
        { label: 'Last Reported At', key: 'lastReported', show: true, width: 20 },
        { label: 'Vehicle Tag', key: 'vehicleTag', show: true, width: 40 },
        { label: 'Current Vehicle Status', key: 'currentVehicleStatus', show: true, width: 20 },
    ];
    let allNoOfCols = [];
    let noOfColsMain = 0;
    map(mainColumnList, (item, index) => {
        noOfColsMain += addHeader(item.show, mainHeaders, item.key, item.label, item.width, footerRow, item.style);
    });
    let noOfColsIdle = 0;
    map(idleColumnList, (item, index) => {
        noOfColsIdle += addHeader(item.show, idleHeaders, item.key, item.label, item.width, footerRow, item.style);
    });
    let noOfColsDisconnected = 0;
    map(disconnectedColumnList, (item, index) => {
        noOfColsDisconnected += addHeader(
            item.show,
            disconnectedHeaders,
            item.key,
            item.label,
            item.width,
            footerRow,
            item.style
        );
    });
    const allData = {};
    const mainData = [];
    const runningData = [];
    const idleData = [];
    const deviationData = [];
    const disconnectedData = [];
    const length = jobs.length;
    let rowIndex = 0;
    for (let i = 0; i < length; i++) {
        const job = jobs[i];
        const {
            jobAnalytics,
            currentKm,
            route,
            startDate,
            endDate,
            vehicle,
            customer,
            predictedDelay,
            percentDownTime,
            status,
            customFields,
            distanceBetweenScheduledAndUnloadingPoint,
            id: jobId,
            deviationCount,
            jobClosingReason = '',
            jobAlarmCounts = {},
            deviceDisconnectCount,
            riskLevel,
            pinCode,
            cityName,
            notes,
            slabData,
        } = job;

        let slabDataMap =
            slabData && slabData.length > 0
                ? slabData.reduce((a, v) => ({ ...a, [v.slabConfigId]: v ? v.value : '0' }), {})
                : {};
        const jobAnalyticsLength = jobAnalytics.length;
        const routePoints = get(route, 'routePoints', '[]');
        const lastRoutePointIndex = routePoints.length - 1;

        const scheduledDistance = get(job, 'scheduledDistance')
            ? get(job, 'scheduledDistance', 0)
            : lastRoutePointIndex > 0
            ? get(routePoints[lastRoutePointIndex], 'distance', 0)
            : 0;
        const differenceKM = round(currentKm) - round(scheduledDistance);

        const totalTravelTime = get(job, 'totalDuration', 0);
        const totalTransitTime = get(job, 'totalTransitTime', 0);
        const totalIdleTimeDiff = totalTravelTime - totalTransitTime;
        const totalIdleTime = totalIdleTimeDiff >= 0 ? totalIdleTimeDiff : 0;

        const lastJobAnalyticPoint = last(jobAnalytics);
        const destinationDeviationType = get(lastJobAnalyticPoint, 'destinationDeviationType', '');

        let jobPercentDownTime;
        if (status === 'COMPLETED' && jobAnalytics.length > 1) {
            const firstPointDepartTime = get(
                jobAnalytics,
                `0.actualDeparture`,
                get(jobAnalytics, 'actualArrival', getMomentTime(startDate))
            );
            const lastPointArrivalTime = get(
                jobAnalytics,
                `${jobAnalytics.length - 1}.actualArrival`,
                get(jobAnalytics, 'actualDeparture', getMomentTime(endDate))
            );
            const diff = lastPointArrivalTime - firstPointDepartTime;
            if (diff && percentDownTime < diff) {
                jobPercentDownTime = round((percentDownTime / diff) * 100, 2);
            }
        }

        let attachedTags = get(job, 'vehicle.tagIdsList', []);
        let tagNames;
        if (attachedTags.length > 0) {
            tagNames = getTagNamesList(tags, attachedTags).join(',');
        } else {
            tagNames = ' - ';
        }

        if (get(job, 'status', '-') === 'COMPLETED') {
            //for (let j = 1; j < jobAnalyticsLength; j++) {
            rowIndex++;
            const dataRow = {};

            let loadingTAT = '-';
            let unloadingTAT = '-';
            let unloadingDiff = 0;
            const loadingArrival = parseJobNotes(get(jobAnalytics[0], 'notes'), 'Check-in time:');
            const loadingDeparture = parseJobNotes(get(jobAnalytics[0], 'notes'), 'Check-out time:');
            const loadingDiff = getDiffFromHHMMSS(loadingArrival, loadingDeparture, true);
            loadingTAT = getDiffFromHHMMSS(loadingArrival, loadingDeparture);
            const unloadingArrival = get(jobAnalytics[jobAnalyticsLength - 1], 'actualArrival');
            const unloadingDeparture = get(jobAnalytics[jobAnalyticsLength - 1], 'actualDeparture');
            const actualDeparture = get(jobAnalytics[0], 'actualDeparture');
            const actualArrival = get(jobAnalytics[0], 'actualArrival');
            const parkingTime = getParkingInTime(allParkingDataResp, job, actualArrival, unloadingArrival);
            const pincodeHit = get(jobAnalytics[jobAnalyticsLength - 1], 'status') === 'MISSED' ? 'No' : 'Yes';

            if (unloadingArrival && unloadingDeparture) {
                unloadingDiff = unloadingDeparture - unloadingArrival;
                unloadingTAT = getTimeDiffHours(unloadingDeparture - unloadingArrival);
            }

            //Date
            addDataRow(true, dataRow, 'date', getMomentTime().format(DATE_FORMAT));
            //Delivery No
            addDataRow(true, dataRow, 'deliveryNo', get(job, 'name', '-'));
            // Vehicle No.
            addDataRow(true, dataRow, 'vehicleNo', get(vehicle, 'licensePlate', '-'));

            addDataRow(isDeviceTypeShownToAccount, dataRow, 'deviceType', get(vehicle, 'deviceType', '-'));

            //Transporter
            addDataRow(
                true,
                dataRow,
                'transporter',
                get(job, 'transporter.name', '') ? get(job, 'transporter.name', '') : get(vehicle, 'group.name', '-')
            );
            //Group
            addDataRow(true, dataRow, 'groupName', get(job, 'vehicle.group.name', '-'));
            //State
            const destinationState = parseJobNotes(get(job, 'notes', ''), 'Destination State:');
            let finalState = destinationState;
            const stateArray = split(destinationState, '#');
            if (get(stateArray, 'length', 0)) {
                finalState = stateArray[0];
            }
            addDataRow(true, dataRow, 'state', finalState);
            //Warehouse Name
            //addDataRow(true, dataRow, 6, get(jobAnalytics[jobAnalyticsLength - 1], 'routePoint.address', '-')); // jobAnalytics[j].routePoint.address);
            // Sold to party
            addDataRow(true, dataRow, 'soldToParty', parseJobNotes(get(job, 'notes', ''), 'Sold to party :'));
            // Ship to party
            addDataRow(true, dataRow, 'shipToParty', get(jobAnalytics[jobAnalyticsLength - 1], 'customer.name', '-'));
            addDataRow(true, dataRow, 'invoiceNumber', get(jobAnalytics[jobAnalyticsLength - 1], 'invoiceNumber', '-'));
            // Scheduled destination
            addDataRow(
                true,
                dataRow,
                'scheduledDestination',
                get(jobAnalytics[jobAnalyticsLength - 1], 'routePoint.address', '-')
            );
            // PGI Date
            addDataRow(
                true,
                dataRow,
                'pgiDate',
                get(jobAnalytics, '0.scheduledDeparture')
                    ? getMomentTime(get(jobAnalytics, '0.scheduledDeparture')).format(DATE_FORMAT)
                    : '-'
            );
            // Date/ Time Of Dispatch
            addDataRow(
                true,
                dataRow,
                'dateOfDispath',
                get(jobAnalytics, '0.scheduledDeparture')
                    ? getMomentTime(get(jobAnalytics, '0.scheduledDeparture')).format(DATE_FORMAT_TIME_24_REPORTS)
                    : '-'
            );
            addDataRow(
                true,
                dataRow,
                'jobStartDate',
                get(job, 'startDate') ? getMomentTime(get(job, 'startDate')).format(DATE_FORMAT_TIME_24_REPORTS) : '-'
            );
            addDataRow(showParkingCols, dataRow, 'parkingIn', parkingTime.parkingIn);
            addDataRow(showParkingCols, dataRow, 'parkingOut', parkingTime.parkingOut);
            addDataRow(
                showParkingCols,
                dataRow,
                'parkingTAT',
                parkingTime.parkingTAT ? getTimeDiffHours(parkingTime.parkingTAT) : '-'
            );
            addDataRow(showParkingCols, dataRow, 'parkingDiff', parkingTime.parkingTAT > HOURS_6 ? 'YES' : 'NO');
            //loadingIn
            addDataRow(true, dataRow, 'checkIn', loadingArrival ? getHHMMSSFromString(loadingArrival) : '-');
            //loadingOut
            addDataRow(true, dataRow, 'checkOut', loadingDeparture ? getHHMMSSFromString(loadingDeparture) : '-');
            //loadingTAT
            addDataRow(true, dataRow, 'loadingTAT', loadingTAT);
            addDataRow(true, dataRow, 'loadingDiff', loadingDiff > HOURS_6 ? 'YES' : 'NO');
            // Cust Entry Date & time
            addDataRow(
                true,
                dataRow,
                'custEntryDate',
                get(jobAnalytics[jobAnalyticsLength - 1], 'actualArrival', '-') !== '-'
                    ? getMomentTime(get(jobAnalytics[jobAnalyticsLength - 1], 'actualArrival', '-')).format(
                          DATE_FORMAT_TIME_24_REPORTS
                      )
                    : '-'
            );
            // Customer Exit Time
            addDataRow(
                true,
                dataRow,
                'customerExitDate',
                get(jobAnalytics[jobAnalyticsLength - 1], 'actualDeparture', '-') !== '-'
                    ? getMomentTime(get(jobAnalytics[jobAnalyticsLength - 1], 'actualDeparture', '-')).format(
                          DATE_FORMAT_TIME_24_REPORTS
                      )
                    : '-'
            );
            //unloadingTAT
            addDataRow(true, dataRow, 'unloadingTAT', unloadingTAT);
            //unloadingDiff
            addDataRow(true, dataRow, 'unloadingDiff', unloadingDiff > 10 * HOURS_1 ? 'YES' : 'NO');
            //Source Plant
            addDataRow(true, dataRow, 'sourcePlant', get(customer, 'name', '-'));
            // Segment
            addDataRow(true, dataRow, 'segment', get(job, 'segment', '-'));
            // Actual Unloading Destination
            addDataRow(
                true,
                dataRow,
                'scheduledUnloadingDestination',
                lastRoutePointIndex > 0 &&
                    get(jobAnalytics[jobAnalyticsLength - 1], 'actualArrival') &&
                    get(jobAnalytics[jobAnalyticsLength - 1], 'status') !== 'MISSED'
                    ? get(routePoints[lastRoutePointIndex], 'address', '-')
                    : '-'
            );
            // Travel Time
            addDataRow(true, dataRow, 'travelTime', getTimeDiffHours(totalTransitTime, false, true));
            // Halt Time
            addDataRow(true, dataRow, 'haltTime', getTimeDiffHours(totalIdleTime, false, true));
            // Scheduled Distance
            addDataRow(true, dataRow, 'scheduledDistance', round(scheduledDistance));
            // Actual Distance
            addDataRow(true, dataRow, 'travelledDistance', round(currentKm));
            //Pincode Hit
            addDataRow(true, dataRow, 'pincodeHit', pincodeHit);
            // Difference KM
            addDataRow(true, dataRow, 'difference', round(differenceKM));
            // Vehicle Tags
            addDataRow(true, dataRow, 'vehicleTags', tagNames);

            addDataRow(true, dataRow, 'jobPercentageDowntime', jobPercentDownTime);
            //Actual Unloading Address
            addDataRow(
                true,
                dataRow,
                'actualUnloadingAddress',
                get(
                    lastJobAnalyticPoint,
                    'actualUnloadingAddress',
                    lastRoutePointIndex > 0 &&
                        get(jobAnalytics[jobAnalyticsLength - 1], 'actualArrival') &&
                        get(jobAnalytics[jobAnalyticsLength - 1], 'status') !== 'MISSED'
                        ? get(routePoints[lastRoutePointIndex], 'address', '-')
                        : '-'
                )
            );

            map(jobConfigListingIdsArr, (ind) => {
                addDataRow(showSlabData, dataRow, ind, slabDataMap[ind] ? slabDataMap[ind] : 0);
            });

            // Threshold Time Spent
            // const thresholdTimeSpent = get(job, 'thresholdTimeSpent', false);
            // addDataRow(true, dataRow, 'thresholdTimeSpent', thresholdTimeSpent, 1, footerRow);

            // geofence status
            const geofenceStatus = get(job, 'geofenceStatus', false);
            addDataRow(true, dataRow, 'geofenceStatus', geofenceStatus, 1, footerRow);

            const vehicleEnteredPincode = get(
                getCustomFieldObjectFromKeyName(customFields, 'Vehicle entered pincode'),
                'value',
                'Vehicle not entered pincode'
            );
            addDataRow(true, dataRow, 'vehicleEnteredPincode', vehicleEnteredPincode);
            //Actual Unloading MapLink
            const lastPoint =
                get(jobAnalytics[jobAnalyticsLength - 1], 'actualArrival') &&
                get(jobAnalytics[jobAnalyticsLength - 1], 'status') !== 'MISSED'
                    ? routePoints[lastRoutePointIndex]
                    : [];

            if (
                (get(lastJobAnalyticPoint, 'actualUnloadingLongitude') &&
                    get(lastJobAnalyticPoint, 'actualUnloadingLatitude')) ||
                (get(lastPoint, 'latitude') && get(lastPoint, 'longitude'))
            ) {
                addDataRow(
                    true,
                    dataRow,
                    'actualUnloadingMapLink',
                    getGoogleMapLink(
                        get(lastJobAnalyticPoint, 'actualUnloadingLatitude'),
                        get(lastJobAnalyticPoint, 'actualUnloadingLongitude')
                    )
                );
            } else {
                addDataRow(true, dataRow, 'actualUnloadingMapLink', '-');
            }
            if (Math.abs(round(differenceKM)) > 10) {
                const deviationRow = clone(dataRow);
                deviationData.push(deviationRow);
            }

            if (showCustomFieldsInJobSummaryReport(loggedInUser)) {
                // Adding Custom Fields value in Row by Horizontal loop
                for (let j = 0; j < jobAnalytics.length; j++) {
                    let customFields = get(jobAnalytics[j], 'customFields', []);
                    map(customFields, (cf) => {
                        addDataRow(
                            true,
                            dataRow,
                            `${cf.field.id}-${cf.field.keyName}-${j}`,
                            isNaN(cf.value) ? `${cf.value}` : round(cf.value, 2)
                        );
                    });
                }
            }
            const remarks =
                dataRow.travelledDistance === 0
                    ? 'Device Disconnected'
                    : Math.abs(differenceKM) > 10
                    ? 'Route Deviation'
                    : 'Completed';
            // Remarks
            addDataRow(showRemarks, dataRow, 'remarks', remarks);
            const jobLink = `https://app.fleetx.io/dashboard/routes/jobs/${jobId}`;
            addDataRow(true, dataRow, 'jobLink', jobLink);
            addDataRow(true, dataRow, 'jobEndDate', endDate ? getReportTime(endDate) : '');
            addDataRow(extraColsAllowed, dataRow, 'deviationCount', deviationCount ? deviationCount : 0);
            addDataRow(
                extraColsAllowed,
                dataRow,
                'destinationDeviationType',
                destinationDeviationType ? destinationDeviationType : '-'
            );

            addDataRow(extraColsAllowed, dataRow, 'jobClosingReason', jobClosingReason);

            const pointCloseReason = get(last(jobAnalytics), 'pointCloseReason', '');
            addDataRow(extraColsAllowed, dataRow, 'pointCloseReason', pointCloseReason);

            const disconnectCountValue = deviceDisconnectCount > 0 ? 'True' : 'False';
            addDataRow(showDisconnectAlarm || extraColumnAllowed, dataRow, 'disconnectCount', disconnectCountValue);
            // aerial distance
            const aerialDistance = round(get(last(jobAnalytics), 'aerialDistance', 0), 2).toString() + ' km';
            addDataRow(extraColumnAllowed, dataRow, 'aerialDistance', aerialDistance, 1);
            addDataRow(
                true,
                dataRow,
                'distanceBetweenScheduledAndUnloadingPoint',
                round(get(last(jobAnalytics), 'aerialDistance', 0), 2)
            );

            addDataRow(extraColsAllowed, dataRow, 'riskLevel', get(RISK_LEVEL_DEF, riskLevel, riskLevel));
            addDataRow(reportLikeChanderiaAccount, dataRow, 'pinCode', pinCode);
            addDataRow(reportLikeChanderiaAccount, dataRow, 'cityName', cityName);
            addDataRow(reportLikeChanderiaAccount, dataRow, 'notes', notes);
            mainData.push(dataRow);
        }
        if (get(job, 'status', '-') === 'STARTED') {
            const runningDataRow = [];
            //Date
            addDataRow(true, runningDataRow, 'date', getMomentTime().format(DATE_FORMAT));
            //Delivery No
            addDataRow(true, runningDataRow, 'devliveryNo', get(job, 'name', '-'));
            // Vehicle No.
            addDataRow(true, runningDataRow, 'vehicleNo', get(vehicle, 'licensePlate', '-'));
            //Transporter
            addDataRow(
                true,
                runningDataRow,
                'transporter',
                get(job, 'transporter.name', '') ? get(job, 'transporter.name', '') : get(vehicle, 'group.name', '-')
            );
            //Group
            addDataRow(true, runningDataRow, 'groupName', get(job, 'vehicle.group.name', '-'));
            //State
            const destinationState = parseJobNotes(get(job, 'notes', ''), 'Destination State:');
            let finalState = destinationState;
            const stateArray = split(destinationState, '#');
            if (get(stateArray, 'length', 0)) {
                finalState = stateArray[0];
            }
            addDataRow(true, runningDataRow, 'state', finalState);
            //Warehouse Name
            //addDataRow(true, runningDataRow, 6, get(jobAnalytics[jobAnalyticsLength - 1], 'routePoint.address', '-')); // jobAnalytics[j].routePoint.address);
            // Sold to party
            addDataRow(true, runningDataRow, 'soldToParty', parseJobNotes(get(job, 'notes', ''), 'Sold to party :'));
            // Ship to party
            addDataRow(
                true,
                runningDataRow,
                'shipToParty',
                get(jobAnalytics[jobAnalyticsLength - 1], 'customer.name', '-')
            );
            addDataRow(
                true,
                runningDataRow,
                'invoiceNumber',
                get(jobAnalytics[jobAnalyticsLength - 1], 'invoiceNumber', '-')
            );
            // Scheduled destination
            addDataRow(
                true,
                runningDataRow,
                'scheduledDestination',
                get(jobAnalytics[jobAnalyticsLength - 1], 'routePoint.address', '-')
            );
            // PGI Date
            addDataRow(
                true,
                runningDataRow,
                'pgiDate',
                get(jobAnalytics, '0.scheduledDeparture')
                    ? getMomentTime(get(jobAnalytics, '0.scheduledDeparture')).format(DATE_FORMAT)
                    : '-'
            );
            // Date/ Time Of Dispatch
            addDataRow(
                true,
                runningDataRow,
                'dateOfDispatch',
                get(jobAnalytics, '0.scheduledDeparture')
                    ? getMomentTime(get(jobAnalytics, '0.scheduledDeparture')).format(DATE_FORMAT_TIME_24_REPORTS)
                    : '-'
            );
            addDataRow(
                true,
                runningDataRow,
                'jobStartDate',
                get(job, 'startDate') ? getMomentTime(get(job, 'startDate')).format(DATE_FORMAT_TIME_24_REPORTS) : '-'
            );
            // Cust Entry Date & time
            // addDataRow(
            //     true,
            //     runningDataRow,
            //     12,
            //     get(jobAnalytics[jobAnalyticsLength - 1], 'actualArrival', '-') !== '-'
            //         ? getMomentTime(get(jobAnalytics[jobAnalyticsLength - 1], 'actualArrival', '-')).format(
            //               DATE_FORMAT_TIME_24_REPORTS
            //           )
            //         : '-'
            // );
            // // Customer Exit Time
            // addDataRow(
            //     true,
            //     runningDataRow,
            //     13,
            //     get(jobAnalytics[jobAnalyticsLength - 1], 'actualDeparture', '-') !== '-'
            //         ? getMomentTime(get(jobAnalytics[jobAnalyticsLength - 1], 'actualDeparture', '-')).format(
            //               DATE_FORMAT_TIME_24_REPORTS
            //           )
            //         : '-'
            // );
            //Source Plant
            addDataRow(true, runningDataRow, 'sourcePlant', get(customer, 'name', '-'));
            // Segment
            addDataRow(true, runningDataRow, 'segment', get(job, 'segment', '-'));
            // Present Location
            const vehicleLive = get(vehicle, 'id') && find(liveData, { vehicleId: vehicle.id });
            addDataRow(true, runningDataRow, 'presentLocation', vehicleLive ? vehicleLive.address : '-');

            // Distance Covered
            addDataRow(true, runningDataRow, 'distanceCovered', round(currentKm));

            // Scheduled Distance
            addDataRow(true, runningDataRow, 'scheduledDistance', round(scheduledDistance));

            // Vehicle Tags
            addDataRow(true, runningDataRow, 'vehicleTags', tagNames);

            //Idle Time
            addDataRow(true, runningDataRow, 'haltTime', getTimeDiffHours(totalIdleTime, false, true));
            //ETA
            //addDataRow(true, runningDataRow, 17, getETA(predictedDelay, job));

            //Location HyperLink
            addDataRow(true, runningDataRow, 'locationHyperlink', liveLocationLink);
            const jobLink = `https://app.fleetx.io/dashboard/routes/jobs/${jobId}`;
            addDataRow(true, runningDataRow, 'jobLink', jobLink);

            if (showCustomFieldsInJobSummaryReport(loggedInUser)) {
                // Adding Custom Fields value in Row by Horizontal loop
                for (let j = 0; j < jobAnalytics.length; j++) {
                    let customFields = get(jobAnalytics[j], 'customFields', []);
                    map(customFields, (cf) => {
                        addDataRow(
                            true,
                            runningDataRow,
                            `${cf.field.id}-${cf.field.keyName}-${j}`,
                            isNaN(cf.value) ? `${cf.value}` : round(cf.value, 2)
                        );
                    });
                }
            }

            // Remarks
            addDataRow(showRemarks, runningDataRow, 'remarks', 'Running');

            runningData.push(runningDataRow);
            const haltLimit = getHaltLimitForAccount(loggedInUser);
            if (moment.duration(totalIdleTime).asHours() > haltLimit) {
                // Remarks
                addDataRow(showRemarks, runningDataRow, 'remarks', `Halt more than ${haltLimit}hrs`);
                idleData.push(runningDataRow);
            }
        }
    }

    map(allDisconnected, (vehicle) => {
        const disconnectedDataRow = [];
        //Date
        addDataRow(true, disconnectedDataRow, 'date', getMomentTime().format(DATE_FORMAT));
        addDataRow(true, disconnectedDataRow, 'vehicleNo', get(vehicle, 'vehicleNumber', '-'));
        addDataRow(true, disconnectedDataRow, 'lastReported', getHumanizeTime(get(vehicle, 'lastStatusTime')));
        addDataRow(
            true,
            disconnectedDataRow,
            'currentVehicleStatus',
            get(vehicle, 'currentStatus') === VEHICLE_STATUS.DISCONNECTED ? 'Disconnected' : 'Not Online'
        );

        disconnectedData.push(disconnectedDataRow);
    });

    set(allHeaders, 0, mainHeaders);
    set(allHeaders, 1, mainHeaders);
    set(allHeaders, 2, idleHeaders);
    set(allHeaders, 3, idleHeaders);
    set(allHeaders, 4, disconnectedHeaders);
    set(allData, 0, mainData);
    set(allData, 1, deviationData);
    set(allData, 2, runningData);
    set(allData, 3, idleData);
    set(allData, 4, disconnectedData);
    allNoOfCols.push(noOfColsMain);
    allNoOfCols.push(noOfColsIdle);

    const headerStyles = {
        font: { name: 'Arial', family: 2, size: 12, bold: true },
        alignment: { wrapText: true, vertical: 'middle', horizontal: 'center' },
        // fill: {
        //     type: 'pattern',
        //     pattern: 'solid',
        //     fgColor: { argb: '99CCFF' },
        // },
    };

    startReportCreation(
        ORIENTATION.LANDSCAPE,
        allHeaders,
        allData,
        reportFormat,
        ['Main Report', 'Deviation Report', 'Running Report', 'Idle Report', 'Disconnected Report'],
        moment(startDate),
        moment(endDate),
        vehicleNumber,
        reportName,
        allNoOfCols,
        '',
        '',
        '',
        '',
        2,
        {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
        },
        false,
        '',
        headerStyles,
        false
    );
}

export async function triggerWaitingJobReportDownload(accesstoken, reportFormat, filterConfig, startDate, endDate) {
    const includeWaitingToLoad = get(filterConfig, 'includeWaitingToLoad', false);
    const includeWaitingToUnload = get(filterConfig, 'includeWaitingToUnload', false);
    let waitingType = null;

    if (includeWaitingToLoad && includeWaitingToUnload) {
        waitingType = null;
    } else if (includeWaitingToLoad) {
        waitingType = VEHICLE_JOB_STATE.WAITING_TO_LOAD;
    } else if (includeWaitingToUnload) {
        waitingType = VEHICLE_JOB_STATE.WAITING_TO_UNLOAD;
    }
    const waitingJobs = get(await fetchWaitingJobs(accesstoken, startDate, endDate, waitingType).promise, 'data');

    const reportName = createReportFilename(reportFormat, 'Job', null, null, startDate, endDate, null, null, null);

    let headers = [],
        data = [];
    let noOfCols = 0;
    noOfCols += addHeader(true, headers, 'jobName', 'Job Name', 150, null);
    // noOfCols += addHeader(true, headers, 'vehicleName', 'Vehicle Name', 150, null);
    noOfCols += addHeader(true, headers, 'vehicleNumber', 'Vehicle Number', 150, null);
    noOfCols += addHeader(true, headers, 'jobStatus', 'Job Status', 150, null);
    noOfCols += addHeader(true, headers, 'vehicleStatus', 'Vehicle Status', 150, null);
    noOfCols += addHeader(true, headers, 'waitingStartDate', 'Waiting Start Time', 150, null);
    noOfCols += addHeader(true, headers, 'hoursSinceWaiting', 'Waiting Since', 150, null);

    forEach(waitingJobs, (job) => {
        const lastJA = last(job.jobAnalytics);
        const waitingStartDate = getMomentTime(get(lastJA, 'waitingStartDate'));
        const duration = getMomentTime().diff(waitingStartDate);
        data.push({
            jobName: `${job.name}`,
            vehicleNumber: `${job.licensePlate}`,
            jobStatus: `${job.status}`,
            vehicleStatus: `${get(lastJA, 'currentVehicleState', '')}`,
            waitingStartDate: getReportTime(waitingStartDate),
            hoursSinceWaiting: `${getTimeDiffHours(duration)}`,
        });
    });

    data.push({
        jobName: `TOTAL ${data.length}`,
        vehicleNumber: ``,
        jobStatus: ``,
        vehicleStatus: ``,
        waitingStartDate: '',
    });

    startReportCreation(
        ORIENTATION.LANDSCAPE,
        headers,
        data,
        reportFormat,
        'Waiting Job Report',
        startDate,
        endDate,
        null,
        reportName,
        noOfCols
    );
}

export async function triggerJobUpdateHistoryReportDownload(
    accesstoken,
    reportFormat,
    vehicleId,
    groupId,
    startDate,
    endDate,
    currentStatusFilter,
    pointStatusFilter,
    onlyPointsFilter,
    routeIdFilter,
    notesFilter,
    customerId,
    jobSize
) {
    const jobResult = get(
        await fetchJobListNewV2(accesstoken, {
            vehicleId: vehicleId,
            groupId: groupId,
            from: startDate,
            to: endDate,
            sortByDateAsc: false,
            currentStatusFilter: currentStatusFilter,
            pointStatusFilter: pointStatusFilter,
            routeIdFilter: routeIdFilter,
            notesFilter: notesFilter,
            onlyPointsFilter: onlyPointsFilter,
            selectedCustomerId: customerId,
            page: 1,
            size: jobSize,
        }).promise,
        'data.content'
    );
    let jobResultObj = {};
    forEach(jobResult, (el) => {
        jobResultObj[el.id] = el;
    });

    let jobIds = map(jobResult, (el) => {
        return el.id;
    });
    jobIds = join(jobIds, ',');
    const jobUpdateData = get(await fetchJobAuditHistoryById(accesstoken, jobIds, 'JobAnalytics').promise, 'data');
    const userNameData = get(await fetchUserListMiniV2(accesstoken, null, null).promise, 'data');
    if (userNameData.length) {
        forEach(jobUpdateData, (el) => {
            const user = filter(userNameData, (user) => get(el, 'createdBy') === user[0]);
            const name = `${get(user, '[0].[1]', '')} ${get(user, '[0].[2]') ? get(user, '[0].[2]') : ''}`;
            set(el, 'createdBy', name);
        });
    }
    const reportName = createReportFilename(
        reportFormat,
        'JobUpdateHistory',
        null,
        null,
        startDate,
        endDate,
        null,
        null,
        null
    );

    let headers = [],
        data = [];
    let noOfCols = 0;
    noOfCols += addHeader(true, headers, 'jobName', 'Job Name', 150, null);
    noOfCols += addHeader(true, headers, 'vehicle', 'Vehicle', 150, null);
    noOfCols += addHeader(true, headers, 'oldStatus', 'Old Status', 150, null);
    noOfCols += addHeader(true, headers, 'newStatus', 'New Status', 150, null);
    noOfCols += addHeader(true, headers, 'group', 'Group', 150, null);
    noOfCols += addHeader(true, headers, 'username', 'Username', 150, null);
    noOfCols += addHeader(true, headers, 'time', 'Time', 150, null);

    forEach(jobUpdateData, (jobUpdate) => {
        let job = jobResultObj[jobUpdate.sourceId];
        data.push({
            jobName: `${get(job, 'name', '-')}`,
            vehicle: `${get(job, 'vehicle.licensePlate', '-')}`,
            oldStatus: `${get(jobUpdate, 'source.status', '-')}`,
            newStatus: `${get(jobUpdate, 'revision.status', '-')}`,
            group: `${get(job, 'vehicle.group.name', '-')}`,
            username: `${get(jobUpdate, 'createdBy', '-')}`,
            time: `${getHumanizeTime(get(jobUpdate, 'createdDate', '-'))}`,
        });
    });
    data.push({
        jobName: `TOTAL ${data.length}`,
        vehicleNumber: ``,
        oldStatus: ``,
        newStatus: ``,
        group: ``,
        username: '',
        time: '',
    });

    startReportCreation(
        ORIENTATION.LANDSCAPE,
        headers,
        data,
        reportFormat,
        'Job Update History',
        '',
        '',
        null,
        reportName,
        noOfCols
    );
}

const fetchAndCombineData = async (
    accesstoken,
    vehicleId,
    groupId,
    startDate,
    endDate,
    sixth,
    currentStatusFilter,
    pointStatusFilter,
    routeIdFilter,
    notesFilter,
    onlyPointsFilter,
    customerId,
    page,
    totalDataSize,
    rfidMismatch,
    sourceFlag,
    destinationFlag,
    riskLevel,
    includeJobExpenses,
    includeCompleteDuring,
    weighmentJob,
    movementType,
    params,
    allData,
    size
) => {
    const jsss = await fetchJobsReport(
        accesstoken,
        vehicleId,
        groupId,
        startDate,
        endDate,
        false,
        currentStatusFilter,
        pointStatusFilter,
        routeIdFilter,
        notesFilter,
        onlyPointsFilter,
        customerId,
        page,
        size,
        rfidMismatch,
        sourceFlag,
        destinationFlag,
        riskLevel,
        includeJobExpenses,
        includeCompleteDuring,
        weighmentJob,
        movementType,
        params
    ).promise;

    const data = jsss?.data?.content || [];
    allData = [...allData, ...data];
    if (page * 1000 > totalDataSize) {
        return allData;
    } else {
        page++;
        return await fetchAndCombineData(
            accesstoken,
            vehicleId,
            groupId,
            startDate,
            endDate,
            false,
            currentStatusFilter,
            pointStatusFilter,
            routeIdFilter,
            notesFilter,
            onlyPointsFilter,
            customerId,
            page,
            totalDataSize,
            rfidMismatch,
            sourceFlag,
            destinationFlag,
            riskLevel,
            includeJobExpenses,
            includeCompleteDuring,
            weighmentJob,
            movementType,
            params,
            allData,
            1000
        );
    }
};

export async function triggerJobReportDownload(
    accesstoken,
    loggedInUser,
    reportFormat,
    filterConfig,
    totalDataSize,
    vehicleId,
    groupId,
    startDate,
    endDate,
    currentStatusFilter,
    pointStatusFilter,
    routeIdFilter,
    notesFilter,
    onlyPointsFilter,
    groupName,
    vehicleNumber,
    customer,
    customerId,
    isExpressRoadways,
    tags,
    addressBookAsMap,
    rfidMismatch,
    sourceFlag,
    destinationFlag,
    riskLevel,
    returnData,
    isCustomReportSelected,
    driverId,
    groupList,
    invoiceFilter,
    orderNumber,
    stationaryNumber,
    includeCompleteDuring,
    userListMiniAsMap,
    isAryanTransReport = false,
    weighmentJob,
    movementType,
    extraParams
) {
    if (!totalDataSize) {
        return;
    }

    const isCSV = reportFormat === REPORT_FORMAT.CSV;
    let jobResult;
    const includeJobExpenses = get(filterConfig, 'includeJobExpenses', false);
    const includeEcommerceJobs = get(filterConfig, 'includeEcommerceJobs', false);
    const isRouteDeviationCount = get(filterConfig, 'includeDeviationKm', false);
    try {
        jobResult = await fetchAndCombineData(
            accesstoken,
            vehicleId,
            groupId,
            startDate,
            endDate,
            false,
            currentStatusFilter,
            pointStatusFilter,
            routeIdFilter,
            notesFilter,
            onlyPointsFilter,
            customerId,
            1,
            totalDataSize,
            rfidMismatch,
            sourceFlag,
            destinationFlag,
            riskLevel,
            includeJobExpenses,
            includeCompleteDuring,
            weighmentJob,
            movementType,
            {
                clientContractChartJob: includeEcommerceJobs,
                isRouteDeviationCount: isRouteDeviationCount,
                ...extraParams,
            },
            [],
            1000
        );
    } catch (e) {
        window.alert(handleError(e));
        console.log(e);
    }

    const reportName = createReportFilename(
        reportFormat,
        'Job',
        vehicleNumber,
        groupName,
        moment(startDate),
        moment(endDate),
        null,
        null,
        customer
    );

    let liveData;

    const includeCurrentLocation = get(filterConfig, 'includeCurrentLocation', true);
    const includeCurrentStatus = get(filterConfig, 'includeCurrentStatus', true);
    const includeCustomField = get(filterConfig, 'includeCustomField', true);
    const includeProfitAndLoss = get(filterConfig, 'includeProfitAndLoss', false);

    const jobs = jobResult || [];
    let jobIdsArray = [];

    if (includeProfitAndLoss) {
        jobIdsArray = map(jobs, (job) => job.id);
    }

    if (includeCurrentLocation || includeCurrentStatus) {
        const idsArr = [];
        forEach(jobs, function (job) {
            if (job.status !== JOB_STATUS_VALUES.COMPLETED && job.vehicle) {
                if (get(job, 'vehicle.id')) {
                    idsArr.push(job.vehicle.id);
                }
            }
        });

        try {
            if (idsArr.length > 0) {
                if (isMaerskAccount()) {
                    const liveResult = await fetchAnalyticsLive(accesstoken).promise;
                    liveData = get(liveResult, 'data.vehicles');
                } else {
                    const liveResult = await fetchLiveVehicleData(accesstoken, join(uniq(idsArr), ',')).promise;
                    liveData = liveResult.data;
                }
            }
        } catch (e) {
            window.alert(handleError(e));
            console.log(e);
        }
    }

    let jobsTransactionExpenses = {};
    let jobsTransactionExpensesUniqueCols = new Set();

    if (isCSV && includeProfitAndLoss && !isEmpty(jobIdsArray)) {
        // fetch job transaction expenses
        try {
            const result = await fetchTransactionExpensesForJobs(accesstoken, jobIdsArray).promise;
            jobsTransactionExpenses = reduce(
                get(result, 'data', []),
                (acc, item) => {
                    if (!isEmpty(item.expenses)) {
                        acc[item.jobId] = item.expenses;
                        // collect keys
                        forEach(item.expenses, (e) => {
                            if (!jobsTransactionExpensesUniqueCols.has(e.expenseType)) {
                                jobsTransactionExpensesUniqueCols.add(e.expenseType);
                            }
                        });
                    }

                    return acc;
                },
                {}
            );
            jobsTransactionExpensesUniqueCols = [...jobsTransactionExpensesUniqueCols];
        } catch (err) {}
    }

    let allCustomFieldsPresent = [];

    if (includeCustomField) {
        forEach(jobs, function (job) {
            if (job.customFields) {
                for (let i = 0; i < job.customFields.length; i++) {
                    allCustomFieldsPresent.push(get(job.customFields[i], 'field.id'));
                }
            }

            const { jobAnalytics } = job;
            if (jobAnalytics.length > 1) {
                for (let i = 0; i < jobAnalytics.length; i++) {
                    if (jobAnalytics[i].customFields) {
                        for (let j = 0; j < jobAnalytics[i].customFields.length; j++) {
                            allCustomFieldsPresent.push(get(jobAnalytics[i].customFields[j], 'field.id'));
                        }
                    }
                }
            }
        });
    }
    allCustomFieldsPresent = uniq(allCustomFieldsPresent);
    allCustomFieldsPresent = await getCustomFieldObjectFromIds(allCustomFieldsPresent, accesstoken);
    const isCustomGroupConfigEnabled = isCustomGroupConfigEnabledForAccount(loggedInUser);
    if (isHorizontalReportEnabled()) {
        triggerHorizontalJobReportDownload(
            accesstoken,
            filterConfig,
            jobResult || [],
            reportFormat,
            liveData,
            startDate,
            endDate,
            vehicleNumber,
            reportName,
            loggedInUser,
            addressBookAsMap,
            isCustomGroupConfigEnabled && isCustomReportSelected,
            {
                jobsTransactionExpenses,
                jobsTransactionExpensesUniqueCols,
            },
            tags,
            groupList,
            userListMiniAsMap,
            isAryanTransReport,
            weighmentJob,
            movementType
        );
    } else {
        const isjobPercentDownTimeOpen = isjobPercentDownTimeOpenForAccount(loggedInUser) || showJobDowntime();
        let headers = [];
        const footerRow = {};
        const includeJobDistance = get(filterConfig, 'includeJobDistance', true);
        const includePercentJobCompleted = get(filterConfig, 'includePercentJobCompleted', true);
        const includePredictedDelay = get(filterConfig, 'includePredictedDelay', true);
        const includeJobNotes = get(filterConfig, 'includeJobNotes', true);
        const includeTat = get(filterConfig, 'includeTat', true);
        const includeNotes = get(filterConfig, 'includeNotes', true);
        const includeWeight = get(filterConfig, 'includeWeight', true);
        const includeDocketNumber = get(filterConfig, 'includeDocketNumber', true);
        const includeInvoiceNumber = get(filterConfig, 'includeInvoiceNumber', true);
        const includePackageCount = get(filterConfig, 'includePackageCount', true);
        const includeVehicleLoad = get(filterConfig, 'includeVehicleLoad', true);
        const includeFreightFare = get(filterConfig, 'includeFreightFare', true);
        const includeFreightRate = get(filterConfig, 'includeFreightRate', true);
        const includeProjectName = get(filterConfig, 'includeProjectName', true);
        const includeTags = get(filterConfig, 'includeTags', true);
        const includeJobCreationDate = get(filterConfig, 'includeJobCreationDate', true);
        const includeOpeningOdo = get(filterConfig, 'includeOpeningOdo', true);
        const includeClosingOdo = get(filterConfig, 'includeClosingOdo', true);
        const includeCustomField = get(filterConfig, 'includeCustomField', true);
        const includeVendor = get(filterConfig, 'includeVendor', true);
        const includeTransporter = get(filterConfig, 'includeTransporter', true);
        const includeRFIDStatus = get(filterConfig, 'includeRFIDStatus', false);

        const includeDeviationCount = get(filterConfig, 'includeDeviationCount', true);
        const jobs = jobResult.data.content;

        let noOfCols = 0;
        noOfCols += addHeader(true, headers, 1, 'Job Name', 150, footerRow);
        noOfCols += addHeader(true, headers, 2, 'Consignor', 150, footerRow);
        noOfCols += addHeader(true, headers, 3, 'Vehicle Name', 150, footerRow);
        noOfCols += addHeader(true, headers, 4, 'Vehicle Number', 150, footerRow);
        noOfCols += addHeader(true, headers, 5, 'Driver Name', 150, footerRow);
        noOfCols += addHeader(true, headers, 6, 'Driver Number', 150, footerRow);
        noOfCols += addHeader(true, headers, 7, getStartDateColumnName(loggedInUser), 150, footerRow);
        noOfCols += addHeader(true, headers, 8, 'Origin', 150, footerRow);
        noOfCols += addHeader(true, headers, 9, 'Destination', 150, footerRow);
        noOfCols += addHeader(
            true,
            headers,
            'scheduledArrivalTimeOrigin',
            'Scheduled Arrival At Origin',
            150,
            footerRow
        );
        noOfCols += addHeader(true, headers, 10, 'Arrival Time At Origin', 150, footerRow);
        noOfCols += addHeader(true, headers, 11, 'Departure Time From Origin', 150, footerRow);
        noOfCols += addHeader(true, headers, 12, 'Loading Duration', 150, footerRow);
        noOfCols += addHeader(true, headers, 13, 'Arrival Time At Destination', 150, footerRow);
        noOfCols += addHeader(true, headers, 14, 'Departure Time From Destination', 150, footerRow);
        noOfCols += addHeader(true, headers, 15, 'Unloading Duration', 150, footerRow);
        noOfCols += addHeader(true, headers, 16, 'Job Status', 150, footerRow);
        noOfCols += addHeader(true, headers, 'vehicleStatus', 'Vehicle Status', 150, footerRow);
        noOfCols += addHeader(true, headers, 'scheduledDistance', 'Scheduled Distance', 150, footerRow);
        noOfCols += addHeader(includeJobDistance, headers, 'jobDistance', 'Job Distance', 150, footerRow);
        noOfCols += addHeader(true, headers, 'delay', 'Delay', 150, footerRow);
        noOfCols += addHeader(true, headers, 'duration', 'Duration', 150, footerRow);
        noOfCols += addHeader(true, headers, 'stopTime', 'Stop Time', 150, footerRow);

        noOfCols += addHeader(includeCurrentLocation, headers, 'currentLocation', 'Current Location ', 450, footerRow);
        noOfCols += addHeader(includeCurrentStatus, headers, 'currentStatus', 'Current Status', 150, footerRow);
        noOfCols += addHeader(
            includePercentJobCompleted,
            headers,
            'percentJobCompleted',
            '% Job Completed',
            150,
            footerRow
        );
        noOfCols += addHeader(isjobPercentDownTimeOpen, headers, 'percentDownTime', '% Job Downtime', 150, footerRow);
        noOfCols += addHeader(includePredictedDelay, headers, 'predictedDelay', 'Predicted Delay', 150, footerRow);
        noOfCols += addHeader(
            includePredictedDelay,
            headers,
            'expectedTimeOfArrival',
            'Expected Time Of Arrival',
            150,
            footerRow
        );
        noOfCols += addHeader(includeProjectName, headers, 'projectName', 'Project Name', 150, footerRow);
        noOfCols += addHeader(includeFreightRate, headers, 'freightRate', 'Freight Rate', 150, footerRow);
        noOfCols += addHeader(includeVendor, headers, 'vendor', 'Vendor Name', 150, footerRow);
        noOfCols += addHeader(includeTransporter, headers, 'transporter', 'Transporter Name', 150, footerRow);
        noOfCols += addHeader(includeJobNotes, headers, 'jobNotes', 'Job Notes', 150, footerRow);
        noOfCols += addHeader(includeTat, headers, 'tat', 'TAT', 150, footerRow);
        noOfCols += addHeader(includeTat, headers, 'actualTAT', 'Actual TAT', 150, footerRow);
        noOfCols += addHeader(includeNotes, headers, 'notes', 'Notes', 150, footerRow);
        noOfCols += addHeader(includeWeight, headers, 'weight', 'Weight', 150, footerRow);
        noOfCols += addHeader(includeDocketNumber, headers, 'docketNumber', 'Docket Number', 150, footerRow);
        noOfCols += addHeader(includeInvoiceNumber, headers, 'invoiceNumber', 'Invoice Number', 150, footerRow);
        noOfCols += addHeader(includePackageCount, headers, 'packageCount', 'Package Count', 150, footerRow);
        noOfCols += addHeader(includeVehicleLoad, headers, 'vehicleState', 'Vehicle State', 150, footerRow);
        noOfCols += addHeader(includeFreightFare, headers, 'freightFare', 'Freight Fare', 150, footerRow);
        noOfCols += addHeader(includeEcommerceJobs, headers, 'ecommerce', 'Client Contract Chart', null);
        noOfCols += addHeader(includeEcommerceJobs, headers, 'isEcommerceDelivery', 'Is Ecommerce Delivery', null);

        if (includeProfitAndLoss && isCSV) {
            forEach(jobsTransactionExpensesUniqueCols, (colKey) => {
                if (colKey === 'Fuel') {
                    noOfCols += addHeader(true, headers, 'expenses-fuelQuantity-DR', 'Fuel Quantity(DR)', 0, null);
                    noOfCols += addHeader(true, headers, `expense-${colKey}-DR`, 'Fuel Amount(Rs.)(DR.)', 0, null);

                    noOfCols += addHeader(true, headers, 'expenses-fuelQuantity-CR', 'Fuel Quantity(CR)', 0, null);
                    noOfCols += addHeader(true, headers, `expense-${colKey}-CR`, 'Fuel Amount(Rs.)(CR.)', 0, null);
                } else {
                    noOfCols += addHeader(true, headers, `expense-${colKey}-DR`, `${colKey}(Rs.)(DR.)`, 0, null);
                    noOfCols += addHeader(true, headers, `expense-${colKey}-CR`, `${colKey}(Rs.)(CR.)`, 0, null);
                }
            });

            // more cols
            noOfCols += addHeader(true, headers, 'expenses-advance', 'Advance(Rs.)', 0, null);
            noOfCols += addHeader(true, headers, 'expenses-totalExpenses', 'Total Expenses(Rs.)', 0, null);
            noOfCols += addHeader(true, headers, 'expenses-balance', 'Balance(Rs.)', 0, null);
            noOfCols += addHeader(true, headers, 'expenses-profit', 'Profit(Rs.)', 0, null);
        }

        if (isCSV) {
            noOfCols += addHeader(includeJobCreationDate, headers, 'createdDate', 'Job Created On', 0, null);

            noOfCols += addHeader(includeTags, headers, 'vehicleTags', 'Vehicle Tags', 0, null);
            if (isExpressRoadways) {
                noOfCols += addHeader(includeTags, headers, 'startAddressBranchTags', 'Start Branch', 0, null);
                noOfCols += addHeader(includeTags, headers, 'startAddressStationTags', 'Start Station', 0, null);

                noOfCols += addHeader(includeTags, headers, 'endAddressBranchTags', 'End Branch', 0, null);
                noOfCols += addHeader(includeTags, headers, 'endAddressStationTags', 'End  Station', 0, null);
            } else {
                noOfCols += addHeader(includeTags, headers, 'startAddressTags', 'Start Address Tags', 0, null);
                noOfCols += addHeader(includeTags, headers, 'endAddressTags', 'End Address Tags', 0, null);
            }

            noOfCols += addHeader(includeOpeningOdo, headers, 'openingOdo', 'Opening Odometer', 0, null);
            noOfCols += addHeader(includeClosingOdo, headers, 'closingOdo', 'Closing Odometer', 0, null);
            noOfCols += addHeader(includeDeviationCount, headers, 'deviationCount', 'Deviation Count', 0, null);
            noOfCols += addHeader(includeRFIDStatus, headers, 'RFIDStatus', 'RFID Present', 150, footerRow);
            noOfCols += addHeader(true, headers, 'stationaryNumber', 'Stationary Number', 150, footerRow);

            if (includeCustomField && allCustomFieldsPresent) {
                map(allCustomFieldsPresent, (cf) => {
                    noOfCols += addHeader(
                        includeCustomField,
                        headers,
                        `${cf.id}-${cf.keyName}`,
                        `${cf.keyName}`,
                        0,
                        null
                    );
                });
            }
        }

        if (isColCustomOrderingForAccount(loggedInUser)) {
            headers = generateCustomOrderingForHeader(headers, expressColJobReportMapping);
        }

        const data = [];

        const length = jobs.length;

        for (let i = 0; i < length; i++) {
            const job = jobs[i];
            const {
                jobAnalytics,
                name,
                currentFuel,
                currentKm,
                notes,
                predictedDelay,
                route,
                startDate,
                endDate,
                status,
                vehicle,
                customer,
                vehicleLoad,
                totalTransitTime,
                runningDuration,
                openingOdo = '',
                closingOdo = '',
                deviationCount = 0,
                id,
                jobImages,
                percentDownTime = 0,
                jobBudget,
                stationaryNumber,
                deviceSupplier = '',
                jobLink,
                simUptimePercentage,
                erpJob,
            } = job;
            const isRFIDPresent = getRFIDStatus(jobImages);

            const jobAnalyticsLength = jobAnalytics.length;

            const routePoints = get(route, 'routePoints', '[]');
            const lastRoutePointIndex = routePoints.length - 1;

            const scheduledDistance =
                lastRoutePointIndex > 0 ? get(routePoints[lastRoutePointIndex], 'distance', 0) : 0;

            let jobPercentDownTime;
            if (status === 'COMPLETED' && jobAnalytics.length === 2) {
                const firstPointDepartTime = jobAnalytics[0].actualDeparture;
                const lastPointArrivalTime = jobAnalytics[jobAnalytics.length - 1].actualArrival;
                const diff = lastPointArrivalTime - firstPointDepartTime;
                if (percentDownTime > 0 && diff && percentDownTime < diff) {
                    jobPercentDownTime = (percentDownTime / diff) * 100;
                }
            }

            if (jobAnalyticsLength === 1) {
                const dataRow = { id: id };
                addDataRow(true, dataRow, 1, name);
                addDataRow(true, dataRow, 2, customer ? customer.name : '');
                addDataRow(true, dataRow, 3, get(vehicle, 'name', '-'));
                addDataRow(true, dataRow, 4, get(vehicle, 'licensePlate', '-'));
                addDataRow(true, dataRow, 5, get(vehicle, 'activeAssignment.user.name', '-'));
                addDataRow(true, dataRow, 6, get(vehicle, 'activeAssignment.user.phoneNumber', '-'));
                addDataRow(true, dataRow, 7, getStartDateFormatType(loggedInUser, startDate));
                addDataRow(true, dataRow, 8, get(jobAnalytics[0], 'routePoint.address'));
                addDataRow(true, dataRow, 9, '');
                addDataRow(
                    includeEcommerceJobs,
                    dataRow,
                    'ecommerce',
                    get(erpJob, 'clientContractChartDto.contractNumber')
                );
                addDataRow(includeEcommerceJobs, dataRow, 'isEcommerceDelivery', get(erpJob, 'id') ? 'Yes' : 'No');
                addDataRow(
                    true,
                    dataRow,
                    10,
                    jobAnalytics[0].actualArrival ? getReportTime(jobAnalytics[0].actualArrival) : ''
                );
                addDataRow(
                    true,
                    dataRow,
                    11,
                    jobAnalytics[0].actualDeparture ? getReportTime(jobAnalytics[0].actualDeparture) : ''
                );
                if (jobAnalytics[0].vehicleState && jobAnalytics[0].vehicleState === 'UNLOADED') {
                    addDataRow(true, dataRow, 12, '');
                } else {
                    addDataRow(
                        true,
                        dataRow,
                        12,
                        jobAnalytics[0].actualDeparture && jobAnalytics[0].actualArrival
                            ? getTimeDiff(
                                  Math.abs(jobAnalytics[0].actualDeparture - jobAnalytics[0].actualArrival),
                                  false,
                                  true
                              )
                            : ''
                    );
                }
                addDataRow(true, dataRow, 13, '');
                addDataRow(true, dataRow, 14, '');
                addDataRow(true, dataRow, 15, '');
                addDataRow(true, dataRow, 16, getJobStatus(status, loggedInUser));
                addDataRow(true, dataRow, 'vehicleStatus', findvehicleStateFromId(job.jobStatus, loggedInUser));
                addDataRow(true, dataRow, 'scheduledDistance', `${round(scheduledDistance)} km`);
                addDataRow(true, dataRow, 'delay', '');
                const time = jobAnalytics[0].actualArrival
                    ? jobAnalytics[0].actualArrival
                    : jobAnalytics[0].actualDeparture;
                addDataRow(true, dataRow, 'duration', time ? getTimeDiff(moment().valueOf() - time, false, true) : '');
                addDataRow(
                    true,
                    dataRow,
                    'stopTime',
                    totalTransitTime > 0 ? getTimeDiffHours(totalTransitTime - runningDuration) : ''
                );
                const vehicleLive = get(vehicle, 'id') && find(liveData, { vehicleId: vehicle.id });
                addDataRow(
                    includeCurrentLocation,
                    dataRow,
                    'currentLocation',
                    vehicleLive ? parseAddress(vehicleLive.address) : ''
                );
                addDataRow(
                    includeCurrentStatus,
                    dataRow,
                    'currentStatus',
                    vehicleLive ? VEHICLE_STATUS_NAME_JOB[vehicleLive.currentStatus] : ''
                );
                addDataRow(includeJobDistance, dataRow, 'jobDistance', `${getJobDistanceRoundedValue(currentKm)} km`);
                addDataRow(includePercentJobCompleted, dataRow, 'percentJobCompleted', '');
                addDataRow(isjobPercentDownTimeOpen, dataRow, 'percentDownTime', '');
                addDataRow(includePredictedDelay, dataRow, 'predictedDelay', '');
                addDataRow(includePredictedDelay, dataRow, 'expectedTimeOfArrival', '');
                addDataRow(includeJobNotes, dataRow, 'jobNotes', notes ? notes : '');
                addDataRow(includeTat, dataRow, 'tat', '');
                addDataRow(includeTat, dataRow, 'actualTAT', '');
                addDataRow(includeNotes, dataRow, 'notes', '');
                addDataRow(includeWeight, dataRow, 'weight', '');
                addDataRow(includeDocketNumber, dataRow, 'docketNumber', '');
                addDataRow(includeInvoiceNumber, dataRow, 'invoiceNumber', '');
                addDataRow(includePackageCount, dataRow, 'packageCount', '');
                addDataRow(
                    includeVehicleLoad,
                    dataRow,
                    'vehicleState',
                    vehicleLoad ? getVehicleStatusUIText(vehicleLoad) : ''
                );
                addDataRow(includeFreightFare, dataRow, 'freightFare', '');
                if (isCSV) {
                    addDataRow(includeJobCreationDate, dataRow, 'createdDate', getReportTime(job.createdDate));
                    addDataRow(includeOpeningOdo, dataRow, 'openingOdo', openingOdo);
                    addDataRow(includeClosingOdo, dataRow, 'closingOdo', closingOdo);
                    addDataRow(includeRFIDStatus, dataRow, 'RFIDStatus', isRFIDPresent ? 'Yes' : 'No');
                }

                if (includeTags && isCSV) {
                    //vehicle tags
                    let attachedTags = get(job, 'vehicle.tagIdsList', []);
                    let tagNames = getTagNamesList(tags, attachedTags).join(',');
                    addDataRow(includeTags, dataRow, 'vehicleTags', tagNames);

                    let startAddressId = get(first(job.jobAnalytics), 'routePoint.addressBookId');
                    let lastAddressId = get(last(job.jobAnalytics), 'routePoint.addressBookId');

                    let startAddressTags = get(addressBookAsMap, `${startAddressId}.tagIdsList`, []);
                    let endAddressTags = get(addressBookAsMap, `${lastAddressId}.tagIdsList`, []);

                    //address tags
                    if (isExpressRoadways) {
                        addFromToBranchStationDataToRow(
                            startAddressTags,
                            'startAddressBranchTags',
                            'startAddressStationTags',
                            dataRow,
                            tags,
                            includeTags
                        );
                        addFromToBranchStationDataToRow(
                            endAddressTags,
                            'endAddressBranchTags',
                            'endAddressStationTags',
                            dataRow,
                            tags,
                            includeTags
                        );
                    } else {
                        tagNames = getTagNamesList(tags, startAddressTags).join(',');
                        addDataRow(includeTags, dataRow, 'startAddressTags', tagNames);
                        tagNames = getTagNamesList(tags, endAddressTags).join(',');
                        addDataRow(includeTags, dataRow, 'endAddressTags', tagNames);
                    }
                }
                //dummy field to be used in table as key
                addDataRow(true, dataRow, 'tableKeyField', `${id}`);
                data.push(dataRow);
            } else {
                for (let j = 1; j < jobAnalyticsLength; j++) {
                    const dataRow = { id: id, jobLink };
                    addDataRow(true, dataRow, 'tableKeyField', `${id}-${j}`);
                    addDataRow(true, dataRow, 1, name);
                    addDataRow(true, dataRow, 2, customer ? customer.name : '');
                    addDataRow(true, dataRow, 3, get(vehicle, 'name', '-'));
                    addDataRow(true, dataRow, 4, get(vehicle, 'licensePlate', '-'));
                    addDataRow(true, dataRow, 5, get(vehicle, 'activeAssignment.user.name', ''));
                    addDataRow(true, dataRow, 6, get(vehicle, 'activeAssignment.user.phoneNumber', ''));
                    addDataRow(true, dataRow, 7, getStartDateFormatType(loggedInUser, startDate));
                    addDataRow(true, dataRow, 8, get(jobAnalytics[0], 'routePoint.address'));
                    addDataRow(true, dataRow, 9, get(jobAnalytics[j], 'routePoint.address'));
                    addDataRow(
                        includeEcommerceJobs,
                        dataRow,
                        'ecommerce',
                        get(erpJob, 'clientContractChartDto.contractNumber')
                    );
                    addDataRow(includeEcommerceJobs, dataRow, 'isEcommerceDelivery', get(erpJob, 'id') ? 'Yes' : 'No');

                    addDataRow(
                        true,
                        dataRow,
                        10,
                        jobAnalytics[0].actualArrival ? getReportTime(jobAnalytics[0].actualArrival) : ''
                    );
                    addDataRow(
                        true,
                        dataRow,
                        11,
                        jobAnalytics[0].actualDeparture ? getReportTime(jobAnalytics[0].actualDeparture) : ''
                    );
                    if (jobAnalytics[0].vehicleState && jobAnalytics[0].vehicleState === 'UNLOADED') {
                        addDataRow(true, dataRow, 12, '');
                    } else {
                        addDataRow(
                            true,
                            dataRow,
                            12,
                            jobAnalytics[0].actualDeparture && jobAnalytics[0].actualArrival
                                ? getTimeDiff(
                                      Math.abs(jobAnalytics[0].actualDeparture - jobAnalytics[0].actualArrival),
                                      false,
                                      true
                                  )
                                : ''
                        );
                    }
                    addDataRow(
                        true,
                        dataRow,
                        13,
                        jobAnalytics[j].actualArrival ? getReportTime(jobAnalytics[j].actualArrival) : ''
                    );
                    addDataRow(
                        true,
                        dataRow,
                        14,
                        jobAnalytics[j].actualDeparture ? getReportTime(jobAnalytics[j].actualDeparture) : ''
                    );
                    if (jobAnalytics[0].vehicleState && jobAnalytics[0].vehicleState === 'UNLOADED') {
                        addDataRow(true, dataRow, 15, '');
                    } else {
                        addDataRow(
                            true,
                            dataRow,
                            15,
                            jobAnalytics[j].actualDeparture && jobAnalytics[j].actualArrival
                                ? getTimeDiff(
                                      Math.abs(jobAnalytics[j].actualDeparture - jobAnalytics[j].actualArrival),
                                      false,
                                      true
                                  )
                                : ''
                        );
                    }
                    //const status = jobAnalytics[j].status;
                    addDataRow(true, dataRow, 16, getJobStatus(status, loggedInUser));
                    addDataRow(true, dataRow, 'vehicleStatus', findvehicleStateFromId(job.jobStatus, loggedInUser));
                    addDataRow(true, dataRow, 'scheduledDistance', `${round(scheduledDistance, 0)} km`);
                    const tat = jobAnalytics[j - 1] ? jobAnalytics[j - 1].transitTime : null;
                    const transitTime =
                        jobAnalytics[j].scheduledArrival && jobAnalytics[j - 1].scheduledDeparture
                            ? jobAnalytics[j].scheduledArrival - jobAnalytics[j - 1].scheduledDeparture
                            : null;
                    const actualTransitTime =
                        jobAnalytics[j].actualArrival && jobAnalytics[j - 1].actualDeparture
                            ? jobAnalytics[j].actualArrival - jobAnalytics[j - 1].actualDeparture
                            : null;
                    const tatVal = tat ? tat : transitTime ? transitTime : null;
                    const delayVal = tatVal && actualTransitTime ? actualTransitTime - tatVal : null;
                    addDataRow(
                        true,
                        dataRow,
                        'delay',
                        delayVal
                            ? `${getTimeDiff(Math.abs(delayVal), false, true)} ${delayVal > 0 ? 'late' : 'early'}`
                            : ''
                    );

                    const originTime = jobAnalytics[0].actualArrival
                        ? jobAnalytics[0].actualArrival
                        : jobAnalytics[0].actualDeparture;
                    const destinationTime = jobAnalytics[j].actualDeparture
                        ? jobAnalytics[j].actualDeparture
                        : jobAnalytics[j].actualArrival;

                    const isCurrent =
                        j + 1 === length ||
                        jobAnalytics[j].status !== 'COMPLETED' ||
                        jobAnalytics[j].status !== 'LATE' ||
                        jobAnalytics[j].status !== 'EARLY' ||
                        jobAnalytics[j].status !== 'MISSED';
                    const isDurationCalculate = (isCurrent && originTime) || (destinationTime && originTime);
                    if (status === 'MISSED' || !isDurationCalculate) {
                        addDataRow(true, dataRow, 'duration', '');
                    } else {
                        addDataRow(
                            true,
                            dataRow,
                            'duration',
                            jobAnalytics[j].status === 'COMPLETED' ||
                                jobAnalytics[j].status === 'LATE' ||
                                jobAnalytics[j].status === 'ONTIME' ||
                                jobAnalytics[j].status === 'EARLY'
                                ? getTimeDiff(destinationTime - originTime, false, true)
                                : isCurrent
                                ? getTimeDiff(moment().valueOf() - originTime, false, true)
                                : ''
                        );
                    }

                    addDataRow(
                        true,
                        dataRow,
                        'stopTime',
                        totalTransitTime > 0 ? getTimeDiffHours(totalTransitTime - runningDuration) : ''
                    );

                    const vehicleLive = get(vehicle, 'id') && find(liveData, { vehicleId: vehicle.id });
                    addDataRow(
                        includeCurrentLocation,
                        dataRow,
                        'currentLocation',
                        vehicleLive ? vehicleLive.address : ''
                    );
                    addDataRow(
                        includeCurrentStatus,
                        dataRow,
                        'currentStatus',
                        vehicleLive ? VEHICLE_STATUS_NAME_JOB[vehicleLive.currentStatus] : ''
                    );
                    addDataRow(
                        includeJobDistance,
                        dataRow,
                        'jobDistance',
                        isCurrent ? `${getJobDistanceRoundedValue(currentKm)} km` : ''
                    );
                    addDataRow(
                        includePercentJobCompleted,
                        dataRow,
                        'percentJobCompleted',
                        status === JOB_STATUS_VALUES.STARTED && currentKm < get(route, 'distance') && isCurrent
                            ? `${round((currentKm / get(route, 'distance')) * 100, 2)}%`
                            : ''
                    );
                    addDataRow(
                        isjobPercentDownTimeOpen,
                        dataRow,
                        'percentDownTime',
                        jobPercentDownTime ? round(jobPercentDownTime) : '-'
                    );
                    addDataRow(
                        includePredictedDelay,
                        dataRow,
                        'expectedTimeOfArrival',
                        isCurrent && predictedDelay != 0 ? getTimeDiff(Math.abs(predictedDelay), false, true) : ''
                    );
                    let arrivalTime = jobAnalytics[j].scheduledArrival;
                    if (
                        !arrivalTime &&
                        jobAnalytics[j - 1].actualDeparture &&
                        jobAnalytics[j - 1].transitTime &&
                        jobAnalytics[j - 1].transitTime > 0
                    ) {
                        arrivalTime = getMomentTime(jobAnalytics[j - 1].actualDeparture)
                            .clone()
                            .add(jobAnalytics[j - 1].transitTime, 'ms');
                    }

                    addDataRow(
                        includePredictedDelay,
                        dataRow,
                        'expectedTimeOfArrival',
                        isCurrent && predictedDelay != 0 && arrivalTime
                            ? getReportTime(arrivalTime + predictedDelay)
                            : ''
                    );
                    addDataRow(includeProjectName, dataRow, 'projectName', get(jobAnalytics[j], 'project.name', ''));
                    addDataRow(
                        includeFreightRate,
                        dataRow,
                        'freightRate',
                        get(jobAnalytics[j], 'project.freightRate', '')
                    );
                    addDataRow(includeVendor, dataRow, 'vendor', get(job, 'vendor.name', ''));
                    addDataRow(includeTransporter, dataRow, 'transporter', get(job, 'transporter.name', ''));
                    addDataRow(includeJobNotes, dataRow, 'jobNotes', notes ? notes : '');
                    addDataRow(
                        includeTat,
                        dataRow,
                        'tat',
                        tat ? getTimeDiffHours(tat) : transitTime ? getTimeDiffHours(transitTime) : ''
                    );
                    addDataRow(
                        includeTat,
                        dataRow,
                        'actualTAT',
                        actualTransitTime ? getTimeDiffHours(actualTransitTime) : ''
                    );
                    addDataRow(includeNotes, dataRow, 'notes', jobAnalytics[j].notes ? jobAnalytics[j].notes : '');
                    addDataRow(includeWeight, dataRow, 'weight', jobAnalytics[j].weight ? jobAnalytics[j].weight : '');
                    addDataRow(
                        includeDocketNumber,
                        dataRow,
                        'docketNumber',
                        jobAnalytics[j].docketNumber
                            ? jobAnalytics[j].docketNumber
                            : j === 1
                            ? jobAnalytics[0].docketNumber
                            : ''
                    );
                    addDataRow(
                        includeInvoiceNumber,
                        dataRow,
                        'invoiceNumber',
                        jobAnalytics[j].invoiceNumber ? jobAnalytics[j].invoiceNumber : ''
                    );
                    addDataRow(
                        includePackageCount,
                        dataRow,
                        'packageCount',
                        jobAnalytics[j].packageCount ? jobAnalytics[j].packageCount : ''
                    );
                    addDataRow(
                        includeVehicleLoad,
                        dataRow,
                        'vehicleState',
                        jobAnalytics[j - 1].vehicleState ? getVehicleStatusUIText(jobAnalytics[j - 1].vehicleState) : ''
                    );
                    addDataRow(
                        includeFreightFare,
                        dataRow,
                        'freightFare',
                        jobAnalytics[j].freightFare ? jobAnalytics[j].freightFare : ''
                    );
                    if (isCSV) {
                        addDataRow(includeJobCreationDate, dataRow, 'createdDate', getReportTime(job.createdDate));
                        addDataRow(includeOpeningOdo, dataRow, 'openingOdo', openingOdo);
                        addDataRow(includeClosingOdo, dataRow, 'closingOdo', closingOdo);
                        addDataRow(includeDeviationCount, dataRow, 'deviationCount', deviationCount);
                        addDataRow(includeRFIDStatus, dataRow, 'RFIDStatus', isRFIDPresent ? 'Yes' : 'No');
                        addDataRow(true, dataRow, 'stationaryNumber', stationaryNumber ? stationaryNumber : '');
                    }
                    if (includeCustomField && isCSV) {
                        let customFields = get(jobAnalytics[j], 'customFields', []);
                        if (job.customFields)
                            map(job.customFields, (cf) => {
                                customFields.push(cf);
                            });

                        map(customFields, (cf) => {
                            addDataRow(
                                includeCustomField,
                                dataRow,
                                `${cf.field.id}-${cf.field.keyName}`,
                                `${cf.value}`
                            );
                        });
                    }

                    if (includeTags && isCSV) {
                        //vehicle tags
                        let attachedTags = get(job, 'vehicle.tagIdsList', []);
                        let tagNames = getTagNamesList(tags, attachedTags).join(',');
                        addDataRow(includeTags, dataRow, 'vehicleTags', tagNames);

                        let startAddressId = get(first(job.jobAnalytics), 'routePoint.addressBookId');
                        let lastAddressId = get(last(job.jobAnalytics), 'routePoint.addressBookId');

                        let startAddressTags = get(addressBookAsMap, `${startAddressId}.tagIdsList`, []);
                        let endAddressTags = get(addressBookAsMap, `${lastAddressId}.tagIdsList`, []);

                        //address tags
                        if (isExpressRoadways) {
                            addFromToBranchStationDataToRow(
                                startAddressTags,
                                'startAddressBranchTags',
                                'startAddressStationTags',
                                dataRow,
                                tags,
                                includeTags
                            );
                            addFromToBranchStationDataToRow(
                                endAddressTags,
                                'endAddressBranchTags',
                                'endAddressStationTags',
                                dataRow,
                                tags,
                                includeTags
                            );
                        } else {
                            tagNames = getTagNamesList(tags, startAddressTags).join(',');
                            addDataRow(includeTags, dataRow, 'startAddressTags', tagNames);
                            tagNames = getTagNamesList(tags, endAddressTags).join(',');
                            addDataRow(includeTags, dataRow, 'endAddressTags', tagNames);
                        }
                    }

                    if (includeProfitAndLoss && isCSV && j === 1) {
                        const transactionExpensesDetails = jobsTransactionExpenses[id];

                        let totalExpense = 0;
                        forEach(jobsTransactionExpensesUniqueCols, (colKey) => {
                            const foundExp = get(
                                find(transactionExpensesDetails, { expenseType: colKey }),
                                'transactionList',
                                []
                            );

                            if (colKey === 'Fuel') {
                                addDataRow(
                                    true,
                                    dataRow,
                                    'expenses-fuelQuantity-DR',
                                    get(find(foundExp, { transactionType: 'DR' }), 'quantity', 0)
                                );
                                addDataRow(
                                    true,
                                    dataRow,
                                    'expenses-fuelQuantity-CR',
                                    get(find(foundExp, { transactionType: 'CR' }), 'quantity', 0)
                                );
                            }
                            const transactionTypeDRAmount = get(find(foundExp, { transactionType: 'DR' }), 'amount', 0);
                            const transactionTypeCRAmount = get(find(foundExp, { transactionType: 'CR' }), 'amount', 0);
                            addDataRow(true, dataRow, `expense-${colKey}-DR`, transactionTypeDRAmount);
                            addDataRow(true, dataRow, `expense-${colKey}-CR`, transactionTypeCRAmount);

                            totalExpense += transactionTypeDRAmount;
                        });

                        const freightFare = get(jobAnalytics[j], 'freightFare', 0);
                        let advance = get(jobBudget, 'advance', 0);
                        let balance = freightFare - advance;
                        let profit = freightFare - totalExpense;

                        // more cols
                        addDataRow(true, dataRow, 'expenses-advance', advance);
                        addDataRow(true, dataRow, 'expenses-totalExpenses', totalExpense);
                        addDataRow(true, dataRow, 'expenses-balance', balance);
                        addDataRow(true, dataRow, 'expenses-profit', profit);
                    }
                    if (isSunFlagAccount(loggedInUser)) {
                        const allJobCustomFields = groupBy(job.customFields, 'fieldId');
                        addDataRow(true, dataRow, 'customerAddress', get(jobAnalytics, '1.routePoint.address', ''));
                        addDataRow(
                            true,
                            dataRow,
                            'startDate',
                            jobAnalytics[0].actualDeparture
                                ? getMomentTime(jobAnalytics[0].actualDeparture, get(loggedInUser, 'timezone')).format(
                                      DATE_FORMAT_TIMESTAMP_DAY
                                  )
                                : startDate
                                ? getMomentTime(startDate).format(DATE_FORMAT_TIMESTAMP_DAY)
                                : ''
                        );
                        addDataRow(
                            true,
                            dataRow,
                            'startTime',
                            jobAnalytics[0].actualDeparture
                                ? getMomentTime(jobAnalytics[0].actualDeparture, get(loggedInUser, 'timezone')).format(
                                      DATE_FORMAT_ONLY_TIME
                                  )
                                : startDate
                                ? getMomentTime(startDate).format(DATE_FORMAT_ONLY_TIME)
                                : ''
                        );
                        addDataRow(true, dataRow, 'shipToPartyCode', get(allJobCustomFields, `447.0.value`));
                        addDataRow(true, dataRow, 'zone', get(allJobCustomFields, `448.0.value`));
                        addDataRow(true, dataRow, 'soldToPartyCode', get(allJobCustomFields, `449.0.value`));
                        addDataRow(true, dataRow, 'soldToPartyName', get(allJobCustomFields, `450.0.value`));
                        addDataRow(true, dataRow, 'simService', deviceSupplier);
                        addDataRow(true, dataRow, 'phoneType', get(allJobCustomFields, `747.0.value`));
                        addDataRow(
                            true,
                            dataRow,
                            'simUptimePercentage',
                            simUptimePercentage != null ? round(simUptimePercentage, 2) : '-'
                        );

                        addDataRow(true, dataRow, 'simConsentStatus', get(allJobCustomFields, `768.0.value`));
                        const simPingCount = get(allJobCustomFields, `770.0.value`, 0);
                        addDataRow(true, dataRow, 'simPingCount', simPingCount);
                        let trackingStatus = '-';
                        if (status === 'ASSIGNED' || (includes(['COMPLETED', 'STARTED'], status) && !simPingCount)) {
                            trackingStatus = 'Not tracked';
                        } else if (includes(['COMPLETED', 'STARTED'], status) && simPingCount) {
                            trackingStatus = 'Tracked';
                        }
                        addDataRow(true, dataRow, 'trackingStatus', trackingStatus);

                        addDataRow(
                            true,
                            dataRow,
                            'destinationInDate',
                            destinationTime ? getMomentTime(destinationTime).format(DATE_FORMAT_TIMESTAMP_DAY) : ''
                        );
                        addDataRow(
                            true,
                            dataRow,
                            'destinationInTime',
                            destinationTime ? getMomentTime(destinationTime).format(DATE_FORMAT_ONLY_TIME) : ''
                        );
                        let uptime = 0;
                        let totalTime = 0;
                        let percentUptime = 0;
                        const finalStartDate = jobAnalytics[0].actualArrival
                            ? jobAnalytics[0].actualArrival
                            : startDate;
                        if (status === 'COMPLETED' && endDate && finalStartDate) {
                            totalTime = getMomentTime(endDate).diff(finalStartDate);
                            uptime = totalTime - percentDownTime;
                        }
                        if (uptime && totalTime) {
                            percentUptime = (uptime / totalTime) * 100;
                        }

                        addDataRow(true, dataRow, 'uptime', round(percentUptime, 2));
                    }
                    data.push(dataRow);
                }
            }
        }
        const totalRow = {};

        addDataRow(true, totalRow, 1, `TOTAL \n${data.length} Jobs`);
        addDataRow(true, totalRow, 2, `-`);
        addDataRow(true, totalRow, 3, `-`);
        addDataRow(true, totalRow, 4, `-`);
        addDataRow(true, totalRow, 5, `-`);
        addDataRow(true, totalRow, 6, `-`);
        addDataRow(true, totalRow, 7, `-`);
        addDataRow(true, totalRow, 8, `-`);
        addDataRow(true, totalRow, 9, `-`);
        addDataRow(true, totalRow, 10, `-`);
        addDataRow(true, totalRow, 11, `-`);
        addDataRow(true, totalRow, 12, `-`);
        addDataRow(true, totalRow, 13, `-`);
        addDataRow(true, totalRow, 14, `-`);
        addDataRow(true, totalRow, 15, `-`);
        addDataRow(true, totalRow, 16, `-`);
        addDataRow(true, totalRow, 17, `-`);
        addDataRow(true, totalRow, 18, `-`);
        addDataRow(true, totalRow, 19, `-`);

        addDataRow(includeCurrentLocation, totalRow, 20, `-`);
        addDataRow(includeCurrentStatus, totalRow, 21, `-`);
        addDataRow(includeJobDistance, totalRow, 22, `-`);
        addDataRow(includePercentJobCompleted, totalRow, 23, `-`);
        addDataRow(includePredictedDelay, totalRow, 24, `-`);
        addDataRow(includePredictedDelay, totalRow, 25, `-`);

        addDataRow(includeProjectName, totalRow, 'projectName', '-');
        addDataRow(includeFreightRate, totalRow, 'freightRate', '-');
        addDataRow(includeJobNotes, totalRow, 26, `-`);
        addDataRow(includeTat, totalRow, 27, `-`);
        addDataRow(includeTat, totalRow, 28, `-`);
        addDataRow(includeNotes, totalRow, 29, `-`);
        addDataRow(includeWeight, totalRow, 30, `-`);
        addDataRow(includeDocketNumber, totalRow, 31, `-`);
        addDataRow(includeInvoiceNumber, totalRow, 32, `-`);
        addDataRow(includePackageCount, totalRow, 33, `-`);
        addDataRow(includeVehicleLoad, totalRow, 34, `-`);
        addDataRow(includeFreightFare, totalRow, 35, `-`);

        if (returnData) {
            return [headers, data];
        } else {
            //data.push(totalRow);
        }
        const headerStyles = {
            font: { name: 'Arial', family: 2, size: 12, bold: true },
            alignment: { wrapText: true, vertical: 'middle', horizontal: 'center' },
            // fill: {
            //     type: 'pattern',
            //     pattern: 'solid',
            //     fgColor: { argb: '99CCFF' },
            // },
        };

        startReportCreation(
            ORIENTATION.LANDSCAPE,
            headers,
            data,
            reportFormat,
            'Job Report',
            moment(startDate),
            moment(endDate),
            vehicleNumber,
            reportName,
            noOfCols
        );
    }
}

export async function triggerPlannedVersusActualReportDownload(
    accesstoken,
    from,
    to,
    vehicleId,
    selectedCriteria,
    loggedInUser
) {
    const result = await fetchMetrics(
        accesstoken,
        getMomentTime(from).valueOf(),
        getMomentTime(to).valueOf(),
        vehicleId
    ).promise;

    const driverResult = await fetchUsersByType(accesstoken, 'driver', 0, 4000).promise;
    const driverList = get(driverResult, 'data.content', []);
    const vehicleResult = await fetchAccountVehicleAnalytics(
        accesstoken,
        getMomentTime(from).valueOf(),
        getMomentTime(to).valueOf()
        // vehicleId
    ).promise;

    const totalDistanceAndTimeList = get(vehicleResult, 'data', []);

    const jobMetrics = get(result, 'data.jobMetrics', []);
    let noOfCols = 0;
    let headers = [];
    const footerRow = {};
    noOfCols += addHeader(true, headers, 'srNo', 'SrNo', 150, footerRow);
    noOfCols += addHeader(true, headers, 'vehicleNo', 'Vehicle No', 150, footerRow);
    noOfCols += addHeader(true, headers, 'driverName', 'Driver Name', 150, footerRow);
    noOfCols += addHeader(
        selectedCriteria === PLANNED_VERSUS_ACTUAL.TOTAL_DISTANCE || isShowColumnsToAccount(loggedInUser),
        headers,
        'scheduledDistance',
        'Scheduled Distance',
        150,
        footerRow
    );
    noOfCols += addHeader(
        selectedCriteria === PLANNED_VERSUS_ACTUAL.TOTAL_DISTANCE || isShowColumnsToAccount(loggedInUser),
        headers,
        'actualDistance',
        'Actual Distance',
        150,
        footerRow
    );
    noOfCols += addHeader(
        isShowColumnsToAccount(loggedInUser),
        headers,
        'distanceDeviation',
        'Distance Deviation',
        150,
        footerRow
    );
    noOfCols += addHeader(
        selectedCriteria === PLANNED_VERSUS_ACTUAL.RUNNING_TIME || isShowColumnsToAccount(loggedInUser),
        headers,
        'scheduledTime',
        'Schedule Time',
        150,
        footerRow
    );
    noOfCols += addHeader(
        selectedCriteria === PLANNED_VERSUS_ACTUAL.RUNNING_TIME || isShowColumnsToAccount(loggedInUser),
        headers,
        'actualTime',
        'Actual Time',
        150,
        footerRow
    );
    noOfCols += addHeader(!isShowColumnsToAccount(loggedInUser), headers, 'deviation', 'Deviation', 150, footerRow);
    noOfCols += addHeader(
        isShowColumnsToAccount(loggedInUser),
        headers,
        'timeDeviation',
        'Time Deviation',
        150,
        footerRow
    );

    noOfCols += addHeader(isShowColumnsToAccount(loggedInUser), headers, 'totalKms', 'Total Kms', 150, footerRow);

    noOfCols += addHeader(isShowColumnsToAccount(loggedInUser), headers, 'totalTime', 'Total Time', 150, footerRow);

    const reportName = 'Planned_Vs_Actual_Report';
    const data = [];
    const reportFormat = REPORT_FORMAT.CSV;
    map(jobMetrics, (jobMetric, index) => {
        const srNo = index + 1;
        const { vehicleId, driverId, licensePlate, scheduledDistance, actualDistance } = jobMetric;
        const vehicleNo = licensePlate;
        const driver = find(driverList, { id: +driverId });
        const driverName = `${get(driver, 'firstName', '-')} ${get(driver, 'lastName', '')} `;
        const totalDistanceAndTime = find(totalDistanceAndTimeList, { vehicleId: +vehicleId });
        const totalKms = get(totalDistanceAndTime, 'totalOdometer', 0);
        // let totalTimeEngineOn = get(totalDistanceAndTime, 'durationEngineOn', 0);
        let totalTime;
        if (isShowColumnsToAccount(loggedInUser)) {
            totalTime = getTimeDiffHours(to - from, false, true);
        }
        let scheduledTime = get(jobMetric, 'scheduledTime', 0);
        let actualTime = get(jobMetric, 'actualTime', 0);

        let deviation;
        let timeDeviation;
        let distanceDeviation;
        if (selectedCriteria === PLANNED_VERSUS_ACTUAL.TOTAL_DISTANCE && !isShowColumnsToAccount(loggedInUser)) {
            deviation = round(actualDistance - scheduledDistance, 2);
        } else if (!isShowColumnsToAccount(loggedInUser)) {
            deviation = getTimeDiffHours(actualTime - scheduledTime, false, true);
        }
        if (isShowColumnsToAccount(loggedInUser)) {
            distanceDeviation = round(actualDistance - scheduledDistance, 2);
            timeDeviation = getTimeDiffHours(actualTime - scheduledTime, false, true);
        }
        scheduledTime = getTimeDiffHours(scheduledTime, false, true);
        actualTime = getTimeDiffHours(actualTime, false, true);

        if (isShowColumnsToAccount(loggedInUser)) {
            data.push({
                srNo,
                vehicleNo,
                driverName,
                scheduledDistance,
                actualDistance,
                scheduledTime,
                actualTime,
                timeDeviation,
                distanceDeviation,
                totalKms,
                totalTime,
            });
        } else {
            data.push({
                srNo,
                vehicleNo,
                driverName,
                scheduledDistance,
                actualDistance,
                scheduledTime,
                actualTime,
                deviation,
            });
        }
    });

    const headerStyles = {
        font: { name: 'Arial', family: 2, size: 12, bold: true },
        alignment: { wrapText: true, vertical: 'middle', horizontal: 'center' },
        // fill: {
        //     type: 'pattern',
        //     pattern: 'solid',
        //     fgColor: { argb: '99CCFF' },
        // },
    };

    startReportCreation(
        ORIENTATION.LANDSCAPE,
        headers,
        data,
        reportFormat,
        'Planned Vs Actual',
        moment(),
        null,
        null,
        reportName,
        noOfCols,
        '',
        '',
        true,
        '',
        '',
        {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
        },
        false,
        '',
        headerStyles,
        false
    );
}

export async function triggerLatestJobReportDownload(accesstoken, reportFormat, filterConfig) {
    const jobResult = await fetchLastJobAllVehicles(accesstoken).promise;
    const jobs = jobResult.data;
    const reportName = createReportFilename(reportFormat, 'Latest-Job', '', 'groupName', moment(), null);
    const headers = [];
    const footerRow = {};
    const isCSV = reportFormat === REPORT_FORMAT.CSV;
    let noOfCols = 0;
    noOfCols += addHeader(true, headers, 1, 'Transporter Name', 150, footerRow);
    noOfCols += addHeader(true, headers, 2, 'Report Date', 150, footerRow);
    noOfCols += addHeader(true, headers, 3, 'Vehicle Number', 150, footerRow);
    noOfCols += addHeader(true, headers, 4, 'Group Name', 150, footerRow);
    noOfCols += addHeader(true, headers, 5, 'Status', 150, footerRow);
    noOfCols += addHeader(true, headers, 6, 'Start Address', 150, footerRow);
    noOfCols += addHeader(true, headers, 7, 'Arrival Time At Loading', 150, footerRow);
    noOfCols += addHeader(true, headers, 8, 'Depature Time At Loading', 150, footerRow);
    noOfCols += addHeader(true, headers, 9, 'End Address', 150, footerRow);
    noOfCols += addHeader(true, headers, 10, 'Arrival Time At UnLoading', 150, footerRow);
    noOfCols += addHeader(true, headers, 11, 'Depature Time At UnLoading', 150, footerRow);
    noOfCols += addHeader(true, headers, 12, 'Current Address', 150, footerRow);
    noOfCols += addHeader(true, headers, 13, 'Customer Name', 150, footerRow);
    noOfCols += addHeader(true, headers, 14, 'Total Duration', 150, footerRow);
    noOfCols += addHeader(true, headers, 15, 'Loading Time', 150, footerRow);
    noOfCols += addHeader(true, headers, 16, 'Transit Time', 150, footerRow);
    noOfCols += addHeader(true, headers, 17, 'Unloading Time', 150, footerRow);
    const length = jobs.length;
    const data = [];

    const todayDate = getMomentTime().format(DATE_FORMAT_HUMANIZE_DAY);
    map(jobs, (job) => {
        const arrivalTimeAtLoading = get(job, 'jobAnalytics[0].actualArrival');
        const departureTimeAtLoading = get(job, 'jobAnalytics[0].actualDeparture');
        const arrivalTimeAtUnloading = get(job, 'jobAnalytics[1].actualArrival');
        const departureTimeAtUnloading = get(job, 'jobAnalytics[1].actualDeparture');
        let currentAddress = '';
        if (departureTimeAtUnloading) {
            currentAddress = 'Way To Plant';
        } else if (arrivalTimeAtUnloading) {
            currentAddress = 'At Customer';
        } else if (departureTimeAtLoading) {
            currentAddress = 'Way to Customer';
        } else if (arrivalTimeAtLoading) {
            currentAddress = 'At Plant';
        }

        const dataRow = {};
        addDataRow(true, dataRow, 1, 'Saint Gobain');
        addDataRow(true, dataRow, 2, todayDate);
        addDataRow(true, dataRow, 3, get(job, 'vehicle.licensePlate', ''));
        addDataRow(true, dataRow, 4, get(job, 'vehicle.group.name', '-'));
        addDataRow(true, dataRow, 5, job.status);
        addDataRow(true, dataRow, 6, get(job, 'route.routePoints[0].address', '-'));
        addDataRow(
            true,
            dataRow,
            7,
            arrivalTimeAtLoading ? getMomentTime(arrivalTimeAtLoading).format(DATE_FORMAT_REPORT) : '-'
        );
        addDataRow(
            true,
            dataRow,
            8,
            departureTimeAtLoading ? getMomentTime(departureTimeAtLoading).format(DATE_FORMAT_REPORT) : '-'
        );
        addDataRow(true, dataRow, 9, get(job, 'route.routePoints[1].address', '-'));
        addDataRow(
            true,
            dataRow,
            10,
            arrivalTimeAtUnloading ? getMomentTime(arrivalTimeAtUnloading).format(DATE_FORMAT_REPORT) : '-'
        );
        addDataRow(
            true,
            dataRow,
            11,
            departureTimeAtUnloading ? getMomentTime(departureTimeAtUnloading).format(DATE_FORMAT_REPORT) : '-'
        );
        addDataRow(true, dataRow, 12, currentAddress);
        addDataRow(true, dataRow, 13, get(job, 'route.routePoints[1].address', '-'));
        addDataRow(
            true,
            dataRow,
            14,
            departureTimeAtUnloading ? getTimeDiffHours(departureTimeAtUnloading - arrivalTimeAtLoading) : '-'
        );
        addDataRow(
            true,
            dataRow,
            15,
            departureTimeAtLoading ? getTimeDiffHours(departureTimeAtLoading - arrivalTimeAtLoading) : '-'
        );
        addDataRow(
            true,
            dataRow,
            16,
            arrivalTimeAtUnloading ? getTimeDiffHours(arrivalTimeAtUnloading - departureTimeAtLoading) : '-'
        );
        addDataRow(
            true,
            dataRow,
            17,
            arrivalTimeAtUnloading ? getTimeDiffHours(departureTimeAtUnloading - arrivalTimeAtUnloading) : '-'
        );

        data.push(dataRow);
    });

    startReportCreation(
        ORIENTATION.LANDSCAPE,
        headers,
        data,
        reportFormat,
        'Latest Job Report',
        moment(),
        null,
        null,
        reportName,
        noOfCols
    );
}

export const expressColJobReportMapping = [
    { prompt: 'Vehicle Number', order: 1, name: 4 },
    { prompt: 'Vehicle Name', order: 2, name: 3 },
    { prompt: 'Job Name', order: 3, name: 1 },
    { prompt: 'Reporting Time At Source', order: 4, name: 7 },
    { prompt: 'Start Branch', order: 5, name: 'startAddressBranchTags' },
    { prompt: 'Start Station', order: 6, name: 'startAddressStationTags' },
    { prompt: 'End Branch', order: 7, name: 'endAddressBranchTags' },
    { prompt: 'End Station', order: 8, name: 'endAddressStationTags' },
    { prompt: 'Consignor', order: 9, name: 2 },
    { prompt: 'Destination', order: 10, name: 9 },
    { prompt: 'Scheduled Arrival At Origin', order: 11, name: 'scheduledArrivalTimeOrigin' },
    { prompt: 'Arrival Time At Origin', order: 12, name: 10 },
    { prompt: 'Departure Time At Origin', order: 13, name: 11 },
    { prompt: 'Loading Duration', order: 14, name: 12 },
    { prompt: 'Expected Time Of Arrival', order: 15, name: 'expectedTimeOfArrival' },
    { prompt: 'Arrival Time At Destination', order: 16, name: 13 },
    { prompt: 'TAT', order: 17, name: 'tat' },
    { prompt: 'Actual TAT', order: 18, name: 'actualTAT' },
    { prompt: 'Delay', order: 19, name: 'delay' },
    { prompt: 'Departue Time At Destination', order: 20, name: 14 },
    { prompt: 'Unloading Duration', order: 21, name: 15 },
    { prompt: 'Scheduled Distance', order: 22, name: 'scheduledDistance' },
    { prompt: 'Job Distance', order: 23, name: 'jobDistance' },
    { prompt: 'Duration', order: 24, name: 'duration' },
    { prompt: 'Stop Time', order: 25, name: 'stopTime' },
    { prompt: 'Weight', order: 26, name: 'weight' },
    { prompt: 'Notes', order: 27, name: 'notes' },
    { prompt: 'Invoice Number', order: 28, name: 'invoiceNumber' },
    { prompt: 'Job Notes', order: 29, name: 'jobNotes' },
    { prompt: 'Deviation Count', order: 30, name: 'deviationCount' },
    { prompt: 'Driver Name', order: 31, name: 5 },
    { prompt: 'Driver Number', order: 32, name: 6 },
    { prompt: 'Opening Odometer', order: 33, name: 'openingOdo' },
    { prompt: 'Closing Odometer', order: 34, name: 'closingOdo' },
];

export const getRFIDStatus = (jobImages) => {
    let rfFound = false;
    map(jobImages, (image) => {
        if (image.imageTag === 'RF') {
            rfFound = true;
        }
    });
    return rfFound;
};

export const checkInsideAllowedStopPoint = (allowedAddressStops, addressId, routeId) => {
    if (routeId) {
        const routeAllowedStops = get(allowedAddressStops, `${routeId}`, []);
        const index = includes(routeAllowedStops, addressId);
        if (index > -1) {
            return true;
        }
    }
    return false;
};
export const checkStoppageSupercedesCondition = (totalStops, totalAllowedStops, duration, route) => {
    const routeThresholds = find(isKnownRoutePoint, { id: route });
    if (routeThresholds) {
        const allowedStopLimitForAcnt = routeThresholds.allowedAddressStoppageCount;
        const allowedDurationLimitForAcnt = routeThresholds.thresholdStoppage;
        const totalStopsDiff = totalStops - totalAllowedStops;
        if (duration > allowedDurationLimitForAcnt) {
            return true;
        } else if (totalStopsDiff >= 1) {
            return true;
        } else return false;
    } else return false;
};

export const REPORT_NAME = {
    JOB_REPORT: 'jobReport',
    JOB_SPEED_REPORT: 'jobSpeedViolationReport',
    JOB_LATEST_REPORT: 'latestJobReport',
    REALTIME_REPORT_RMC: 'realtimeReportRMC',
    INTRA_PLANT_REPORT: 'intraPlantReport',
    VEHICLE_PERFORMANCE_REPORT: 'vehiclePerformanceReport',
    JOB_REPORT_CASTROL: 'jobReportCastrol',
    JOB_REPORT_BHARATHI: 'jobReportBharathi',
    EXCEPTION_JOB_REPORT_BHARATHI: 'exceptionJobReportBharathi',
    MANUAL_JOB_REPORT: 'jobReportManual',
};

export const omJayFacilityRemoveColumnsMapping = [
    'consignor',
    'haltTime',
    'routeId',
    'routeName',
    'vehicleTags',
    'jobLink',
    'createdDate',
    'createdBy',
    'stationaryNumber',
    'arrivalTimeOrigin',
    'scheduledDepartureTimeOrigin',
    'unloadingDuration',
    'plantTAT',
    'delayOrigin',
    1, // 'status',
    'vehicleStatus',
    'scheduledDistance',
    2, //'totalDelayArrival',
    'stopTime',
    3, //'currentLocation',
    4, //'currentStatus',
    6, //'jobScheduledDistance',
    'vehicleLoadState',
    7, // 'missingKM',
    8, //'percentageDowntime',
    9, //'percentJobCompleted',
    10, //'predictedDelay',
    'expectedTimeOfArrival',
    'jobNotes',
    'driverExpenses',
    'driverExpensesPerKMEmptyTrips',
    'fuelCost',
    'fuelQuantityInLtr',
    'rtoExpenses',
    'tollExpenses',
    'otherExpenses',
    'moreExpenses',
    'scheduleTotalTat',
    'actualTotalTat',
    'transporter',
    'openingOdo',
    'closingOdo',
    'dtdcKm',
    'freightFare',
    'hardAcceleration',
    'hardDeceleration',
    'sharpTurns',
    'INVOICE',
    'EWAY',
    'DOCKET',
    'LR',
    13,
    27, //'idealArrivalTimeDestination',
    28, //departureTimeFromdestination2
    15,
    29, //'idealDepartureTimeDestination',
    16,
    30, //'haltUnloadingDurationDestination',
    17,
    31, //'tatDestination',
    18,
    32, //'actualTatDestination',
    22,
    36, //'delayDestination',
    23,
    37, //'statusDestination',
    'returnTripTAT',
    'roundTripTAT',
    'hardAcc',
    'hardDeceleration',
    'fatigueDrivingCount',
    'overspeeding',
    'sharpTurns',
    'nightDriving',
];

export const jklcJobColumnsMapping = [
    'Job Name',
    'Invoice Number',
    'Delivery Number',
    'Vehicle Number',
    'Job Status',
    'Origin',
    'Invoice Date and Time',
    'Destination',
    'Job End Date',
    'Actual Unloading Lat, Lng',
    'Actual Unloading Map Link',
    'Job Distance',
    'Duration',
    'Tracking Mode',
    'Driver Name',
    'Driver Number',
    'Job Link',
    'Device Mode',
    'Device Id',
    '1Km Geofence Entry',
    '1Km Geofence Entry Time',
    '15Km Geofence Entry',
    '15Km Geofence Entry Time',
    '40Km Geofence Entry',
    '40Km Geofence Entry Time',
    // 'Job Tracking',
    'Sim Ping Count',
    'Segment',
    'SAP Distance',
    'Source Code',
    'Ship To Code',
    'Ship To Party Name',
    'Ship To Lat',
    'Ship To Long',
    'Sold To Code',
    'Sold To Name',
    'Schedule Quantity',
    'Material Desc',
    'Transporter Code',
    'Transporter Name',
    'Transportation Zone',
    'Transporter Mail',
    'Transporter Mobile',
    'Unloading District',
    'Unloading Region Code',
    'Current Transportation Zone',
    'Job Close Reason',
    'Final Trip Type',
    'Measured Distance Difference',
    'Wire Disconnection Status',
    'Risk Level',
    'Risk Level Time',
    'SIM Uptime Percentage',
    'GPS Uptime Percentage',
    'Track Health %',
    'Trip Running Status',
    'Trip Closure Type',
    'Tempering Risk',
    'Distance to Unloading Point',
    '1Km Geofence Exit Time',
    '15Km Geofence Exit Time',
    '40Km Geofence Exit Time',
    'SAP and Job Difference',
    'Toll Cost',
    'Actual Unloading Address',
    'Distance Difference',
    'Sim Distance',
    'Distance Tracking Status',
    'GPS Distance',
    'Customer entry date and time',
    'Customer exit date and time',
    'Trip URL',
    'Ship To Description',
    'Unloading Point Description',
];
