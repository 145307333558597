import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { get, isEmpty, map, round, sumBy, values } from 'lodash';
import { getMomentTime } from 'utils/date_utils';
import { Input, InputNumber } from 'sharedV2/FxFormItems';
import { Link } from 'react-router-dom';
import { getCurrencyFromCountry } from 'utils/internationalization_utils';
const renderTdsAmountFieldForFreightBill = (record, formikProps) => {
    const { values } = formikProps;
    const billId = get(record, 'id');
    // console.log(billId, 'sdf34232', get(values, `paymentMappingRequests[${billId}]`));
    const disabled = !get(values, `paymentMappingRequests[${billId}]`);
    return (_jsx(InputNumber, { addonBefore: getCurrencyFromCountry(window.FLEETX_LOGGED_IN_USER), disabled: disabled, name: `paymentMappingRequests.${billId}.tdsAmount`, placeholder: "Enter TDS Amount", style: { width: '60%' } }));
};
const renderPaymentAmountFieldForFreightBill = (record, formikProps) => {
    const { values } = formikProps;
    const billId = get(record, 'id');
    const disabled = !get(values, `paymentMappingRequests[${billId}]`);
    return (_jsx(InputNumber, { addonBefore: getCurrencyFromCountry(window.FLEETX_LOGGED_IN_USER), disabled: disabled, name: `paymentMappingRequests.${billId}.paymentAmount`, placeholder: "Enter Payment Amount", style: { width: '60%' } }));
};
export const getCommonColumns = (billType, formikProps) => {
    const billNumberConfig = {
        FREIGHT_BILL: {
            idPath: ['basicDetails', 'freightBillId'],
            amountPath: ['totalFreight'],
            editUrl: (id) => get(window.FLEETX_LOGGED_IN_DATA, 'isSwitchedUser') ||
                get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0) > 11000
                ? `/dashboard/bills/freightbillv2/edit/${id}`
                : `/dashboard/bills/freightbill/edit/${id}`,
        },
        SUPPLEMENTARY_LOGISTICS_INVOICE: {
            idPath: ['basicDetails', 'supplementaryBillId'],
            amountPath: ['totalFreight'],
            editUrl: (id) => `/dashboard/bills/supplementarybill/edit/${id}`,
        },
        SUPPLEMENTARY_NON_LOGISTICS_INVOICE: {
            idPath: ['basicDetails', 'supplementaryBillId'],
            amountPath: ['totalFreight'],
            editUrl: (id) => `/dashboard/bills/supplementarybill/edit/${id}`,
        },
        DEBIT_NOTE: {
            idPath: ['id'],
            amountPath: ['paymentAmount'],
            editUrl: (id) => `/dashboard/bills/debitnote/edit/${id}`,
        },
    };
    const config = billNumberConfig[billType];
    return [
        {
            title: 'Bill Number',
            dataIndex: ['basicDetails', 'billNumber'],
            key: 'billNumber',
            render: (text, record) => {
                const id = get(record, config.idPath, get(record, 'id'));
                return (_jsx(_Fragment, { children: billType === 'FREIGHT_BILL' ||
                        billType === 'SUPPLEMENTARY_LOGISTICS_INVOICE' ||
                        billType === 'SUPPLEMENTARY_NON_LOGISTICS_INVOICE' ? (_jsx("a", { target: "_blank", href: config.editUrl(id), children: text })) : (_jsx("div", { children: get(record, 'stationaryNumber') })) }));
            },
        },
        {
            title: 'Bill Amount',
            dataIndex: config.amountPath,
            key: 'amount',
            render: (amount) => `₹ ${round(amount, 2)}`,
        },
        {
            title: 'Billing Date',
            dataIndex: ['basicDetails', 'billingDate'],
            key: 'billingDate',
            render: (date) => getMomentTime(date).format('DD/MM/YYYY'),
        },
        {
            title: 'Bill Settled Amount',
            dataIndex: ['voucher', 'settledAmount'],
            key: 'settledAmount',
        },
        {
            title: 'TDS Amount',
            key: 'tdsAmount',
            render: (record) => renderTdsAmountFieldForFreightBill(record, formikProps),
            hidden: billType !== 'FREIGHT_BILL',
        },
        {
            title: 'Payment Amount',
            key: 'paymentAmount',
            render: (record) => renderPaymentAmountFieldForFreightBill(record, formikProps),
            hidden: billType === 'FREIGHT_BILL' || billType === 'SUPPLEMENTARY_LOGISTICS_INVOICE',
        },
    ];
};
const renderPaymentAmountField = (record, formikProps) => {
    const freightBillId = get(record, 'freightBillId');
    const consignmentId = get(record, 'id');
    const disabled = !get(formikProps.values, `paymentMappingRequests[${freightBillId}]`) ||
        !get(formikProps.values, `paymentMappingRequests[${freightBillId}].settlementEntityRequests[${consignmentId}]`);
    return (_jsx(InputNumber, { addonBefore: getCurrencyFromCountry(window.FLEETX_LOGGED_IN_USER), disabled: disabled, name: `paymentMappingRequests.${freightBillId}.settlementEntityRequests.${consignmentId}.balanceAmount`, placeholder: "Enter Amount" }));
};
const renderRemarksField = (record, formikProps) => {
    const freightBillId = get(record, 'freightBillId');
    const consignmentId = get(record, 'id');
    const disabled = !get(formikProps.values, `paymentMappingRequests[${freightBillId}]`) ||
        !get(formikProps.values, `paymentMappingRequests[${freightBillId}].settlementEntityRequests[${consignmentId}]`);
    return (_jsx(Input, { disabled: disabled, name: `paymentMappingRequests.${freightBillId}.settlementEntityRequests.${consignmentId}.remarks`, placeholder: "Enter Remarks" }));
};
export const getConsignmentColumns = (billType, formikProps) => [
    {
        title: 'CN Number',
        dataIndex: ['consignmentEntityDetailsDto', 'consignmentNumber'],
        key: 'consignmentNumber',
        render: (text, record) => (_jsx(Link, { target: "_blank", to: `/dashboard/consignments/edit/${get(record, 'consignmentEntityDetailsDto.id')}/${get(record, 'consignmentEntityDetailsDto.consignmentStatus')}`, children: text })),
    },
    {
        title: 'CN Date',
        dataIndex: ['basicDetails', 'consignmentDate'],
        key: 'consignmentDate',
        render: (date) => getMomentTime(date).format('DD/MM/YYYY'),
    },
    // {
    //     title: 'Route',
    //     dataIndex: ['basicDetails', 'chargedRouteId'],
    //     key: 'chargedRouteId',
    // },
    // {
    //     title: 'Vehicle',
    //     dataIndex: ['basicDetails', 'vehicle', 'licensePlate'],
    //     key: 'licensePlate',
    // },
    // {
    //     title: 'Epod Details',
    //     key: 'epodDetails',
    //     render: (record: any) => {
    //         const cnStatus = get(record, 'status');
    //         if (cnStatus === 'POD_COMPLETED') {
    //             return (
    //                 <div>
    //                     <a
    //                         href={`/dashboard/epod/epod-form/edit/${get(record, 'jobId')}/${get(record, 'id')}`}
    //                         target="_blank"
    //                         onClick={(e) => e.stopPropagation()}
    //                     >
    //                         View POD
    //                     </a>
    //                 </div>
    //             );
    //         } else if (cnStatus === 'MAPPED_TO_CHALLAN') {
    //             return <span style={{ cursor: 'pointer' }}>Add Epod</span>;
    //         }
    //         return <span>Job Not Created</span>;
    //     },
    // },
    {
        title: 'Freight Amount',
        dataIndex: ['total'],
        key: 'total',
    },
    {
        title: 'Received Advance',
        dataIndex: ['receivedAdvanceAmount', 'amount'],
        key: 'consignmentAdvanceAmount',
        hidden: billType !== 'FREIGHT_BILL',
    },
    {
        title: 'Received Payment',
        dataIndex: ['receivedPaymentAmount'],
        key: 'receivedAdvanceAmount',
        hidden: billType !== 'FREIGHT_BILL',
    },
    {
        title: 'Balance Amount',
        dataIndex: ['balancePaymentAmount'],
        key: 'balancePaymentAmount',
        hidden: billType !== 'FREIGHT_BILL',
    },
    {
        title: 'Payment Amount',
        key: 'paymentAmount',
        width: '15%',
        render: (record) => renderPaymentAmountField(record, formikProps),
    },
    {
        title: 'Remark',
        key: 'remarks',
        width: '15%',
        render: (record) => renderRemarksField(record, formikProps),
    },
];
export const transformPayload = (formData) => {
    console.log('formData', formData);
    // Extract and convert amounts to string
    const indirectIncomeAmount = get(formData, 'ledgerDetails.indirectIncome.amount', '').toString();
    const tdsLedgerAmount = get(formData, 'ledgerDetails.tdsLedger.amount', '').toString();
    console.log('indirectIncomeAmount', indirectIncomeAmount);
    console.log('tdsLedgerAmount', tdsLedgerAmount);
    // Initialize ledgerDetails object to conditionally add properties
    const ledgerDetails = {};
    // Add indirectIncome to ledgerDetails if amount > 0
    if (parseFloat(indirectIncomeAmount) > 0) {
        ledgerDetails.indirectIncome = {
            ledgerId: get(formData, 'ledgerDetails.indirectIncome.ledgerId'),
            amount: indirectIncomeAmount,
        };
    }
    // Add tdsLedger to ledgerDetails if amount > 0
    if (parseFloat(tdsLedgerAmount) > 0) {
        ledgerDetails.tdsLedger = {
            ledgerId: get(formData, 'ledgerDetails.tdsLedger.ledgerId'),
            amount: tdsLedgerAmount,
        };
    }
    // Prepare transformed object
    const transformed = {
        mappingId: get(formData, 'mappingId'),
        mappingNature: get(formData, 'mappingNature'),
        ledgerDetails: Object.keys(ledgerDetails).length > 0 ? ledgerDetails : undefined,
        paymentMappingRequests: map(formData.paymentMappingRequests, (request) => {
            // Calculate total balanceAmount and deductionAmount for each paymentMappingRequest
            const settlementEntity = get(request, 'settlementEntityRequests', []);
            const totalBalanceAmount = sumBy(values(settlementEntity), (entity) => get(entity, 'balanceAmount', 0) ? get(entity, 'balanceAmount', 0) : get(entity, 'settlementAmount'));
            const totalDeductionAmount = sumBy(values(settlementEntity), (entity) => sumBy(entity.deductionMappings || [], (deduction) => {
                return get(deduction, 'deductionAmount', 0);
            }));
            let totalAmount;
            if (get(request, 'paymentAmount')) {
                totalAmount = get(request, 'paymentAmount');
            }
            else {
                totalAmount = totalBalanceAmount + totalDeductionAmount;
            }
            // Prepare the request object, conditionally adding tdsAmount only if it has a value
            const paymentMappingRequest = {
                billId: get(request, 'billId'),
                settlementType: get(request, 'settlementType'),
                totalAmount: totalAmount,
            };
            // Only add settlementEntityRequests if it has values
            const settlementEntities = map(request.settlementEntityRequests, (entity) => ({
                settlementEntityId: get(entity, 'settlementEntityId'),
                settlementAmount: get(entity, 'balanceAmount'),
                remarks: get(entity, 'remarks'),
                deductionMappings: isEmpty(entity.deductionMappings)
                    ? undefined // if no deductionMappings, skip this field
                    : map(entity.deductionMappings, (deduction) => ({
                        ledgerId: get(deduction, 'ledgerId'),
                        deductionAmount: get(deduction, 'deductionAmount'),
                        remarks: get(deduction, 'remarks'),
                    })),
            }));
            // Only include settlementEntityRequests if it has values
            if (settlementEntities.length > 0) {
                paymentMappingRequest.settlementEntityRequests = settlementEntities;
            }
            // Only add tdsAmount if it has a value
            const tdsAmount = get(request, 'tdsAmount');
            if (tdsAmount !== undefined && tdsAmount !== null && tdsAmount !== '') {
                paymentMappingRequest.tdsAmount = tdsAmount;
            }
            // Only add paymentAmount if it has a value
            // const paymentAmount = get(request, 'paymentAmount');
            // if (paymentAmount !== undefined && paymentAmount !== null && paymentAmount !== '') {
            //     paymentMappingRequest.paymentAmount = paymentAmount;
            // }
            return paymentMappingRequest;
        }),
    };
    return transformed;
};
