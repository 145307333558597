import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { FxCol, FxRow, FxTable } from 'sharedV2';
import { get, map, pickBy, filter, has, assign, includes } from 'lodash';
import CalendarComponent from 'components/utils/calendar_component';
import { getCommonColumns } from './utils';
const BillSettlementNonSupplementaryBillComponent = (props) => {
    const { formikProps, bills, loading, dateRange, onDateRangeChange } = props;
    const { values } = formikProps;
    const handleNonSupplementaryBillSelect = (selectedRowKeys, selectedRows) => {
        const { values, setFieldValue } = formikProps;
        const currentMappings = { ...get(values, 'paymentMappingRequests', {}) };
        // Separate non-supplementary bills from other bill types
        const nonSupplementaryBills = pickBy(currentMappings, (mapping) => get(mapping, 'settlementType') === 'SUPPLEMENTARY_NON_LOGISTICS_INVOICE');
        const otherBills = pickBy(currentMappings, (mapping) => get(mapping, 'settlementType') !== 'SUPPLEMENTARY_NON_LOGISTICS_INVOICE');
        // Filter only non-supplementary bills based on selectedRowKeys
        const currentMappingsFiltered = pickBy(nonSupplementaryBills, (_, key) => includes(selectedRowKeys, key));
        // Find rows that are not already in currentMappingsFiltered
        const rowsToAdd = filter(selectedRows, (row) => !has(currentMappingsFiltered, row.id));
        // Create newMappings directly
        const newMappings = rowsToAdd.reduce((acc, row) => {
            acc[row.id] = {
                billId: row.id,
                settlementType: 'SUPPLEMENTARY_NON_LOGISTICS_INVOICE',
                // totalAmount: get(row, 'basicDetails.freightAmount', 0),
                // tdsAmount: 0,
                // settlementEntityRequests: {},
            };
            return acc;
        }, {});
        // Merge currentMappingsFiltered, newMappings and otherBills
        const updatedMappings = assign({}, otherBills, currentMappingsFiltered, newMappings);
        setFieldValue('paymentMappingRequests', updatedMappings);
    };
    const columns = getCommonColumns('SUPPLEMENTARY_NON_LOGISTICS_INVOICE', formikProps);
    return (_jsxs(_Fragment, { children: [_jsx(FxRow, { className: "mb-4", children: _jsx(FxCol, { span: 24, children: _jsx(CalendarComponent, { startDate: dateRange.startDate, endDate: dateRange.endDate, onDateSelected: onDateRangeChange }) }) }), _jsx(FxTable, { columns: columns, dataSource: bills, loading: loading, rowKey: "id", pagination: false, scroll: { x: true }, rowSelection: {
                    type: 'checkbox',
                    selectedRowKeys: map(filter(values.paymentMappingRequests || {}, (mapping) => get(mapping, 'settlementType') === 'SUPPLEMENTARY_NON_LOGISTICS_INVOICE'), 'billId'),
                    onChange: handleNonSupplementaryBillSelect,
                } })] }));
};
export default BillSettlementNonSupplementaryBillComponent;
