import { get } from 'lodash';

const FxTextField = (passedProps) => {
    const {
        label,
        title = label,
        field,
        form,
        outerDiv,
        titleDiv,
        inputClassNames,
        isRequired,
        onChange,
        onBlur,
        prependText,
        helptext,
        helpTextClassName,
        noSideBySide = false,
        labelOnTop,
        ...props
    } = passedProps;

    const error = get(form.errors, field.name, '');
    const isTouched = get(form.touched, field.name, '');

    const onChangeHandler = (event) => {
        field.onChange(event);
        if (onChange) {
            onChange(event.currentTarget.value);
        }
    };
    const onBlurHandler = (event) => {
        field.onBlur(event);
        if (onBlur) {
            onBlur(event.currentTarget.value);
        }
    };

    return (
        <div className={outerDiv ? outerDiv : ''}>
            <div className={`${!noSideBySide ? 'input-group' : ''} align-items-center `}>
                {title ? (
                    <div className={titleDiv ? `${titleDiv}` : 'mr-2'}>
                        {noSideBySide ? <label>{title}</label> : title}
                        {isRequired && <abbr className="text-danger">*</abbr>}
                    </div>
                ) : null}

                <div className={inputClassNames ? `d-flex flex-column ${inputClassNames}` : 'd-flex flex-column'}>
                    {prependText && (
                        <div className="input-group">
                            <span className="input-group-addon">{prependText}</span>
                            <input
                                className="form-control rounded w-100"
                                data-testid={field.name}
                                {...props}
                                {...field}
                                onChange={onChangeHandler}
                                onBlur={onBlurHandler}
                            />
                        </div>
                    )}
                    {!prependText && (
                        <input
                            className="form-control rounded w-100"
                            data-testid={field.name}
                            {...props}
                            {...field}
                            onChange={onChangeHandler}
                            onBlur={onBlurHandler}
                        />
                    )}
                    {helptext && !error && <div className={`help-block ${helpTextClassName}`}>{helptext}</div>}
                    {isTouched && error ? <div className="help-block text-danger">{error}</div> : null}
                </div>
            </div>
        </div>
    );
};
export default FxTextField;
