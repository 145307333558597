/**
 * Created by abhay on 2017-07-12.
 */
import axios from 'axios';
import { parseQueryParams, ROOT_API_ERP, ROOT_API_URL, ROOT_REPORTING_NODE_API_URL } from '../../constant';
import { assign, get, join, omit, set } from 'lodash';
import { excludeBlacklistedUsers } from 'utils/account_utils';

export const ADD_USER = 'add_user';
export const UPDATE_USER = 'update_user';
export const FETCH_USER = 'fetch_user';
export const FETCH_USERS = 'fetch_users';
export const DELETE_USER = 'delete_user';
export const FETCH_ELIGIBLE_USERS = 'fetch_eligible_users';
export const FETCH_USER_LIST_MINI = 'fetch_user_list_mini';
export const FETCH_USER_LIST_MINI_V2 = 'fetch_user_list_mini_v2';
export const UPDATE_USER_MOBILE_NUMBER = 'update_user_mobile_number';
export const FETCH_ALL_TECHNICIANS = 'fetch_all_technicians';
export const FETCH_TECHNICIAN = 'fetch_technician';
export const ADD_TECHNICIAN_ADDRESS_MAPPING = 'add_technician_address_mapping';
export const ENABLE_USER = 'enable_user';
export const DISABLE_USER = 'disable_user';
export const BLACKLIST_USER = 'BLACKLIST_USER';
export const FETCH_SESSION_REPOPRT = 'FETCH_SESSION_REPOPRT';
export const GET_DRIVER_VEHICLE_HISTORY = 'GET_DRIVER_VEHICLE_HISTORY';
export const GET_DRIVER_VEHICLE_CHANGES = 'GET_DRIVER_VEHICLE_CHANGES';
export const FETCH_USER_LIST_MINI_V3 = 'FETCH_USER_LIST_MINI_V3';
export const FETCH_DRIVER_INCENTIVE_KMS = 'FETCH_DRIVER_INCENTIVE_KMS';
export const FETCH_DRIVER_INCENTIVE_FUEL = 'FETCH_DRIVER_INCENTIVE_FUEL';
export const FETCH_DRIVER_INCENTIVE_DETAILS = 'FETCH_DRIVER_INCENTIVE_DETAILS';
export const FETCH_DRIVER_INCENTIVE_EPOD = 'FETCH_DRIVER_INCENTIVE_EPOD';
export const FETCH_USER_LIST_MINI_BY_TYPE = 'FETCH_USER_LIST_MINI_BY_TYPE';
export const FETCH_OBD_PIDS = 'FETCH_OBD_PIDS';
export const FETCH_DRIVER_FREIGHT_INCENTIVE = 'FETCH_DRIVER_FREIGHT_INCENTIVE';
export const FETCH_USER_SALARY = 'FETCH_USER_SALARY';
export const USERS_REPORT = 'USERS_REPORT';

export function addUser(values, accesstoken) {
    let data = assign({}, values);
    // const phoneNumberPrefix = getPhonePrefixFromCountry();
    // if (values.phoneNumber) {
    //     set(data, 'phoneNumber', `${phoneNumberPrefix}-` + data.phoneNumber);
    // }

    // if (values.workPhoneNumber) {
    //     set(data, 'workPhoneNumber', `${phoneNumberPrefix}-` + data.workPhoneNumber);
    // }
    //
    // if (values.contact && values.contact.homePhoneNumber) {
    //     set(data, 'contact.homePhoneNumber', `${phoneNumberPrefix}-` + data.contact.homePhoneNumber);
    // }
    //
    // if (values.contact && values.contact.otherPhoneNumber) {
    //     set(data, 'contact.otherPhoneNumber', `${phoneNumberPrefix}-` + data.contact.otherPhoneNumber);
    // }
    //
    // if (values.contact && values.contact.emergencyPhoneNumber) {
    //     set(data, 'contact.emergencyPhoneNumber', `${phoneNumberPrefix}-` + data.contact.emergencyPhoneNumber);
    // }

    if (!values.loginEnabled || values.loginEnabled === '') {
        data = omit(data, 'role');
    }
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.post(`${ROOT_API_URL}users/`, data, config);

    return {
        type: ADD_USER,
        promise: request,
    };
}

export function updateUser(id, values, accesstoken, initialValues) {
    let data = assign({}, values);
    //const phoneNumberPrefix = getPhonePrefixFromCountry();

    // if (values.phoneNumber) {
    //     set(data, 'phoneNumber', `${phoneNumberPrefix}-` + data.phoneNumber);
    // }

    // if (values.workPhoneNumber) {
    //     set(data, 'workPhoneNumber', `${phoneNumberPrefix}-` + data.workPhoneNumber);
    // }

    // if (values.contact && values.contact.homePhoneNumber) {
    //     set(data, 'contact.homePhoneNumber', `${phoneNumberPrefix}-` + data.contact.homePhoneNumber);
    // }

    // if (values.contact && values.contact.otherPhoneNumber) {
    //     set(data, 'contact.otherPhoneNumber', `${phoneNumberPrefix}-` + data.contact.otherPhoneNumber);
    // }

    // if (values.contact && values.contact.emergencyPhoneNumber) {
    //     set(data, 'contact.emergencyPhoneNumber', `${phoneNumberPrefix}-` + data.contact.emergencyPhoneNumber);
    // }

    data = omit(data, 'role');

    if (values.loginEnabled && get(values, 'role.name', null)) {
        set(data, 'role.id', get(values, 'role.id', null));
        set(data, 'role.name', get(values, 'role.name', null));
    }

    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.patch(`${ROOT_API_URL}users/${id}`, data, config);

    return {
        type: UPDATE_USER,
        promise: request,
    };
}

export function fetchUser(id, accesstoken) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.get(`${ROOT_API_URL}users/${id}`, config);

    return {
        type: FETCH_USER,
        promise: request,
    };
}

export function fetchUsers(
    accesstoken,
    page = 1,
    size = 7000,
    userTypeId,
    groupId,
    { roleId = null, tagIds = null } = {},
    fetchByType,
    enabled,
    id,
    customerIds,
    restParams
) {
    const fetchUserKey = fetchByType ? 'userType' : 'userTypeId';
    const params = {
        roleID: roleId,
        tagIds: join(tagIds, ','),
        [fetchUserKey]: userTypeId,
        groupId,
        page: page - 1,
        size,
        enabled,
        id,
        customerIds,
    };
    const { verified, loginEnabled, phoneNumber, licenseNumber, aadharCardNumber } = restParams || {};
    if (verified) {
        params.verified = verified;
    }
    if (loginEnabled) {
        params.loginEnabled = loginEnabled;
    }
    if (phoneNumber) {
        params.phoneNumber = phoneNumber;
    }
    if (licenseNumber) {
        params.licenseNumber = licenseNumber;
    }
    if (aadharCardNumber) {
        params.aadharCardNumber = aadharCardNumber;
    }
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams(params),
    };
    const request = axios.get(`${ROOT_API_URL}users/`, config);

    return {
        type: FETCH_USERS,
        promise: request,
    };
}

export function deleteUser(id, accesstoken) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.delete(`${ROOT_API_URL}users/${id}`, config);
    return {
        type: DELETE_USER,
        promise: request,
    };
}

export function enableUser(id, accesstoken) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.put(`${ROOT_API_URL}users/un-archive?userId=${id}`, null, config);
    return {
        type: ENABLE_USER,
        promise: request,
    };
}

export function disableUser(id, accesstoken) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.put(`${ROOT_API_URL}users/archive?userId=${id}`, null, config);
    return {
        type: DISABLE_USER,
        promise: request,
    };
}

export function blacklistUser(accesstoken, id, isBlackListed) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.patch(`${ROOT_API_URL}users/blacklist/${id}/${isBlackListed}`, null, config);
    return {
        type: BLACKLIST_USER,
        promise: request,
    };
}

export function fetchUsersByType(accesstoken, userType, verified, page = 1, size = 6000) {
    return fetchUsers(accesstoken, page, size, userType, null, {}, true, null, null, '', verified);
}

export function fetchEligibleUsers(accesstoken, groupId) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const groupQuery = groupId ? `groupId=${groupId}` : '';
    const request = axios.get(`${ROOT_API_URL}users/alerts/eligible?${groupQuery}`, config);

    return {
        type: FETCH_ELIGIBLE_USERS,
        promise: request,
    };
}

/**
 *
 * @param {*} accesstoken
 * @param {*} [groupId]
 * @param {*} [userType]
 * @returns
 */

export function fetchUserListMiniV2(accesstoken, groupId = null, userType = null, fromUserMaster = false) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            excludeBlacklisted: excludeBlacklistedUsers() === true && fromUserMaster === false ? true : null,
        }),
    };
    let groupQuery = '',
        userTypeQuery = '';
    if (groupId && userType) {
        groupQuery = groupId ? `?groupId=${groupId}` : '';
        userTypeQuery = userType ? `&userTypeId=${userType}` : '';
    } else if (groupId) {
        groupQuery = groupId ? `?groupId=${groupId}` : '';
    } else if (userType) {
        userTypeQuery = userType ? `?userTypeId=${userType}` : '';
    }

    // removed replace, because of bug at backend
    const request = axios.get(`${ROOT_API_URL}users/autocomplete/${groupQuery}${userTypeQuery}`, config);

    return {
        type: FETCH_USER_LIST_MINI_V2,
        promise: request,
    };
}

/**
 *
 * @param {*} accesstoken
 * @param {*} [groupId]
 * @param {*} [userType]
 * @param {*} [verified]
 * @param {*} [accountId]
 * @returns
 */

export function fetchUserListMini(
    accesstoken,
    groupId = null,
    userType = null,
    verified = null,
    accountId = null,
    fromUserMaster = false,
    enabled = null
) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            verified: verified === true ? true : null,
            accountId,
            excludeBlacklisted: excludeBlacklistedUsers() === true && fromUserMaster === false ? true : null,
            enabled,
        }),
    };
    let groupQuery = '',
        userTypeQuery = '';

    if (groupId && userType) {
        groupQuery = groupId ? `?groupId=${groupId}` : '';
        userTypeQuery = userType ? `&userTypeId=${userType}` : '';
    } else if (groupId) {
        groupQuery = groupId ? `?groupId=${groupId}` : '';
    } else if (userType) {
        userTypeQuery = userType ? `?userTypeId=${userType}` : '';
    }

    const request = axios.get(`${ROOT_API_URL}users/autocomplete/${groupQuery}${userTypeQuery}`, config);

    return {
        type: FETCH_USER_LIST_MINI,
        promise: request,
    };
}

export function fetchUserListMiniByType(
    accesstoken,
    { groupId = null, userType = null, verified = null, accountId = null }
) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            verified: verified === true ? true : null,
            accountId,
            excludeBlacklisted: excludeBlacklistedUsers() === true ? true : null,
        }),
    };
    let groupQuery = '',
        userTypeQuery = '';

    if (groupId && userType) {
        groupQuery = groupId ? `?groupId=${groupId}` : '';
        userTypeQuery = userType ? `&userTypeId=${userType}` : '';
    } else if (groupId) {
        groupQuery = groupId ? `?groupId=${groupId}` : '';
    } else if (userType) {
        userTypeQuery = userType ? `?userTypeId=${userType}` : '';
    }

    const request = axios.get(`${ROOT_API_URL}users/autocomplete/${groupQuery}${userTypeQuery}`, config);

    return {
        type: FETCH_USER_LIST_MINI_BY_TYPE,
        promise: request,
    };
}

export function updateUserPhoneNumber(id, accesstoken, newPhoneNumber) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const data = new FormData();
    data.append('phoneNumber', newPhoneNumber);

    const request = axios.post(`${ROOT_API_URL}users/update/mobile/${id}`, data, config);
    return {
        type: UPDATE_USER_MOBILE_NUMBER,
        promise: request,
    };
}

export function fetchAllTechnicians(accesstoken) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.get(`${ROOT_API_URL}/technicians`, config);

    return {
        type: FETCH_ALL_TECHNICIANS,
        promise: request,
    };
}

export function fetchTechnician(accesstoken, id) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.get(`${ROOT_API_URL}technicians/${id}`, config);

    return {
        type: FETCH_TECHNICIAN,
        promise: request,
    };
}

export function createTechnicianAddressMapping(values, accesstoken) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.post(`${ROOT_API_URL}technicians`, values, config);

    return {
        type: ADD_TECHNICIAN_ADDRESS_MAPPING,
        promise: request,
    };
}

export function getUserReport(accesstoken) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        responseType: 'blob',
    };
    const request = axios.get(`${ROOT_API_URL}users/report`, config);

    return {
        type: FETCH_TECHNICIAN,
        promise: request,
    };
}

export function triggerSessionReportDownload(accesstoken, params) {
    const { from, to } = params;
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            from: from,
            to: to,
        }),
        responseType: 'blob',
    };
    const request = axios.get(`${ROOT_API_URL}users/login-report`, config);

    return {
        type: FETCH_SESSION_REPOPRT,
        promise: request,
    };
}

export function getVehicleDriverHistory(accesstoken, params) {
    const { from, to, driverId } = params;
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            from: from,
            to: to,
            driverId: driverId,
        }),
        responseType: 'blob',
    };
    const request = axios.get(`${ROOT_REPORTING_NODE_API_URL}reports/vehicleDriverHistory`, config);

    return {
        type: GET_DRIVER_VEHICLE_HISTORY,
        promise: request,
    };
}

export function getVehicleDriverChanges(accesstoken, params) {
    const { from, to, driverId } = params;
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            from: from,
            to: to,
        }),
        responseType: 'blob',
    };
    const request = axios.get(`${ROOT_REPORTING_NODE_API_URL}reports/vehicleDriverChange`, config);

    return {
        type: GET_DRIVER_VEHICLE_CHANGES,
        promise: request,
    };
}

export function fetchUserListMiniV3(accesstoken, groupId = null, userType = null, otherParams = {}) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            withLedger: get(otherParams, 'withLedger'),
            excludeBlacklisted: excludeBlacklistedUsers() === true ? true : null,
        }),
    };
    let groupQuery = '',
        userTypeQuery = '';
    if (groupId && userType) {
        groupQuery = groupId ? `?groupId=${groupId}` : '';
        userTypeQuery = userType ? `&userTypeId=${userType}` : '';
    } else if (groupId) {
        groupQuery = groupId ? `?groupId=${groupId}` : '';
    } else if (userType) {
        userTypeQuery = userType ? `?userTypeId=${userType}` : '';
    }

    // removed replace, because of bug at backend
    const request = axios.get(
        `${ROOT_API_URL}users/autocomplete/${groupQuery}${userTypeQuery}`.replace('v1', 'v2'),
        config
    );

    return {
        type: FETCH_USER_LIST_MINI_V3,
        promise: request,
    };
}

export function fetchDriverIncentiveKms(accesstoken, { driverId, from, to }) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            driverId,
            from,
            to,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}users/payment/incentive-kms`, config);

    return {
        type: FETCH_DRIVER_INCENTIVE_KMS,
        promise: request,
    };
}

export function fetchDriverIncentiveFuel(accesstoken, { driverId, from, to }) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            driverId,
            from,
            to,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}users/payment/incentive-fuel`, config);

    return {
        type: FETCH_DRIVER_INCENTIVE_FUEL,
        promise: request,
    };
}

export function fetchDriverIncentiveDetails(accesstoken, { driverId, from, to }) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            driverId,
            from,
            to,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}users/payment/incentive-details`, config);

    return {
        type: FETCH_DRIVER_INCENTIVE_DETAILS,
        promise: request,
    };
}

export function fetchDriverIncentiveEpod(accesstoken, { driverId, from, to }) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            driverId,
            from,
            to,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}users/payment/incentive-epod`, config);

    return {
        type: FETCH_DRIVER_INCENTIVE_EPOD,
        promise: request,
    };
}

export function fetchObdPIDs(deviceId) {
    const request = axios.get(`${ROOT_API_URL}devices/obd/${deviceId}`);

    return {
        type: FETCH_OBD_PIDS,
        promise: request,
    };
}

export function fetchObdHealth({ vehicleId, startTime, endTime }) {
    const config = {
        params: parseQueryParams({
            vehicleId,
            startTime,
            endTime,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}vehicle-health/fetch-health-score`, config);

    return {
        type: FETCH_OBD_PIDS,
        promise: request,
    };
}

export function fetchDriverFreightIncentive({ driverId, from, to }) {
    const config = {
        params: parseQueryParams({
            driverId,
            from,
            to,
        }),
    };
    const request = axios.get(`${ROOT_API_ERP}consignment/freight-incentive`, config);

    return {
        type: FETCH_DRIVER_FREIGHT_INCENTIVE,
        promise: request,
    };
}

export function fetchUserSalary({ id, from, to }) {
    const config = {
        params: parseQueryParams({
            from,
            to,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}user/attendance/day-wise/${id}`, config);

    return {
        type: FETCH_USER_SALARY,
        promise: request,
    };
}

export function triggerUsersReport(options) {
    const config = {
        responseType: 'blob',
    };
    const data = parseQueryParams(options);

    const request = axios.post(`${ROOT_REPORTING_NODE_API_URL}reports/triggerUsersReport`, data, config);

    return {
        type: USERS_REPORT,
        promise: request,
    };
}
